import React, { Fragment, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Report } from '@material-ui/icons';
import { FaFileInvoice } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import {
  Chip,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import { req } from 'src/services/api';
import ModalFaturamento from './ModalFaturamento';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiSvgIcon-root': {
      color: '#F18750',
      opacity: 0.8
    },
    '& .MuiSvgIcon-root:hover ': {
      //
      color: '#ff5c08',
      opacity: 1
    }
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const estadosTypeOne = {
  1: 'Aguardando pagamento',
  2: 'Preparando Para a Entrega',
  3: 'Saiu para Entrega',
  4: 'Entregue',
  5: 'Cancelado'
};

const estadosTypeTwo = {
  1: 'Aguardando pagamento',
  2: 'Separando os produtos',
  3: 'Pedido pronto para entrega',
  4: 'Entregue',
  5: 'Cancelado'
};

const estados_cores = {
  1: '#FFA500',
  2: '#4169E1',
  3: '#228B22',
  4: '#778899',
  5: '#B22222'
};

const formas_de_pagamento = {
  2: 'CARTÃO DE CRÉDITO',
  1: 'PIX'
};

const Results = ({
  className,
  total,
  orders,
  loading,
  isFinished,
  limit,
  handleLimitChange,
  page,
  handlePageChange,
  permissions,
  getOrders,
  ...rest
}) => {
  const classes = useStyles();
  const [selectedOrderIds, setSelectedOrderIds] = useState([]);
  const [pedidoSelecionado, setPedidoSelecionado] = useState(null);

  const navigate = useNavigate();

  const visualizeOrder = async uuid => {
    try {
      const resp = await req(
        `
          mutation{
            visualize_order(uuid: "${uuid}")
          }
          `
      );
      if (resp.data) {
        console.log('visualizado');
      } else if (resp.errors) {
        console.log(resp.error);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const hasNewOrders = orders.find(order => {
    return !order.visualisado && order.status <= 2;
  });

  function faturarPedido(e, order) {
    e.stopPropagation();
    setPedidoSelecionado(order);
  }

  const navigateToDetails = async order => {
    await visualizeOrder(order.uuid);
    navigate('/pedido', { state: { order, permissions } });
  };

  return (
    <Fragment>
      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <Table>
              <TableHead>
                <TableRow>
                  {!isFinished && hasNewOrders && (
                    <TableCell align="center" colSpan={1}>
                      {' '}
                    </TableCell>
                  )}
                  <TableCell align="center">Código do Pedido</TableCell>
                  <TableCell align="center">Cliente</TableCell>
                  <TableCell align="center">Valor da Compra</TableCell>
                  <TableCell align="center">Bairro</TableCell>
                  <TableCell align="center">Zona</TableCell>
                  <TableCell align="center">Horario da Compra</TableCell>
                  <TableCell align="center">Forma de Pagamento</TableCell>
                  <TableCell align="center">Status do Pedido</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow hover key="key">
                    <TableCell colSpan={9} align="center">
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center'
                        }}
                      >
                        <CircularProgress color="primary" size={20} />
                        <Typography style={{ marginLeft: 10 }}>
                          Carregando...
                        </Typography>
                      </div>
                    </TableCell>
                  </TableRow>
                ) : orders.length > 0 ? (
                  orders.map(order => (
                    <TableRow
                      hover
                      key={order.uuid}
                      onClick={() => navigateToDetails(order)}
                      selected={selectedOrderIds.indexOf(order.uuid) !== -1}
                      style={{
                        cursor: 'pointer'
                      }}
                    >
                      {!isFinished && !order.visualisado && (
                        <TableCell align="center">
                          <Chip
                            style={{
                              backgroundColor: '#3BB143',
                              color: 'white'
                            }}
                            label="Novo"
                            size="small"
                          />
                        </TableCell>
                      )}

                      <TableCell align="center">
                        <strong>
                          {order.uuid.slice(30, 36).toUpperCase()}
                        </strong>
                      </TableCell>

                      <TableCell>{order.user.nome}</TableCell>

                      <TableCell align="center">
                        {order.valor_total.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL'
                        })}
                      </TableCell>

                      <TableCell align="center">{order.bairro}</TableCell>
                      <TableCell align="center">{order.zona}</TableCell>
                      <TableCell align="center">
                        {new Date(
                          parseInt(order.data_pedido, 10)
                        ).toLocaleString('pt-BR')}
                      </TableCell>

                      <TableCell align="center">
                        {formas_de_pagamento[order.paymentMethod.type]}
                      </TableCell>
                      <TableCell align="center">
                        <Chip
                          style={{
                            backgroundColor: estados_cores[order.status],
                            color: 'white'
                          }}
                          label={
                            order.deliveryType == 1
                              ? estadosTypeOne[order.status]
                              : estadosTypeTwo[order.status]
                          }
                          size="small"
                        />
                      </TableCell>
                      <TableCell align="center">
                        <div className={classes.row}>
                          {![1, 5].includes(order.status) &&
                            !order.faturado && (
                              <FaFileInvoice
                                onClick={e => faturarPedido(e, order)}
                                style={{ color: '#222', fontSize: 18 }}
                              />
                            )}
                          {order.status_interno !== '' && (
                            <Report
                              style={{
                                color: '#E83B3C',
                                marginLeft: ![1, 5].includes(order.status)
                                  ? 10
                                  : 0
                              }}
                            />
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow hover key="key">
                    <TableCell colSpan={9} align="center">
                      Nenhum pedido encontrado
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={total}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[15, 25, 50, 100]}
        />
      </Card>
      <ModalFaturamento
        visible={pedidoSelecionado !== null}
        pedido={pedidoSelecionado}
        atualizarLista={getOrders}
        onClose={() => setPedidoSelecionado(null)}
      />
    </Fragment>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  orders: PropTypes.array.isRequired
};

export default Results;
