import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  Button
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import ExportUsers from './ExportUsers';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3)
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  SearchInput: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 10
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: '50%'
    },
    width: '100%'
  },
  Button: {
    marginLeft: 10,
    color: '#fff'
  }
}));

const Toolbar = ({
  className,
  input,
  setInput,
  openModal,
  onClick,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box mt={3} className={classes.container}>
        <Box maxWidth={500} className={classes.SearchInput}>
          <TextField
            fullWidth
            value={input}
            onChange={e => setInput(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              )
            }}
            placeholder="Buscar usuário"
            variant="outlined"
            onKeyPress={e => {
              if (e.key === 'Enter') {
                onClick();
              }
            }}
          />
          <Button
            className={classes.Button}
            onClick={onClick}
            color="primary"
            variant="contained"
          >
            Buscar
          </Button>
        </Box>
        <ExportUsers filter={input} />
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
