import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Typography,
  InputAdornment
} from '@material-ui/core';
import ItemLista from './ItemLista';
import { req } from '../../../services/api';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { blobToFile } from '../../../utils/blobToFile';
import { store } from '../../../store';

const CreateMutation = gql`
  mutation(
    $codigo: String!
    $nome_popular: String!
    $nome_formal: String!
    $quantidade_estoque: Float!
    $preco: Float!
    $desconto_pix: Float!
    $quantidade_pedidas: Float!
    $category_id: String!
    $marca: String!
    $unidade_preco: String!
    $descricao: String!
    $images: [Upload!]!
  ) {
    create_product(
      codigo: $codigo
      nome_popular: $nome_popular
      nome_formal: $nome_formal
      quantidade_estoque: $quantidade_estoque
      preco: $preco
      desconto_pix: $desconto_pix
      quantidade_pedidas: $quantidade_pedidas
      category_id: $category_id
      marca: $marca
      unidade_preco: $unidade_preco
      descricao: $descricao
      images: $images
    )
  }
`;

const UpdateMutation = gql`
  mutation($uuid: String!, $fields: ProductUpdateInput!) {
    updateProduct(uuid: $uuid, fields: $fields)
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    backgroundColor: 'white',
    width: '85%',
    height: '90vh',
    [theme.breakpoints.down('xs')]: {
      width: '95%'
    },
    overflowY: 'scroll'
  },
  title: {
    paddingBottom: theme.spacing(1)
  },
  cover: {
    width: 360,
    height: 200
  },
  input: {
    display: 'none'
  },
  inputLabel: {
    paddingBottom: theme.spacing(1)
  },
  imagesContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    gridGap: '16px',
    flexDirection: 'row'
  }
}));

const RegisterProduct = ({
  className,
  handleChange,
  produtoCarregado,
  setProdutoCarregado,
  loadProdutos,
  image,
  setImage,
  handleClose,
  toast,
  ...rest
}) => {
  const [categorias, setCategorias] = useState([]);
  const [createProduct] = useMutation(CreateMutation);
  const [updateProduct] = useMutation(UpdateMutation);
  const classes = useStyles();

  function generate(element) {
    return image.map((value, index) =>
      React.cloneElement(element, {
        url: value.url,
        nome: value.nome,
        indice: index,
        key: index
      })
    );
  }

  function deletaIndice(index) {
    let array = [...image];
    array.splice(index, 1);
    setImage(array);
  }

  useEffect(() => {
    async function loadCategories() {
      const data = await req(
        `{
                    readCategory{
                        uuid
                        nome
                      }
                  }`
      );

      if (data.data) {
        var listaDeCategorias = [];
        listaDeCategorias.push({
          value: 'nao_selecionado',
          label: 'Selecionar categoria'
        });
        for (var i = 0; i < data.data.readCategory.length; i++) {
          listaDeCategorias.push({
            value: data.data.readCategory[i].uuid,
            label: data.data.readCategory[i].nome
          });
        }
        setCategorias(listaDeCategorias);
      } else if (data.errors) {
        alert(data.errors);
      }
    }

    if (categorias.length == 0) {
      loadCategories();
    }
  }, [categorias]);

  function limparCampos() {
    setImage([]);
    setProdutoCarregado({
      uuid: '',
      codigo: '',
      nomeTecnico: '',
      nomePopular: '',
      categoria: 'nao_selecionado',
      marca: '',
      unidade_preco: '',
      descricao: '',
      preco: '',
      desconto_pix: '',
      estoque: ''
    });
  }

  async function salvarProduto(event) {
    event.preventDefault();
    if (produtoCarregado.categoria === 'nao_selecionado') {
      toast.warning('É necessário selecionar uma categoria para o produto!');
    } else {
      try {
        var arrayImage = [];
        for (var i = 0; i < image.length; i++) {
          if (image[i].dados) {
            arrayImage.push(image[i].dados);
          } else {
            let pieces = image[i].url.split('/');
            let dado = await blobToFile(
              await fetch(image[i].url).then(response => response.blob()),
              pieces[pieces.length - 1]
            );
            arrayImage.push(dado);
          }
        }
        if (arrayImage.length == 0) {
          toast.warning('É necessário cadastrar ao menos uma imagem!');
          return;
        }
      } catch (error) {
        toast.error('Erro ao carregar as imagens!');
        return;
      }

      if (produtoCarregado.uuid) {
        try {
          await updateProduct({
            variables: {
              uuid: produtoCarregado.uuid,
              fields: {
                codigo: produtoCarregado.codigo,
                nome_popular: produtoCarregado.nomePopular,
                nome_formal: produtoCarregado.nomeTecnico,
                quantidade_estoque: parseFloat(produtoCarregado.estoque),
                preco: parseFloat(produtoCarregado.preco),
                desconto_pix: produtoCarregado.desconto_pix
                  ? parseFloat(produtoCarregado.desconto_pix)
                  : 0,
                quantidade_pedidas: 0,
                category_id: produtoCarregado.categoria,
                marca: produtoCarregado.marca.toUpperCase(),
                unidade_preco: produtoCarregado.unidade_preco,
                descricao: produtoCarregado.descricao,
                images: arrayImage
              }
            },
            context: {
              headers: {
                authorization: store.getState().auth.accessToken,
                'x-refresh-token': store.getState().auth.refreshToken,
                'Access-Control-Allow-Origin': '*'
              }
            }
          });
          toast.success('Produto atualizado com sucesso!');
        } catch (error) {
          toast.error('Erro ao atualizar produto!');
          return;
        }
      } else {
        try {
          await createProduct({
            variables: {
              codigo: produtoCarregado.codigo,
              nome_popular: produtoCarregado.nomePopular,
              nome_formal: produtoCarregado.nomeTecnico,
              quantidade_estoque: parseFloat(produtoCarregado.estoque),
              preco: parseFloat(produtoCarregado.preco),
              desconto_pix: produtoCarregado.desconto_pix
                ? parseFloat(produtoCarregado.desconto_pix)
                : 0,
              quantidade_pedidas: 0,
              category_id: produtoCarregado.categoria,
              marca: produtoCarregado.marca.toUpperCase(),
              unidade_preco: produtoCarregado.unidade_preco,
              descricao: produtoCarregado.descricao,
              images: arrayImage
            },
            context: {
              headers: {
                authorization: store.getState().auth.accessToken,
                'x-refresh-token': store.getState().auth.refreshToken,
                'Access-Control-Allow-Origin': '*'
              }
            }
          });
          toast.success('Produto cadastrado com sucesso!');
        } catch (error) {
          toast.error('Erro ao cadastrar produto!');
          return;
        }
      }
      loadProdutos();
      limparCampos();
      handleClose();
    }
  }

  return (
    <form
      autoComplete="off"
      onSubmit={event => salvarProduto(event)}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Typography className={classes.title} variant="h2" color="textPrimary">
        Cadastrar novo produto
      </Typography>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Nome Técnico"
                autoComplete="new-password"
                name="nomeTecnico"
                onChange={handleChange}
                required
                value={produtoCarregado.nomeTecnico}
                variant="outlined"
                InputLabelProps={{
                  style: { color: '#c4c4c4' }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Nome Popular"
                autoComplete="new-password"
                name="nomePopular"
                onChange={handleChange}
                required
                value={produtoCarregado.nomePopular}
                variant="outlined"
                InputLabelProps={{
                  style: { color: '#c4c4c4' }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                fullWidth
                label="Código"
                autoComplete="new-password"
                name="codigo"
                onChange={handleChange}
                required
                value={produtoCarregado.codigo}
                variant="outlined"
                InputLabelProps={{
                  style: { color: '#c4c4c4' }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                fullWidth
                name="categoria"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={produtoCarregado.categoria}
                variant="outlined"
                InputLabelProps={{
                  style: { color: '#c4c4c4' }
                }}
              >
                {categorias.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                fullWidth
                label="Marca"
                autoComplete="new-password"
                name="marca"
                onChange={handleChange}
                required
                value={produtoCarregado.marca}
                variant="outlined"
                InputLabelProps={{
                  style: { color: '#c4c4c4' }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Unidade de medida"
                autoComplete="new-password"
                name="unidade_preco"
                onChange={handleChange}
                multiline
                required
                value={produtoCarregado.unidade_preco}
                variant="outlined"
                InputLabelProps={{
                  style: { color: '#c4c4c4' }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label="Preço"
                name="preco"
                onChange={event => {
                  event.target.value = event.target.value.replace('.', '');
                  var numbers = /^[0-9]*$/;
                  if (event.target.value.match(numbers)) {
                    event.target.value = event.target.value.replace(
                      /(.)(?=(\d{2})$)/g,
                      '$1.'
                    );
                    handleChange(event);
                  }
                }}
                required
                value={produtoCarregado.preco}
                variant="outlined"
                InputLabelProps={{
                  style: { color: '#c4c4c4' }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label="Desconto PIX"
                name="desconto_pix"
                type="number"
                onChange={event => {
                  event.target.value =
                    event.target.value >= 0 ? event.target.value : 0;
                  handleChange(event);
                }}
                value={produtoCarregado.desconto_pix}
                variant="outlined"
                InputLabelProps={{
                  style: { color: '#c4c4c4' }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end"> % </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                fullWidth
                label="Quantidade em estoque"
                name="estoque"
                onChange={event => {
                  var numbers = /^[0-9]*$/;
                  if (event.target.value.match(numbers)) {
                    handleChange(event);
                  }
                }}
                required
                type="text"
                pattern="[0-9]*"
                value={produtoCarregado.estoque}
                variant="outlined"
                InputLabelProps={{
                  style: { color: '#c4c4c4' }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Descrição"
                autoComplete="new-password"
                name="descricao"
                onChange={handleChange}
                multiline
                rows={10}
                required
                value={produtoCarregado.descricao}
                variant="outlined"
                InputLabelProps={{
                  style: { color: '#c4c4c4' }
                }}
                inputProps={{
                  maxLength: 850
                }}
              />
              <Typography
                style={{
                  marginTop: 10,
                  fontSize: 14
                }}
              >
                Total de caracteres: {produtoCarregado.descricao.length}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                color="textPrimary"
                className={classes.inputLabel}
              >
                Selecionar imagens
              </Typography>
              <div className={classes.imagesContainer}>
                {generate(<ItemLista onDelete={deletaIndice} />)}
                <div
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <div
                    style={{
                      flex: 1
                    }}
                  >
                    <input
                      accept="image/*"
                      id="raised-button-file"
                      className={classes.input}
                      type="file"
                      onChange={e => {
                        let arrayFiles = [];
                        for (var i = 0; i < e.target.files.length; i++) {
                          arrayFiles.push({
                            nome: e.target.files[i].name,
                            url: URL.createObjectURL(e.target.files[i]),
                            dados: e.target.files[i]
                          });
                        }
                        setImage([...image, ...arrayFiles]);
                      }}
                      multiple
                    />
                    <label htmlFor="raised-button-file">
                      <Button raised component="span" color="primary">
                        Adicionar imagem
                      </Button>
                    </label>
                  </div>
                  <Button
                    style={{ flex: 1 }}
                    color="secondary"
                    onClick={() => {
                      setImage([]);
                    }}
                  >
                    Limpar imagens
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                <Button
                  color="secondary"
                  variant="contained"
                  style={{ color: '#fff', margin: 10 }}
                  onClick={() => {
                    limparCampos();
                    handleClose();
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  style={{ color: '#fff', margin: 10 }}
                >
                  Salvar Produto
                </Button>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

RegisterProduct.propTypes = {
  className: PropTypes.string
};

export default RegisterProduct;
