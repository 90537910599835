import React, { useState, useEffect, Fragment } from 'react';
import {
  makeStyles,
  Typography,
  Container,
  Card,
  CardContent,
  Grid,
  TextField,
  Button
} from '@material-ui/core';
import { req } from '../../services/api';
import InputAdornment from '@material-ui/core/InputAdornment';
import { toast, ToastContainer } from 'react-toastify';
import Page from 'src/components/Page';
import clsx from 'clsx';

import {
  Truck as TruckIcon,
  MessageSquare,
  DollarSign,
  Clock,
  Percent
} from 'react-feather';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#f18750'
      }
    }
  },
  title: {
    paddingBottom: theme.spacing(1),
    marginLeft: theme.spacing(2)
  },
  input: {
    display: 'none',
    '&:hover': {
      backgroundColor: '#15e577',
      borderColor: '#f18750'
    }
  },
  inputLabel: {
    paddingBottom: theme.spacing(1)
  }
}));

const Frete = ({ className, permissions, ...rest }) => {
  const classes = useStyles();

  const [frete, setFrete] = useState({});
  const [novoFrete, setNovoFrete] = useState('');
  const [novoLimite, setNovoLimite] = useState('');

  const [alert_message, setAlert_message] = useState('');
  const [new_alert_message, setNewAlert_message] = useState('');

  const [compraMinima, setCompraMinima] = useState(0.0);
  const [new_compraMinima, setNewCompraMinima] = useState(0.0);

  const [expiratiom_pix_time, setExpiratiom_Pix_Time] = useState(0.0);
  const [new_expiratiom_pix_time, setNewExpiratiom_Pix_Time] = useState('');

  const [desconto_pix_value, setDesconto_Pix_Value] = useState(0.0);
  const [desconto_pix_value_new, setDesconto_Pix_Value_New] = useState(0.0);

  const [desconto_pix_limite, setDesconto_Pix_Limite] = useState(0.0);
  const [desconto_pix_limite_new, setDesconto_Pix_Limite_New] = useState(0.0);

  const [desconto_Extra_value, setDesconto_Extra_Value] = useState(0.0);
  const [desconto_Extra_value_new, setDesconto_Extra_Value_New] = useState(0.0);

  const [desconto_Extra_limite, setDesconto_Extra_Limite] = useState(0.0);
  const [desconto_Extra_limite_new, setDesconto_Extra_Limite_New] = useState(
    0.0
  );

  const [isDefined, setIsDefined] = useState(false);
  const [enableButtonValue, setEnableButtonValue] = useState(false);
  const [enableButtonValuePIX, setEnableButtonValuePIX] = useState(false);
  const [enableButtonLimitePIX, setEnableButtonLimitePIX] = useState(false);
  const [enableButtonValueExtra, setEnableButtonValueExtra] = useState(false);
  const [enableButtonLimiteExtra, setEnableButtonLimiteExtra] = useState(false);
  const [enableButtonLimit, setEnableButtonLimit] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  async function loadFreight() {
    try {
      const data = await req(
        `
            {
                getFreight{
                    valor
                    limite
                }
            }
            `
      );
      if (data.data) {
        setIsDefined(true);
        setFrete(data.data.getFreight);
      } else if (data.errors) {
        toast.error(data.errors[0].message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function loadCartAlertMessage() {
    try {
      const data = await req(
        `
          {
              getAlertMessage{
                text_value
              }
          }
          `
      );
      if (data.data) {
        setAlert_message(data.data.getAlertMessage.text_value);
      } else if (data.errors) {
        toast.error(data.errors[0].message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function loadCartCompraMinima() {
    try {
      const data = await req(
        `
          {
              getCompraMinima{
                number_value
              },
              getExpirationPixTime{
                number_value
              },
          }
          `
      );
      if (data.data) {
        setCompraMinima(data.data.getCompraMinima.number_value);
        let value_atual = data.data.getExpirationPixTime.number_value;
        setExpiratiom_Pix_Time(value_atual ? value_atual : 0);
      } else if (data.errors) {
        toast.error(data.errors[0].message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function loadDescontoPix() {
    try {
      const resp = await req(
        `
          {
            getPixPromotion{
              valor
              limite
            }
          }
          `
      );
      if (resp.data) {
        if (!resp.data.getPixPromotion) return;
        setDesconto_Pix_Value(resp.data.getPixPromotion.valor);
        setDesconto_Pix_Limite(resp.data.getPixPromotion.limite);
      } else if (resp.errors) {
        toast.error(resp.errors[0].message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function loadDescontoExtra() {
    try {
      const resp = await req(
        `
          {
            getDescontoExtra{
              valor
              limite
            }
          }
          `
      );
      if (resp.data) {
        setDesconto_Extra_Value(resp.data.getDescontoExtra.valor);
        setDesconto_Extra_Limite(resp.data.getDescontoExtra.limite);
      } else if (resp.errors) {
        toast.error(resp.errors[0].message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function defineFreightValue() {
    try {
      if (novoFrete !== '') {
        await req(
          `
            mutation{
                defineFreight
                (
                  fields: {
                    valor: ${novoFrete}
                  }
                )
              }
            `
        );
        setHasChanged(!hasChanged);
        toast.success('Valor da entrega definido com sucesso!');
      } else toast.warning('O campo não pode estar vazio!');
    } catch (error) {
      alert(error);
    }
  }

  async function updateFreightValue() {
    try {
      if (novoFrete !== '') {
        await req(
          `
            mutation{
                updateFreight
                (
                  fields: {
                    valor: ${novoFrete}
                  }
                )
              }
            `
        );
        setHasChanged(!hasChanged);
        toast.success('Valor da entrega atualizado com sucesso!');
      } else toast.warning('O campo não pode estar vazio!');
    } catch (error) {
      alert(error);
    }
  }

  async function defineFreightLimit() {
    try {
      if (novoLimite !== '') {
        await req(
          `
          mutation{
              defineFreight
              (
                fields: {
                  limite: ${novoLimite}
                }
              )
            }
          `
        );
        setHasChanged(!hasChanged);
        toast.success('Valor do limite definido com sucesso!');
      } else toast.warning('O campo não pode estar vazio!');
    } catch (error) {
      alert(error);
    }
  }

  async function updateFreightLimit() {
    try {
      if (novoLimite) {
        await req(
          `
          mutation{
              updateFreight
              (
                fields: {
                  limite: ${novoLimite}
                }
              )
            }
          `
        );
        setHasChanged(!hasChanged);
        toast.success('Valor do limite atualizado com sucesso!');
      } else toast.warning('O campo não pode estar vazio!');
    } catch (error) {
      alert(error);
    }
  }

  async function updateAlertMessage() {
    try {
      if (new_alert_message.length !== 0) {
        await req(
          `
        mutation{
          updateAlertMessage
            (
              text_value:"${new_alert_message}"
            )
          }
        `
        );
        toast.success('Mensagem de alerta atualizada com sucesso!');
        setAlert_message(new_alert_message);
        setNewAlert_message('');
      } else toast.warning('O campo não pode estar vazio!');
    } catch (error) {
      console.log(error);
      alert(error);
    }
  }

  async function updateCompraMinima() {
    try {
      if (new_compraMinima > 0) {
        await req(
          `
        mutation{
          updateCompraMinima
            (
              number_value:${new_compraMinima}
            )
          }
        `
        );
        toast.success('Valor da compra minima atualizado com sucesso!');
        setCompraMinima(new_compraMinima);
        setNewCompraMinima('');
      } else toast.warning('O campo não pode estar vazio!');
    } catch (error) {
      console.log(error);

      alert(error);
    }
  }

  async function updatePixTime() {
    try {
      const int_time = parseInt(new_expiratiom_pix_time);

      if (int_time > 0) {
        await req(
          `
        mutation{
          updateExpirationPixTime
            (
              number_value:${int_time}
            )
          }
        `
        );
        toast.success(
          'Valor do tempo de expiração do pix atualizado com sucesso!'
        );
        setNewExpiratiom_Pix_Time('');
        setExpiratiom_Pix_Time(int_time);
      } else toast.warning('O campo não pode estar vazio!');
    } catch (error) {
      console.log(error);

      alert(error);
    }
  }

  async function updateDescontoPix(tipo) {
    try {
      if (tipo === 0) {
        const new_value = parseFloat(desconto_pix_value_new);
        await req(
          `
        mutation{
          updatePixPromotion
            (
              valor:${new_value}
            )
          }
        `
        );
        toast.success('Dados atualizados com sucesso!');

        setDesconto_Pix_Value_New('');
        setDesconto_Pix_Value(new_value);
      } else {
        const new_limite = parseFloat(desconto_pix_limite_new);
        await req(
          `
        mutation{
          updatePixPromotion
            (
              limite:${new_limite}
            )
          }
        `
        );
        toast.success('Dados atualizados com sucesso!');

        setDesconto_Pix_Limite_New('');
        setDesconto_Pix_Limite(new_limite);
      }
    } catch (error) {
      console.log(error);

      alert(error);
    }
  }

  async function updateDescontoExtra(tipo) {
    try {
      if (tipo === 0) {
        const new_value = parseFloat(desconto_Extra_value_new);
        await req(
          `
        mutation{
          updateDescontoExtra
            (
              valor:${new_value}
            )
          }
        `
        );
        toast.success('Dados atualizados com sucesso!');

        setDesconto_Extra_Value_New('');
        setDesconto_Extra_Value(new_value);
      } else {
        const new_limite = parseFloat(desconto_Extra_limite_new);
        await req(
          `
        mutation{
          updateDescontoExtra
            (
              limite:${new_limite}
            )
          }
        `
        );
        toast.success('Dados atualizados com sucesso!');

        setDesconto_Extra_Limite_New('');
        setDesconto_Extra_Limite(new_limite);
      }
    } catch (error) {
      console.log(error);

      alert(error);
    }
  }

  const handleValueChangePixDescontoValue = event => {
    setEnableButtonValuePIX(true);
    setDesconto_Pix_Value_New(event.target.value);
  };

  const handleValueChangePixDescontoLimite = event => {
    setEnableButtonLimitePIX(true);
    setDesconto_Pix_Limite_New(event.target.value);
  };

  const handleValueChangeExtraDescontoValue = event => {
    setEnableButtonValueExtra(true);
    setDesconto_Extra_Value_New(event.target.value);
  };

  const handleValueChangeExtraDescontoLimite = event => {
    setEnableButtonLimiteExtra(true);
    setDesconto_Extra_Limite_New(event.target.value);
  };

  const handleValueChange = event => {
    setEnableButtonValue(true);
    setNovoFrete(event.target.value);
  };

  const handleLimitChange = event => {
    setEnableButtonLimit(true);
    setNovoLimite(event.target.value);
  };

  useEffect(() => {
    loadFreight();
    loadCartAlertMessage();
    loadCartCompraMinima();
    loadDescontoPix();
    loadDescontoExtra();
  }, [hasChanged]);

  return (
    <Page className={classes.root} title="Carrinho">
      {(permissions.editarValorDeEntrega || permissions.editarLimite) && (
        <Container maxWidth={false}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <TruckIcon color="#5e5e5f" style={{ margin: 10 }} />
            <Typography color="textPrimary" variant="h2">
              Entrega
            </Typography>
          </div>

          <form
            autoComplete="off"
            onSubmit={() => {}}
            className={clsx(classes.root, className)}
            {...rest}
          >
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  {permissions.editarValorDeEntrega && (
                    <Fragment>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          fullWidth
                          label="Valor de entrega Atual"
                          variant="outlined"
                          name="preco"
                          value={
                            isDefined
                              ? frete.valor?.toLocaleString('pt-br', {
                                  style: 'currency',
                                  currency: 'BRL'
                                })
                              : 'R$ 0.00'
                          }
                          InputProps={{
                            readOnly: true
                          }}
                          InputLabelProps={{
                            style: { color: '#f18750' }
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={2}>
                        <TextField
                          fullWidth
                          label="Novo valor de entrega"
                          name="novo preco"
                          onChange={event => {
                            event.target.value = event.target.value.replace(
                              '.',
                              ''
                            );
                            var numbers = /^[0-9]*$/;
                            if (event.target.value.match(numbers)) {
                              event.target.value = event.target.value.replace(
                                /(.)(?=(\d{2})$)/g,
                                '$1.'
                              );
                              handleValueChange(event);
                            }
                          }}
                          variant="outlined"
                          InputLabelProps={{
                            style: { color: '#c4c4c4' }
                          }}
                        />
                      </Grid>

                      <Grid item xs={6} sm={2}>
                        <Button
                          onClick={
                            isDefined ? updateFreightValue : defineFreightValue
                          }
                          color="primary"
                          variant="contained"
                          disabled={!enableButtonValue}
                          style={{
                            height: 55
                          }}
                        >
                          {' '}
                          {isDefined ? (
                            <Typography
                              style={{
                                color: '#fff',
                                display: 'flex'
                              }}
                            >
                              Atualizar valor de entrega
                            </Typography>
                          ) : (
                            <Typography
                              style={{
                                color: '#fff',
                                display: 'flex'
                              }}
                            >
                              Definir valor de entrega
                            </Typography>
                          )}
                        </Button>
                      </Grid>
                    </Fragment>
                  )}

                  {permissions.editarLimite && (
                    <Fragment>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          fullWidth
                          label="Limite - entrega grátis"
                          name="preco"
                          variant="outlined"
                          value={
                            isDefined
                              ? frete.limite?.toLocaleString('pt-br', {
                                  style: 'currency',
                                  currency: 'BRL'
                                })
                              : 'R$ 0,00'
                          }
                          InputProps={{
                            readOnly: true
                          }}
                          InputLabelProps={{
                            style: { color: '#f18750' }
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={2}>
                        <TextField
                          fullWidth
                          label="Novo Limite"
                          name="novo limite"
                          onChange={event => {
                            event.target.value = event.target.value.replace(
                              '.',
                              ''
                            );
                            var numbers = /^[0-9]*$/;
                            if (event.target.value.match(numbers)) {
                              event.target.value = event.target.value.replace(
                                /(.)(?=(\d{2})$)/g,
                                '$1.'
                              );
                              handleLimitChange(event);
                            }
                          }}
                          variant="outlined"
                          InputLabelProps={{
                            style: { color: '#c4c4c4' }
                          }}
                        />
                      </Grid>

                      <Grid item xs={6} sm={2}>
                        <Button
                          onClick={
                            isDefined ? updateFreightLimit : defineFreightLimit
                          }
                          color="primary"
                          variant="contained"
                          disabled={!enableButtonLimit}
                          style={{
                            height: 55
                          }}
                        >
                          {' '}
                          {isDefined ? (
                            <Typography
                              style={{
                                color: '#fff',
                                display: 'flex'
                              }}
                            >
                              Atualizar Limite
                            </Typography>
                          ) : (
                            <Typography
                              style={{
                                color: '#fff',
                                display: 'flex'
                              }}
                            >
                              Definir Limite
                            </Typography>
                          )}
                        </Button>
                      </Grid>
                    </Fragment>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </form>
        </Container>
      )}

      {permissions.editarMensagemDeAlerta && (
        <Container maxWidth={false}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <MessageSquare color="#5e5e5f" style={{ margin: 10 }} />
            <Typography color="textPrimary" variant="h2">
              Mensagem de Alerta
            </Typography>
          </div>

          <form
            autoComplete="off"
            onSubmit={() => {}}
            className={clsx(classes.root, className)}
            {...rest}
          >
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      id="input"
                      label="Mensagem Atual"
                      variant="outlined"
                      multiline
                      rows={4}
                      rowsMax={4}
                      name="aviso_agendamento"
                      value={alert_message}
                      InputProps={{
                        readOnly: true
                      }}
                      InputLabelProps={{
                        style: { color: '#f18750' }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Nova Mensagem "
                      variant="outlined"
                      multiline
                      rows={4}
                      rowsMax={4}
                      onChange={e => setNewAlert_message(e.target.value)}
                      name="aviso_agendamento"
                      value={new_alert_message}
                      InputLabelProps={{
                        style: { color: '#c4c4c4' }
                      }}
                    />
                  </Grid>

                  <Grid item xs={6} sm={4}>
                    <Button
                      onClick={updateAlertMessage}
                      color="primary"
                      variant="contained"
                      disabled={new_alert_message === ''}
                      style={{
                        height: 55
                      }}
                    >
                      <Typography
                        style={{
                          color: '#fff',
                          display: 'flex'
                        }}
                      >
                        Atualizar Mensagem
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </form>
        </Container>
      )}

      {permissions.editarValorDeCompraMinima && (
        <Container maxWidth={false}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <DollarSign color="#5e5e5f" style={{ margin: 5 }} />
            <Typography color="textPrimary" variant="h2">
              Compra mínima
            </Typography>
          </div>

          <form
            autoComplete="off"
            onSubmit={() => {}}
            className={clsx(classes.root, className)}
            {...rest}
          >
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      fullWidth
                      label="Valor Atual"
                      variant="outlined"
                      name="preco"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">R$</InputAdornment>
                        )
                      }}
                      value={compraMinima.toLocaleString('pt-br', {
                        minimumFractionDigits: 2
                      })}
                      InputLabelProps={{
                        style: { color: '#f18750' }
                      }}
                    />
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    <TextField
                      fullWidth
                      label="Nova Compra Minima"
                      name="novo preco"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">R$</InputAdornment>
                        )
                      }}
                      onChange={event => {
                        event.target.value = event.target.value.replace(
                          '.',
                          ''
                        );
                        var numbers = /^[0-9]*$/;
                        if (event.target.value.match(numbers)) {
                          event.target.value = event.target.value.replace(
                            /(.)(?=(\d{2})$)/g,
                            '$1.'
                          );
                          setNewCompraMinima(event.target.value);
                        }
                      }}
                      variant="outlined"
                      InputLabelProps={{
                        style: { color: '#c4c4c4' }
                      }}
                    />
                  </Grid>

                  <Grid item xs={6} sm={2}>
                    <Button
                      onClick={updateCompraMinima}
                      type="number"
                      color="primary"
                      variant="contained"
                      disabled={new_compraMinima === 0.0}
                      style={{
                        height: 55
                      }}
                    >
                      <Typography
                        style={{
                          color: '#fff',
                          display: 'flex'
                        }}
                      >
                        Atualizar valor
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </form>
        </Container>
      )}

      {permissions.editarTempoDeExpiracaoPix && (
        <Container maxWidth={'50%'}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <Clock color="#5e5e5f" style={{ margin: 5 }} />
            <Typography color="textPrimary" variant="h2">
              Tempo de expiração do Pix
            </Typography>
          </div>

          <form
            autoComplete="off"
            onSubmit={() => {}}
            className={clsx(classes.root, className)}
            {...rest}
          >
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      fullWidth
                      label="Tempo  Atual"
                      variant="outlined"
                      name="preco"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            minutos
                          </InputAdornment>
                        )
                      }}
                      value={expiratiom_pix_time}
                      InputLabelProps={{
                        style: { color: '#f18750' }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={2}>
                    <TextField
                      fullWidth
                      label="Novo Tempo"
                      name="novo tempo"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            minutos
                          </InputAdornment>
                        )
                      }}
                      value={new_expiratiom_pix_time}
                      onChange={e => setNewExpiratiom_Pix_Time(e.target.value)}
                      variant="outlined"
                      InputLabelProps={{
                        style: { color: '#c4c4c4' }
                      }}
                    />
                  </Grid>

                  <Grid item xs={6} sm={2}>
                    <Button
                      onClick={updatePixTime}
                      color="primary"
                      variant="contained"
                      disabled={
                        new_expiratiom_pix_time === 0 ||
                        new_expiratiom_pix_time === ''
                      }
                      style={{
                        height: 55
                      }}
                    >
                      <Typography
                        style={{
                          color: '#fff',
                          display: 'flex'
                        }}
                      >
                        Atualizar Tempo
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </form>
        </Container>
      )}

      {(permissions.editarDescontoPix ||
        permissions.editarLimiteDescontoPix) && (
        <Container maxWidth={false}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <Percent color="#5e5e5f" style={{ margin: 10 }} />
            <Typography color="textPrimary" variant="h2">
              Desconto Pix
            </Typography>
          </div>

          <form
            autoComplete="off"
            onSubmit={() => {}}
            className={clsx(classes.root, className)}
            {...rest}
          >
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  {permissions.editarDescontoPix && (
                    <Fragment>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          fullWidth
                          label="Pecentual de desconto Atual"
                          variant="outlined"
                          name="pecentual"
                          value={desconto_pix_value}
                          InputProps={{
                            readOnly: true,
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            )
                          }}
                          InputLabelProps={{
                            style: { color: '#f18750' }
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={2}>
                        <TextField
                          fullWidth
                          label="Novo pecentual"
                          name="novo pecentual"
                          value={desconto_pix_value_new}
                          onChange={event =>
                            handleValueChangePixDescontoValue(event)
                          }
                          variant="outlined"
                          InputLabelProps={{
                            style: { color: '#c4c4c4' }
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            )
                          }}
                        />
                      </Grid>

                      <Grid item xs={6} sm={2}>
                        <Button
                          onClick={() => updateDescontoPix(0)}
                          color="primary"
                          variant="contained"
                          disabled={!enableButtonValuePIX}
                          style={{
                            height: 55
                          }}
                        >
                          <Typography
                            style={{
                              color: '#fff',
                              display: 'flex'
                            }}
                          >
                            Atualizar desconto
                          </Typography>
                        </Button>
                      </Grid>
                    </Fragment>
                  )}

                  {/* {permissions.editarLimiteDescontoPix && (
                    <Fragment>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          fullWidth
                          label="Limite - desconto pix"
                          name="preco"
                          variant="outlined"
                          value={desconto_pix_limite?.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL'
                          })}
                          InputProps={{
                            readOnly: true
                          }}
                          InputLabelProps={{
                            style: { color: '#f18750' }
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={2}>
                        <TextField
                          fullWidth
                          label="Novo Limite"
                          value={desconto_pix_limite_new}
                          name="novo limite"
                          onChange={event => {
                            event.target.value = event.target.value.replace(
                              '.',
                              ''
                            );
                            var numbers = /^[0-9]*$/;
                            if (event.target.value.match(numbers)) {
                              event.target.value = event.target.value.replace(
                                /(.)(?=(\d{2})$)/g,
                                '$1.'
                              );
                              handleValueChangePixDescontoLimite(event);
                            }
                          }}
                          variant="outlined"
                          InputLabelProps={{
                            style: { color: '#c4c4c4' }
                          }}
                        />
                      </Grid>

                      <Grid item xs={6} sm={2}>
                        <Button
                          onClick={() => updateDescontoPix(1)}
                          color="primary"
                          variant="contained"
                          disabled={!enableButtonLimitePIX}
                          style={{
                            height: 55
                          }}
                        >
                          <Typography
                            style={{
                              color: '#fff',
                              display: 'flex'
                            }}
                          >
                            Atualizar Limite
                          </Typography>
                        </Button>
                      </Grid>
                    </Fragment>
                  )} */}
                </Grid>
              </CardContent>
            </Card>
          </form>
        </Container>
      )}

      {(permissions.editarDescontoPix ||
        permissions.editarLimiteDescontoPix) && (
        <Container maxWidth={false}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <Percent color="#5e5e5f" style={{ margin: 10 }} />
            <Typography color="textPrimary" variant="h2">
              Desconto extra
            </Typography>
          </div>

          <form
            autoComplete="off"
            onSubmit={() => {}}
            className={clsx(classes.root, className)}
            {...rest}
          >
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  {permissions.editarDescontoPix && (
                    <Fragment>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          fullWidth
                          label="Valor de desconto Atual"
                          variant="outlined"
                          name="valor"
                          value={desconto_Extra_value.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL'
                          })}
                          InputProps={{
                            readOnly: true
                          }}
                          InputLabelProps={{
                            style: { color: '#f18750' }
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={2}>
                        <TextField
                          fullWidth
                          label="Novo valor"
                          name="novo valor"
                          type="number"
                          value={desconto_Extra_value_new}
                          onChange={event => {
                            event.target.value = event.target.value.replace(
                              '.',
                              ''
                            );
                            var numbers = /^[0-9]*$/;
                            if (event.target.value.match(numbers)) {
                              event.target.value = event.target.value.replace(
                                /(.)(?=(\d{2})$)/g,
                                '$1.'
                              );
                              handleValueChangeExtraDescontoValue(event);
                            }
                          }}
                          variant="outlined"
                          InputLabelProps={{
                            style: { color: '#c4c4c4' }
                          }}
                        />
                      </Grid>

                      <Grid item xs={6} sm={2}>
                        <Button
                          onClick={() => updateDescontoExtra(0)}
                          color="primary"
                          variant="contained"
                          disabled={!enableButtonValueExtra}
                          style={{
                            height: 55
                          }}
                        >
                          <Typography
                            style={{
                              color: '#fff',
                              display: 'flex'
                            }}
                          >
                            Atualizar desconto
                          </Typography>
                        </Button>
                      </Grid>
                    </Fragment>
                  )}

                  {permissions.editarLimiteDescontoPix && (
                    <Fragment>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          fullWidth
                          label="Limite - desconto extra"
                          name="preco"
                          variant="outlined"
                          value={desconto_Extra_limite?.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL'
                          })}
                          InputProps={{
                            readOnly: true
                          }}
                          InputLabelProps={{
                            style: { color: '#f18750' }
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={2}>
                        <TextField
                          fullWidth
                          label="Novo Limite"
                          value={desconto_Extra_limite_new}
                          name="novo limite"
                          onChange={event => {
                            event.target.value = event.target.value.replace(
                              '.',
                              ''
                            );
                            var numbers = /^[0-9]*$/;
                            if (event.target.value.match(numbers)) {
                              event.target.value = event.target.value.replace(
                                /(.)(?=(\d{2})$)/g,
                                '$1.'
                              );
                              handleValueChangeExtraDescontoLimite(event);
                            }
                          }}
                          variant="outlined"
                          InputLabelProps={{
                            style: { color: '#c4c4c4' }
                          }}
                        />
                      </Grid>

                      <Grid item xs={6} sm={2}>
                        <Button
                          onClick={() => updateDescontoExtra(1)}
                          color="primary"
                          variant="contained"
                          disabled={!enableButtonLimiteExtra}
                          style={{
                            height: 55
                          }}
                        >
                          <Typography
                            style={{
                              color: '#fff',
                              display: 'flex'
                            }}
                          >
                            Atualizar Limite
                          </Typography>
                        </Button>
                      </Grid>
                    </Fragment>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </form>
        </Container>
      )}
    </Page>
  );
};

export default Frete;
