export const formatPermissions = permissions => ({
  produtos: {
    exibirAba: permissions.produtosExibirAba,
    adicionar: permissions.produtosAdicionar,
    editar: permissions.produtosEditar,
    excluir: permissions.produtosExcluir
  },
  categoriasApp: {
    exibirAba: permissions.categoriasAppExibirAba,
    adicionar: permissions.categoriasAppAdicionar,
    editar: permissions.categoriasAppEditar,
    excluir: permissions.categoriasAppExcluir
  },
  categoriasSite: {
    exibirBotao: permissions.categoriasSiteExibirBotao,
    adicionar: permissions.categoriasSiteAdicionar,
    excluir: permissions.categoriasSiteExcluir
  },
  marcas: {
    exibirAba: permissions.marcasExibirAba,
    adicionar: permissions.marcasAdicionar,
    editar: permissions.marcasEditar,
    excluir: permissions.marcasExcluir
  },
  pedidos: {
    exibirAba: permissions.pedidosExibirAba,
    alterarStatus: permissions.pedidosAlterarStatus
  },
  promocoes: {
    exibirAba: permissions.promocoesExibirAba,
    adicionar: permissions.promocoesAdicionar,
    excluir: permissions.promocoesExcluir
  },
  vendedores: {
    exibirAba: permissions.vendedoresExibirAba,
    adicionar: permissions.vendedoresAdicionar,
    editar: permissions.vendedoresEditar,
    excluir: permissions.vendedoresExcluir,
    downloadRelatorio: permissions.vendedoresDownloadRelatorio
  },
  cupons: {
    exibirAba: permissions.cuponsExibirAba,
    adicionar: permissions.cuponsAdicionar,
    editar: permissions.cuponsEditar,
    excluir: permissions.cuponsExcluir
  },
  contato: {
    exibirAba: permissions.contatoExibirAba,
    editarTelefone: permissions.contatoEditarTelefone,
    editarEmail: permissions.contatoEditarEmail,
    editarEndereco: permissions.contatoEditarEndereco,
    editarLinksMapa: permissions.contatoEditarLinksMapa
  },
  usuarios: {
    exibirAba: permissions.usuariosExibirAba,
    ativarUsuario: permissions.usuariosAtivarUsuario
  },
  informacoesAdicionais: {
    exibirAba: permissions.informacoesAdicionaisExibirAba,
    editarPrazoAtual: permissions.informacoesAdicionaisEditarPrazoAtual
  },
  faq: {
    exibirAba: permissions.faqExibirAba,
    adicionar: permissions.faqAdicionar,
    editar: permissions.faqEditar,
    excluir: permissions.faqExcluir
  },
  orcamentos: {
    exibirAba: permissions.orcamentosExibirAba,
    adicionar: permissions.orcamentosAdicionar,
    excluir: permissions.orcamentosExcluir,
    faturar: permissions.orcamentosFaturar
  },
  carrinho: {
    exibirAba: permissions.carrinhoExibirAba,
    editarValorDeEntrega: permissions.carrinhoEditarValorDeEntrega,
    editarLimite: permissions.carrinhoEditarLimite,
    editarMensagemDeAlerta: permissions.carrinhoEditarMensagemDeAlerta,
    editarValorDeCompraMinima: permissions.carrinhoEditarValorDeCompraMinima,
    editarTempoDeExpiracaoPix: permissions.carrinhoEditarTempoDeExpiracaoPix,
    editarDescontoPix: permissions.carrinhoEditarDescontoPix,
    editarLimiteDescontoPix: permissions.carrinhoEditarLimiteDescontoPix
  },
  entrega: {
    exibirAba: permissions.entregaExibirAba,
    adicionar: permissions.entregaAdicionar,
    editar: permissions.entregaEditar
  }
});
