import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { Download as DownloadIcon } from 'react-feather';

import { req } from '../../../services/api';
import exportToExcel from 'src/utils/excel';

export default function ExportProducts({ categoria, hasQuantFilter, quantOperator, quantValue, filter }) {
  const [loading, setLoading] = useState(false);

  async function getProducts() {
    setLoading(true);
    try {
      const data = await req(`
        {
          readProduct(
            filter: "${filter}"
            ${categoria() !== 0 ? `categoria: "${categoria()}"` : ''}
            ${hasQuantFilter() ? `
              numberFilters: [
                {column: "quantidade_estoque", operator: ${quantOperator()}, value: ${quantValue()}}
              ]
            ` : ''}
          ){
            data {
              uuid
              codigo
              nome_formal
              category{
                nome
              }
              marca
              quantidade_estoque
              preco
              desconto_pix
            }
            total
          }
        }
      `);
      if (data?.data) {
        const products = data.data.readProduct.data.map(product => ({
          Codigo: product.codigo,
          Nome: product.nome_formal,
          Categoria: product.category.nome,
          Marca: product.marca,
          Quantidade: product.quantidade_estoque,
          Preco: product.preco,
          Desconto_PIX: product.desconto_pix,
        }));
        exportToExcel(products, `produtos-${new Date().toLocaleString('pt-BR')}`, "Produtos");
      } else if (data.errors) {
        alert(data.errors);
      }
    } catch (err) {
      alert(err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Button
      color="primary"
      variant="contained"
      style={{ marginRight: 15, height: 50 }}
      onClick={getProducts}
      disabled={loading}
    >
      {loading ? 'Carregando...' : <DownloadIcon color="white" />}
    </Button>
  );
}