const INITIAL_STATE = {
  tabOrcamento: false,
  tabPedidos: false
};

const tabs = (state = INITIAL_STATE, action) => {
  const baseAction = '@tabs/';
  switch (action.type) {
    case `${baseAction}ALTERAR_TAB_ORCAMENTO`:
      return {
        ...state,
        tabOrcamento: action.payload.tab
      };
    case `${baseAction}ALTERAR_TAB_PEDIDOS`:
      return {
        ...state,
        tabPedidos: action.payload.tab
      };
    default:
      return state;
  }
};

export default tabs;
