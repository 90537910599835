import React, { useState, useEffect, Fragment } from 'react';
import {
  Typography,
  Container,
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  makeStyles
} from '@material-ui/core';
import { req } from '../../services/api';
import { toast } from 'react-toastify';
import Page from 'src/components/Page';
import clsx from 'clsx';

import {
  Phone as PhoneIcon,
  Map as MapIcon,
  ArrowUpRight as ArrowUpRightIcon
} from 'react-feather';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  title: {
    paddingBottom: theme.spacing(1),
    marginLeft: theme.spacing(2)
  },
  input: {
    display: 'none'
  },
  inputLabel: {
    paddingBottom: theme.spacing(1)
  }
}));

const Contato = ({ className, permissions, ...rest }) => {
  const classes = useStyles();

  const [novoTelefone, setNovoTelefone] = useState('');
  const [novoEmail, setNovoEmail] = useState('');
  const [contato, setContato] = useState(null);

  const [novoCep, setNovoCep] = useState('');
  const [novoUf, setNovoUf] = useState('');
  const [novoLogradouro, setNovoLogradouro] = useState('');
  const [novoComplemento, setNovoComplemento] = useState('');
  const [novoCidade, setNovoCidade] = useState('');
  const [novoBairro, setNovoBairro] = useState('');
  const [novoNumero, setNovoNumero] = useState('');

  const [novoLinkGoogle, setNovoLinkGoogle] = useState('');
  const [novoLinkApple, setNovoLinkApple] = useState('');

  const [enableButtonTelefone, setEnableButtonTelefone] = useState(false);
  const [enableButtonEmail, setEnableButtonEmail] = useState(false);
  const [enableButtonLinkGoogle, setEnableButtonLinkGoogle] = useState(false);
  const [enableButtonLinkApple, setEnableButtonLinkApple] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  const isAddressValid = () =>
    novoCep !== '' &&
    novoUf !== '' &&
    novoLogradouro !== '' &&
    novoComplemento !== '' &&
    novoCidade !== '' &&
    novoBairro !== '' &&
    novoNumero;

  function defineLinkGoogle() {}

  function defineLinkApple() {}
  async function loadContact() {
    try {
      const data = await req(
        `
                {
                    getContact{
                        telefone
                        email
                        linkApple
                        linkGoogle
                        cep
                        uf
                        cidade
                        logradouro
                        bairro
                        complemento
                        numero
                    }
    
                }
                `
      );
      if (data.data) {
        setContato(data.data.getContact);
        setNovoCep(data.data.getContact.cep);
        setNovoBairro(data.data.getContact.bairro);
        setNovoCidade(data.data.getContact.cidade);
        setNovoComplemento(data.data.getContact.complemento);
        setNovoNumero(data.data.getContact.numero);
        setNovoLogradouro(data.data.getContact.logradouro);
        setNovoUf(data.data.getContact.uf);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function defineContactTelefone() {
    try {
      if (novoTelefone !== '') {
        await req(
          `
                mutation{
                    defineContact
                    (
                      fields: {
                        telefone: "${novoTelefone}"
                      }
                    )
                  }
                `
        );
        setHasChanged(!hasChanged);
        toast.success('Telefone definido com sucesso!');
        setNovoTelefone('');
      } else toast.warning('O campo não pode estar vazio!');
    } catch (error) {
      alert(error);
    }
  }

  async function defineContactEmail() {
    try {
      if (novoEmail !== '') {
        await req(
          `
              mutation{
                  defineContact
                  (
                    fields: {
                      email: "${novoEmail}"
                    }
                  )
                }
              `
        );
        setHasChanged(!hasChanged);
        toast.success('Email definido com sucesso!');
        setNovoEmail('');
      } else toast.warning('O campo não pode estar vazio!');
    } catch (error) {
      alert(error);
    }
  }

  async function defineEndereco() {
    try {
      if (isAddressValid()) {
        await req(
          `
              mutation{
                  defineContact
                  (
                    fields: {
                      cep: "${novoCep}"
                      uf: "${novoUf}"
                      cidade: "${novoCidade}"
                      logradouro: "${novoLogradouro}"
                      bairro: "${novoBairro}"
                      complemento: "${novoComplemento}"
                      numero: "${novoNumero}"
                    }
                  )
                }
              `
        );
        setHasChanged(!hasChanged);
        toast.success('Endereço definido com sucesso!');
      } else toast.warning('O campo não pode estar vazio!');
    } catch (error) {
      alert(error);
    }
  }

  async function defineLinkGoogle() {
    try {
      if (novoLinkGoogle !== '') {
        await req(
          `
                mutation{
                    defineContact
                    (
                      fields: {
                        linkGoogle: "${novoLinkGoogle}"
                      }
                    )
                  }
                `
        );
        setHasChanged(!hasChanged);
        toast.success('Link definido com sucesso!');
        setNovoTelefone('');
      } else toast.warning('O campo não pode estar vazio!');
    } catch (error) {
      alert(error);
    }
  }
  async function defineLinkApple() {
    console.log(novoLinkApple);
    try {
      if (novoLinkApple !== '') {
        await req(
          `
                mutation{
                    defineContact
                    (
                      fields: {
                        linkApple: "${novoLinkApple}"
                      }
                    )
                  }
                `
        );
        setHasChanged(!hasChanged);
        toast.success('Link definido com sucesso!');
        setNovoTelefone('');
      } else toast.warning('O campo não pode estar vazio!');
    } catch (error) {
      alert(error);
    }
  }

  useEffect(() => {
    loadContact();
  }, [hasChanged]);

  return (
    <Page className={classes.root} title="Contato">
      <Container maxWidth={false}>
        {(permissions.editarTelefone || permissions.editarEmail) && (
          <Fragment>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <PhoneIcon color="#5e5e5f" style={{ margin: 10 }} />
              <Typography color="textPrimary" variant="h2">
                Informações de contato
              </Typography>
            </div>

            <form
              autoComplete="off"
              onSubmit={() => {}}
              className={clsx(classes.root, className)}
              {...rest}
            >
              <Card>
                <CardContent>
                  <Grid container spacing={2}>
                    {permissions.editarTelefone && (
                      <Fragment>
                        <Grid item xs={12} sm={2}>
                          <TextField
                            fullWidth
                            label="Telefone"
                            variant="outlined"
                            name="preco"
                            value={
                              contato && contato.telefone !== ''
                                ? contato.telefone
                                : '(00) 00000-0000'
                            }
                            InputProps={{
                              readOnly: true
                            }}
                            InputLabelProps={{
                              style: { color: '#c4c4c4' }
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={2}>
                          <TextField
                            fullWidth
                            label="Novo telefone"
                            name="novo telefone"
                            value={novoTelefone}
                            onChange={event => {
                              setNovoTelefone(event.target.value);
                              setEnableButtonTelefone(true);
                            }}
                            variant="outlined"
                            InputLabelProps={{
                              style: { color: '#c4c4c4' }
                            }}
                          />
                        </Grid>

                        <Grid item xs={6} sm={2}>
                          <Button
                            onClick={defineContactTelefone}
                            color="primary"
                            variant="contained"
                            disabled={!enableButtonTelefone}
                            style={{
                              height: 55
                            }}
                          >
                            {' '}
                            {contato && contato.telefone !== '' ? (
                              <Typography
                                style={{
                                  color: '#fff',
                                  display: 'flex'
                                }}
                              >
                                Atualizar telefone
                              </Typography>
                            ) : (
                              <Typography
                                style={{
                                  color: '#fff',
                                  display: 'flex'
                                }}
                              >
                                Definir telefone
                              </Typography>
                            )}
                          </Button>
                        </Grid>
                      </Fragment>
                    )}
                    {permissions.editarEmail && (
                      <Fragment>
                        <Grid item xs={12} sm={2}>
                          <TextField
                            fullWidth
                            label="E-mail"
                            name="email"
                            variant="outlined"
                            value={
                              contato && contato.email !== ''
                                ? contato.email
                                : 'exemplo@exemplo.com'
                            }
                            InputProps={{
                              readOnly: true
                            }}
                            InputLabelProps={{
                              style: { color: '#c4c4c4' }
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={2}>
                          <TextField
                            fullWidth
                            label="Novo email"
                            type="email"
                            name="novo email"
                            value={novoEmail}
                            onChange={event => {
                              setNovoEmail(event.target.value);
                              setEnableButtonEmail(true);
                            }}
                            variant="outlined"
                            InputLabelProps={{
                              style: { color: '#c4c4c4' }
                            }}
                          />
                        </Grid>

                        <Grid item xs={6} sm={2}>
                          <Button
                            onClick={defineContactEmail}
                            color="primary"
                            variant="contained"
                            disabled={!enableButtonEmail}
                            style={{
                              height: 55
                            }}
                          >
                            {' '}
                            {contato && contato.email !== '' ? (
                              <Typography
                                style={{
                                  color: '#fff',
                                  display: 'flex'
                                }}
                              >
                                Atualizar e-mail
                              </Typography>
                            ) : (
                              <Typography
                                style={{
                                  color: '#fff',
                                  display: 'flex'
                                }}
                              >
                                Definir e-mail
                              </Typography>
                            )}
                          </Button>
                        </Grid>
                      </Fragment>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </form>
          </Fragment>
        )}
        {permissions.editarEndereco && (
          <Fragment>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <MapIcon color="#5e5e5f" style={{ margin: 10 }} />
              <Typography color="textPrimary" variant="h2">
                Informações de endereço
              </Typography>
            </div>
            <form
              autoComplete="off"
              onSubmit={() => {}}
              className={clsx(classes.root, className)}
              {...rest}
            >
              <Card>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        fullWidth
                        label="CEP"
                        variant="outlined"
                        name="cep"
                        value={novoCep}
                        onChange={event => {
                          setNovoCep(event.target.value);
                        }}
                        InputLabelProps={{
                          style: { color: '#c4c4c4' }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        fullWidth
                        label="UF"
                        variant="outlined"
                        name="uf"
                        value={novoUf}
                        onChange={event => {
                          setNovoUf(event.target.value);
                        }}
                        InputLabelProps={{
                          style: { color: '#c4c4c4' }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        fullWidth
                        label="Cidade"
                        variant="outlined"
                        name="cidade"
                        value={novoCidade}
                        onChange={event => {
                          setNovoCidade(event.target.value);
                        }}
                        InputLabelProps={{
                          style: { color: '#c4c4c4' }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        fullWidth
                        label="Bairro"
                        variant="outlined"
                        name="bairro"
                        value={novoBairro}
                        onChange={event => {
                          setNovoBairro(event.target.value);
                        }}
                        InputLabelProps={{
                          style: { color: '#c4c4c4' }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        fullWidth
                        label="Logradouro"
                        variant="outlined"
                        name="logradouro"
                        value={novoLogradouro}
                        onChange={event => {
                          setNovoLogradouro(event.target.value);
                        }}
                        InputLabelProps={{
                          style: { color: '#c4c4c4' }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        fullWidth
                        label="Número"
                        variant="outlined"
                        name="numero"
                        value={novoNumero}
                        onChange={event => {
                          setNovoNumero(event.target.value);
                        }}
                        InputLabelProps={{
                          style: { color: '#c4c4c4' }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        fullWidth
                        label="Complemento"
                        variant="outlined"
                        name="complemento"
                        value={novoComplemento}
                        onChange={event => {
                          setNovoComplemento(event.target.value);
                        }}
                        InputLabelProps={{
                          style: { color: '#c4c4c4' }
                        }}
                      />
                    </Grid>

                    <Grid item xs={6} sm={2}>
                      <Button
                        onClick={defineEndereco}
                        color="primary"
                        variant="contained"
                        disabled={!isAddressValid()}
                        style={{
                          height: 55
                        }}
                      >
                        <Typography
                          style={{
                            color: '#fff',
                            display: 'flex'
                          }}
                        >
                          Atualizar endereço
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </form>
          </Fragment>
        )}
        {permissions.editarLinksMapa && (
          <Fragment>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <ArrowUpRightIcon color="#5e5e5f" style={{ margin: 10 }} />
              <Typography color="textPrimary" variant="h2">
                Links para aplicativos de mapa
              </Typography>
            </div>
            <form
              autoComplete="off"
              onSubmit={() => {}}
              className={clsx(classes.root, className)}
              {...rest}
            >
              <Card>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        fullWidth
                        label="Link Google"
                        variant="outlined"
                        name="link google"
                        value={
                          contato && contato.linkGoogle !== ''
                            ? contato.linkGoogle
                            : ''
                        }
                        InputProps={{
                          readOnly: true
                        }}
                        InputLabelProps={{
                          style: { color: '#c4c4c4' }
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={2}>
                      <TextField
                        fullWidth
                        label="Novo link Google"
                        name="novo link google"
                        value={novoLinkGoogle}
                        onChange={event => {
                          setNovoLinkGoogle(event.target.value);
                          setEnableButtonLinkGoogle(true);
                        }}
                        variant="outlined"
                        InputLabelProps={{
                          style: { color: '#c4c4c4' }
                        }}
                      />
                    </Grid>

                    <Grid item xs={6} sm={2}>
                      <Button
                        onClick={defineLinkGoogle}
                        color="primary"
                        variant="contained"
                        disabled={!enableButtonLinkGoogle}
                        style={{
                          height: 55
                        }}
                      >
                        {' '}
                        {contato && contato.linkGoogle !== '' ? (
                          <Typography
                            style={{
                              color: '#fff',
                              display: 'flex'
                            }}
                          >
                            Atualizar link
                          </Typography>
                        ) : (
                          <Typography
                            style={{
                              color: '#fff',
                              display: 'flex'
                            }}
                          >
                            Definir link
                          </Typography>
                        )}
                      </Button>
                    </Grid>

                    <Grid item xs={12} sm={2}>
                      <TextField
                        fullWidth
                        label="Link Apple"
                        name="Link Apple"
                        variant="outlined"
                        value={
                          contato && contato.linkApple !== ''
                            ? contato.linkApple
                            : ''
                        }
                        InputProps={{
                          readOnly: true
                        }}
                        InputLabelProps={{
                          style: { color: '#c4c4c4' }
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={2}>
                      <TextField
                        fullWidth
                        label="Novo link Apple"
                        name="Novo link Apple"
                        value={novoLinkApple}
                        onChange={event => {
                          setNovoLinkApple(event.target.value);
                          setEnableButtonLinkApple(true);
                        }}
                        variant="outlined"
                        InputLabelProps={{
                          style: { color: '#c4c4c4' }
                        }}
                      />
                    </Grid>

                    <Grid item xs={6} sm={2}>
                      <Button
                        onClick={defineLinkApple}
                        color="primary"
                        variant="contained"
                        disabled={!enableButtonLinkApple}
                        style={{
                          height: 55
                        }}
                      >
                        {' '}
                        {contato && contato.linkApple !== '' ? (
                          <Typography
                            style={{
                              color: '#fff',
                              display: 'flex'
                            }}
                          >
                            Atualizar link
                          </Typography>
                        ) : (
                          <Typography
                            style={{
                              color: '#fff',
                              display: 'flex'
                            }}
                          >
                            Definir link
                          </Typography>
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </form>
          </Fragment>
        )}
      </Container>
    </Page>
  );
};

export default Contato;
