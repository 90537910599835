import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
  makeStyles
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { store } from 'src/store';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'transparent',
    pointerEvents: 'none'
  },
  avatar: {
    width: 60,
    height: 60
  },
  toolbar: {
    backgroundColor: '#fff',
    [theme.breakpoints.up('lg')]: {
      width: 256,
      paddingTop: 10,
      borderRightWidth: 1,
      borderRightStyle: 'solid',
      borderColor: 'rgba(0, 0, 0, 0.12)'
    }
  },
  img: {
    width: 50,
    marginTop: 5,
    [theme.breakpoints.down('sm')]: {
      width: 40
    }
  }
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <img
            alt=""
            src="/static/images/logo/logo.png"
            className={classes.img}
          />
        </RouterLink>
        <Typography style={{ marginLeft: 10 }} color="textPrimary" variant="h4">
          {store.getState().auth.nome}
        </Typography>
        <Box flexGrow={1} />
        <Hidden lgUp>
          <IconButton
            style={{ pointerEvents: 'all' }}
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
