import React, { useState } from 'react';
import {
  Container,
  Modal,
  Typography,
  makeStyles,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  Button
} from '@material-ui/core';
import { formatValue } from 'src/utils/currency';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  container: {
    background: '#fff',
    padding: 20,
    width: '50%',
    borderRadius: 5
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginTop: 14,
    marginBottom: 14,
    justifyContent: 'space-between'
  },
  rowItem: {
    backgroundColor: '#fff',
    marginBottom: theme.spacing(1),
    width: '32%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: 10
    }
  },
  actions: {
    marginTop: 10,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

export default function ModalFaturamento({ visible, onConfirm, onClose }) {
  const classes = useStyles();

  const [banco, setBanco] = useState(0);
  const [valorPago, setValorPago] = useState('');
  const [tipoDeCompra, setTipoDeCompra] = useState(0);
  const [formaDePagemento, setFormaDePagamento] = useState(0);

  return (
    <Modal open={visible} onClose={onClose} className={classes.modal}>
      <Container className={classes.container}>
        <Typography color="textPrimary" variant="h4">
          Faturar orçamento
        </Typography>
        <div className={classes.row}>
          <TextField
            fullWidth
            label="Valor pago"
            name="valor-pago"
            value={valorPago}
            onChange={(e) => setValorPago(formatValue(e.target.value))}
            placeholder="0"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"> R$ </InputAdornment>
              )
            }}
            className={classes.rowItem}
            type="number"
          />
          <FormControl variant="outlined" className={classes.rowItem}>
            <InputLabel id="forma-pagamento">Forma de pagamento</InputLabel>
            <Select
              labelId="forma-pagamento"
              value={formaDePagemento}
              label="Forma de pagamento"
              onChange={event => setFormaDePagamento(event.target.value)}
            >
              <MenuItem value={0}>Espécie</MenuItem>
              <MenuItem value={1}>Débito</MenuItem>
              <MenuItem value={2}>Pix/Transferência</MenuItem>
              <MenuItem value={3}>Crédito 1x</MenuItem>
              <MenuItem value={4}>Crédito 2x</MenuItem>
              <MenuItem value={5}>Crédito 3x</MenuItem>
              <MenuItem value={6}>Crédito 4x</MenuItem>
              <MenuItem value={7}>Crédito 5x</MenuItem>
              <MenuItem value={8}>Crédito 6x</MenuItem>
              <MenuItem value={9}>Saldo</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.rowItem}>
            <InputLabel id="banco">Banco</InputLabel>
            <Select
              labelId="banco"
              value={banco}
              label="Banco"
              disabled={formaDePagemento !== 2}
              onChange={event => setBanco(event.target.value)}
            >
              <MenuItem value={0}>Nordeste</MenuItem>
              <MenuItem value={1}>Banco do Brasil</MenuItem>
              <MenuItem value={2}>Caixa</MenuItem>
              <MenuItem value={3}>Itaú</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.rowItem}>
            <InputLabel id="tipo-compra">Tipo de compra</InputLabel>
            <Select
              labelId="tipo-compra"
              value={tipoDeCompra}
              label="Tipo de compra"
              onChange={event => setTipoDeCompra(event.target.value)}
            >
              <MenuItem value={0}>WhatsApp</MenuItem>
              <MenuItem value={1}>Presencial</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className={classes.actions}>
          <Button
            component="span"
            color="primary"
            onClick={() =>
              onConfirm(valorPago, formaDePagemento, banco, tipoDeCompra)
            }
            variant="outlined"
            disabled={valorPago <= 0}
          >
            <Typography style={{ color: '#f18750' }}>Confirmar</Typography>
          </Button>
          <Button
            component="span"
            color="secondary"
            onClick={onClose}
            variant="contained"
            disabled={false}
            style={{ marginLeft: 20 }}
          >
            <Typography style={{ color: '#fff' }}>Cancelar</Typography>
          </Button>
        </div>
      </Container>
    </Modal>
  );
}
