import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, makeStyles } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({

    image: {
        width: '96px',
        height: '96px',
        objectFit: 'cover',
        borderRadius: '5px',
    },
    imageContainer: {
        display: 'flex'
    },
    icon: {
        padding: 0,
        alignSelf: 'start'
    }

}));

function ItemLista(props) {

    const classes = useStyles();
    return (
        <div className={classes.imageContainer}>
            <img className={classes.image} src={props.url} alt={props.nome} />
            <IconButton className={classes.icon} onClick={() => props.onDelete(props.indice)}>
                <ClearIcon />
            </IconButton>
        </div>);
};

ItemLista.propTypes = {
    className: PropTypes.string
};

export default ItemLista;