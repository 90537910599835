const currency = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL'
});

export const formatValue = value => {
  if (value < 0) return 0;
  let newValue = value.replace('.', '').replace(',', '');
  const numbers = /^[0-9]*$/;
  if (newValue.match(numbers)) {
    newValue = newValue.replace(/(.)(?=(\d{2})$)/g, '$1.');
  }
  return newValue;
};

export const formatCurrency = value => currency.format(value);
