import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  Button,
  Typography
} from '@material-ui/core';
import { Search as SearchIcon, Plus as PlusIcon } from 'react-feather';

const useStyles = makeStyles(theme => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  SearchInput: {
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 10
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: '50%'
    },
    width: '100%'
  }
}));

const Toolbar = ({
  className,
  categorias,
  setCategoriasSearch,
  input,
  setInput,
  isCategoryForSite,
  setIsCategoryForSite,
  openModal,
  permissions,
  ...rest
}) => {
  const classes = useStyles();

  function handleReload() {
    setIsCategoryForSite(!isCategoryForSite);
  }

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box mt={3} className={classes.container}>
        <Box maxWidth={500} className={classes.SearchInput}>
          <TextField
            fullWidth
            value={input}
            onChange={e => {
              setInput(e.target.value);
              if (e.target.value.length > 0) {
                setCategoriasSearch(
                  categorias.filter(c => {
                    return c.nome
                      .toLowerCase()
                      .match(e.target.value.toLowerCase());
                  })
                );
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              )
            }}
            placeholder="Buscar categoria"
            variant="outlined"
          />
        </Box>
        <Box>
          {permissions.app.adicionar && (
            <Button
              onClick={openModal}
              color="primary"
              variant="contained"
              style={{
                marginRight: 15,
                height: 50
              }}
            >
              <Typography
                style={{
                  color: '#fff',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <PlusIcon style={{ paddingRight: 3 }} />
                Adicionar nova categoria
              </Typography>
            </Button>
          )}
          {permissions.site.exibirBotao && (
            <Button
              color="secondary"
              variant="contained"
              onClick={handleReload}
              style={{
                height: 50
              }}
            >
              {isCategoryForSite ? 'MUDAR PARA APP' : ' MUDAR PARA SITE'}
            </Button>
          )}
        </Box>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
