import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import { withPermissions } from './hocs/permissions';
import DashboardLayout from 'src/layouts/DashboardLayout';
import PedidosView from 'src/views/Pedidos/PedidosView';
import ProdutosListView from 'src/views/Produtos/ProdutosListView';
import PedidosListView from 'src/views/PedidosListView';
import NotFoundView from 'src/views/NotFound/NotFoundView';
import CategoriaListView from 'src/views/Categorias/CategoriaListView';
import MarcaListView from 'src/views/Marcas/MarcaListView';
import LoginView from 'src/views/Login/LoginView';
import CategoriaView from 'src/views/Categoria';
import MarcaView from 'src/views/Marca';
import PromotionView from 'src/views/Promocoes';
import FreteView from 'src/views/CartConfig';
import ContatoView from 'src/views/Contato';
import CuponsView from 'src/views/Cupons';
import VendedoresView from 'src/views/Vendedores';
import GerenciadoresView from 'src/views/Gerenciadores';
import UsuariosView from 'src/views/Usuarios';
import FAQView from 'src/views/FAQ';
import FAQChangeView from 'src/views/FAQ/faq';
import InformacoesAdicionaisView from 'src/views/InformacoesAdicionais';
import OrcamentosView from 'src/views/Orcamentos';
import NovoOrcamentoView from './views/NovoOrcamento';
import PermissionsView from './views/Permissions';
import EntregaView from './views/Entrega';
import Dados from './views/Dados';
import Logs from './views/Logs';
import ApiView from './views/Api';

const mapFirstPageToRoute = firstPage => {
  switch (firstPage) {
    case 'categoriasApp':
      return 'categorias';
    case 'informacoesAdicionais':
      return 'informacoes';
    default:
      return firstPage;
  }
};

function RoutesComponent({ firstPage, auth, permissions, refreshPermissions }) {
  const firstRoute = mapFirstPageToRoute(firstPage);

  return (
    <Routes>
      {auth.accessToken !== '' && permissions ? (
        <>
          <Route
            path="/"
            element={<DashboardLayout permissions={permissions} />}
          >
            {permissions?.categoriasApp?.exibirAba && (
              <>
                <Route
                  path="categorias"
                  element={
                    <CategoriaListView
                      permissions={{
                        app: permissions?.categoriasApp,
                        site: permissions?.categoriasSite
                      }}
                    />
                  }
                />
                <Route path="categoria" element={<CategoriaView />} />
              </>
            )}
            {permissions?.marcas?.exibirAba && (
              <>
                <Route
                  path="marcas"
                  element={<MarcaListView permissions={permissions?.marcas} />}
                />
                <Route path="marca" element={<MarcaView />} />
              </>
            )}
            {permissions?.promocoes?.exibirAba && (
              <Route
                path="promocoes"
                element={<PromotionView permissions={permissions?.promocoes} />}
              />
            )}
            {permissions?.carrinho?.exibirAba && (
              <Route
                path="carrinho"
                element={<FreteView permissions={permissions?.carrinho} />}
              />
            )}
            {permissions.orcamentos.exibirAba &&
              permissions.pedidos.exibirAba && (
                <Route path="dados" element={<Dados />} />
              )}

            {permissions?.carrinho?.exibirAba && (
              <Route
                path="logs"
                element={<Logs permissions={permissions?.carrinho} />}
              />
            )}

            {permissions?.contato?.exibirAba && (
              <Route
                path="contato"
                element={<ContatoView permissions={permissions?.contato} />}
              />
            )}
            {permissions?.cupons?.exibirAba && (
              <Route
                path="cupons"
                element={<CuponsView permissions={permissions?.cupons} />}
              />
            )}
            {permissions?.vendedores?.exibirAba && (
              <Route
                path="vendedores"
                element={
                  <VendedoresView permissions={permissions?.vendedores} />
                }
              />
            )}
            {auth.role === 0 && (
              <Route path="gerenciadores" element={<GerenciadoresView />} />
            )}
            {permissions?.usuarios?.exibirAba && (
              <Route
                path="usuarios"
                element={<UsuariosView permissions={permissions?.usuarios} />}
              />
            )}
            {permissions?.informacoesAdicionais?.exibirAba && (
              <Route
                path="informacoes"
                element={
                  <InformacoesAdicionaisView
                    permissions={permissions?.informacoesAdicionais}
                  />
                }
              />
            )}
            {auth.role === 0 && <Route path="api" element={<ApiView />} />}
            {permissions?.faq?.exibirAba && (
              <Route
                path="faq"
                element={<FAQView permissions={permissions?.faq} />}
              />
            )}
            {permissions?.faq?.exibirAba && permissions?.faq.editar && (
              <Route path="faqchange" element={<FAQChangeView />} />
            )}
            {auth.role === 0 && (
              <Route path="permissions" element={<PermissionsView />} />
            )}
            {permissions?.produtos?.exibirAba && (
              <Route
                path="produtos"
                element={
                  <ProdutosListView permissions={permissions?.produtos} />
                }
              />
            )}
            {permissions?.pedidos?.exibirAba && (
              <>
                <Route
                  path="pedidos"
                  element={
                    <PedidosListView permissions={permissions?.pedidos} />
                  }
                />
                <Route path="pedido" element={<PedidosView />} />{' '}
              </>
            )}

            {permissions?.orcamentos?.exibirAba && (
              <>
                <Route
                  path="orcamentos"
                  element={
                    <OrcamentosView permissions={permissions?.orcamentos} />
                  }
                />
                <Route
                  path="orcamento/:uuid"
                  element={
                    <NovoOrcamentoView permissions={permissions?.orcamentos} />
                  }
                />
              </>
            )}

            {permissions?.entrega?.exibirAba && (
              <Route
                path="entrega"
                element={<EntregaView permissions={permissions?.entrega} />}
              />
            )}

            <Route path="/" element={<Navigate to={`/${firstRoute}`} />} />
          </Route>

          <Route path="/">
            <Route path="404" element={<NotFoundView />} />
            <Route path="*" element={<Navigate to="/404" />} />
          </Route>
        </>
      ) : (
        <></>
      )}
      <Route path="/">
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Route>
      <Route
        path="/login"
        element={<LoginView refreshPermissions={refreshPermissions} />}
      />
    </Routes>
  );
}

export default withPermissions()(RoutesComponent);
