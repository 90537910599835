import React, { useState, useEffect } from 'react';import {
    Box,
    Grid,
    Button,
    Card,
    CardContent,
    makeStyles,
    Typography,
    TextField
} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { store } from '../../store';
import { Auth } from '../../store/reducers/auth/actions';
import { connect } from 'react-redux';
import { req } from '../../services/api';


const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
        backgroundColor: "white",
        width: '85%',
        [theme.breakpoints.down('xs')]: {
            width: '95%',
            height: '95%',
        },
        overflowY: 'scroll'
    },
    title: {
        paddingBottom: theme.spacing(1)
    },
    cover: {
        width: 360,
        height: 200,
    },
    input: {
        display: 'none'
    },
    inputLabel: {
        paddingBottom: theme.spacing(1),
    },
    imagesContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
        gridGap: '16px',
        flexDirection: 'row',
    }
}));


const AddPromocao = ({ loadVendedores,vendedor,handleChange, dispatch, handleClose, toast }) => {
    //const [createProduct] = useMutation(CreateMutation);
    const classes = useStyles();
    
    async function cadastrarVendedor(event) {
        event.preventDefault();
        if (!vendedor.nome || !vendedor.cpf) {
            toast.warning("Preencha todos os campos!");
        } else {
                try {
                     const req_ = await req(
                      `mutation{
                            createSeller
                            (
                                nome:"${vendedor.nome}"
                                cpf:"${vendedor.cpf}"
                            )
                          }
                      `
                      )
                    if(!req_.data.errors)
                        toast.success("Vendedor cadastrado com sucesso!");
                } catch (error) {
                    toast.error("Erro ao cadastrar Vendedor!");
                    return;
                }
            }
            handleClose();
            loadVendedores();
           
    }

    async function atualiarVendedor(event) {
        event.preventDefault();
        if (!vendedor.nome || !vendedor.cpf) {
            toast.warning("Preencha todos os campos!");
        } else {
                try {
                     const req_ = await req(
                      `mutation{
                            updateSeller
                            (
                                uuid:"${vendedor.uuid}"
                                nome:"${vendedor.nome}"
                                cpf:"${vendedor.cpf}"
                            )
                          }
                      `
                      )
                    if(!req_.data.errors)
                        toast.success("Vendedor atualizado com sucesso!");
                    else
                        toast.error("Erro ao cadastrar cupom: "+req_.errors.message);
                } catch (error) {
                    toast.error("Erro ao atualizar Vendedor!");
                     handleClose();
                    return;
                }
            }
            handleClose();
            loadVendedores();
           
    }
    return (
        <Card style={{padding:"1rem"}}>
            <form
            autoComplete="off"
            onSubmit={(event) => vendedor.isEdit?atualiarVendedor(event): cadastrarVendedor(event)}
            >
            <Typography
                className={classes.title}
                variant="h2"
                color="textPrimary"
            >
                Cadastrar novo Vendedor
            </Typography>
            <Card>
                <CardContent>
                    <Grid
                        container
                        spacing={2}
                    >
                    
                        <Grid
                            item
                            xs={12}
                            sm={6}
                        >
                            <TextField
                                fullWidth
                                label="Nome do Vendedor"
                                autoComplete="new-password"
                                name="nome"
                                onChange={handleChange}
                                required
                                value={vendedor.nome}
                                variant="outlined"
                                InputLabelProps={{
                                    style: { color: '#c4c4c4' },
                                }}
                            />
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={6}
                        >
                            <TextField
                                fullWidth
                                label="Cpf"
                                name="cpf"
                                onChange={handleChange}
                                required
                                value={vendedor.cpf}
                                variant="outlined"
                                InputLabelProps={{
                                    style: { color: '#c4c4c4' },
                                }}
                            />
                        </Grid>
                        
                
                        
                        <Grid
                            item
                            xs={12}
                        >
                            <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    style={{ color: "#fff", margin: 10 }}
                                    onClick={handleClose}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    style={{ color: '#fff', margin: 10 }}

                                >
                                    Salvar Vendedor
                                </Button>
                            </div>

                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            </form >
        </Card>
    )
}

export default connect()(AddPromocao);