import React, { useEffect, useState } from 'react';
import { BookOpen as BookOpenIcon } from 'react-feather';
import {
  Box,
  Card,
  CircularProgress,
  Container,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Button
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Trash as TrashIcon } from 'react-feather';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from 'react-redux';

import Page from 'src/components/Page';
import { req } from 'src/services/api';
import { formatCurrency } from 'src/utils/currency';

import Toolbar from './Toolbar';
import { alterarTabOrcamento } from 'src/store/reducers/tabs/actions';
import { usePagination } from 'src/context';
import MergeModal from './MergeModal';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const mapTipoEntregaParaTexto = tipoEntrega => {
  switch (tipoEntrega) {
    case 0:
      return 'Retirada na loja';
    case 1:
    default:
      return 'Entregue no endereço';
  }
};

const Orcamento = ({ permissions }) => {
  const classes = useStyles();

  const {
    orcamentosAbertosPage,
    setOrcamentosAbertosPage,
    orcamentosFechadosPage,
    setOrcamentosFechadosPage
  } = usePagination();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { tabOrcamento } = useSelector(state => state.tabs);

  const [orcamentos, setOrcamentos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState('');
  const [produtosSelecionados, setProdutosSelecionados] = useState([]);
  const [limit, setLimit] = useState(15);
  const [total, setTotal] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleLimitChange = event => {
    setLimit(event.target.value);
  };

  const handlePageChange = (_, newPage) => {
    if (!tabOrcamento) {
      setOrcamentosAbertosPage(newPage);
    } else {
      setOrcamentosFechadosPage(newPage);
    }
  };

  function handleSearch() {
    if (!tabOrcamento) {
      setOrcamentosAbertosPage(0);
    } else {
      setOrcamentosFechadosPage(0);
    }
    loadOrcamentos();
  }

  function navigateToEditarOrcamento(orcamento) {
    const win = window.open(`/orcamento/${orcamento.uuid}`, '_blank');
    win.focus();
    // navigate(`/orcamento/${orcamento.uuid}`, { state: { permissions } });
  }

  const currency = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  });

  async function loadOrcamentos() {
    try {
      const users = await req(
        `
                {
                    readBudgets(
                        skip: ${(!tabOrcamento
                          ? orcamentosAbertosPage
                          : orcamentosFechadosPage) * limit}
                        take: ${limit}
                        filter: "${input}"
                        status: ${Boolean(tabOrcamento)}
                    ){
                        data {
                            uuid
                            nome_vendedor
                            nome_cliente
                            desconto
                            status
                            createdAt
                            updatedAt
                            dataDeFaturamento
                            codigo
                            valor_total
                            frete
                            tipo_entrega
                        }
                        total
                    }
                }
                `
      );

      if (users.data) {
        setOrcamentos(users.data.readBudgets.data);
        setTotal(users.data.readBudgets.total);
      } else if (users.errors) {
        toast.error(users.errors[0].message);
      }
    } catch (error) {
      console.log(error);
      toast.error('Erro ao resgatar orçamentos');
    } finally {
      setLoading(false);
    }
  }

  function confirmRemove(e, orcamento) {
    e.stopPropagation();
    confirmAlert({
      title: `Excluir orçamento ${orcamento.codigo}?`,
      buttons: [
        {
          label: 'Sim',
          onClick: () => handleRemove(orcamento)
        },
        {
          label: 'Não',
          onClick: () => {}
        }
      ]
    });
  }

  async function handleRemove(orcamento) {
    const delay = ms => new Promise(res => setTimeout(res, ms));
    try {
      const removeOrcamento = await req(
        `
                mutation{
                    removeBudget(
                        uuid: "${orcamento.uuid}"
                    )
                }
                `
      );

      if (removeOrcamento.data) {
        toast.success('Orçamento excluído com sucesso');
        await delay(500);
        loadOrcamentos();
      } else if (removeOrcamento.errors) {
        toast.error(removeOrcamento.errors[0].message);
      }
    } catch (error) {
      console.log(error);
      toast.error('Erro ao excluir orçamento');
    }
  }

  useEffect(() => {
    loadOrcamentos();
  }, [orcamentosAbertosPage, orcamentosFechadosPage, limit, tabOrcamento]);

  return (
    <Page className={classes.root} title="Orçamentos">
      <Container maxWidth={false}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 24
          }}
        >
          <BookOpenIcon color="#5e5e5f" style={{ margin: 10 }} />
          <Typography color="textPrimary" variant="h2">
            Orçamentos
          </Typography>
        </div>
        <Toolbar
          input={input}
          setInput={setInput}
          onClick={handleSearch}
          permissions={permissions}
          produtosSelecionados={produtosSelecionados}
          handleOpenModal={() => setIsModalOpen(true)}
          setProdutosSelecionados={setProdutosSelecionados}
        />
        <Card>
          <PerfectScrollbar>
            <Box minWidth={1050}>
              <Container
                maxWidth={false}
                style={{
                  backgroundColor: 'transparent',
                  paddingLeft: 0,
                  paddingRight: 0,
                  marginBottom: 4,
                  width: '100%'
                }}
              >
                <Button
                  maxWidth={false}
                  variant={!tabOrcamento && 'outlined'}
                  onClick={() => {
                    dispatch(alterarTabOrcamento(false));
                  }}
                  style={{
                    color: !tabOrcamento && '#F18750',
                    width: '50%',
                    borderColor: '#F18750',
                    borderWidth: 0,
                    borderBottomWidth: 3,
                    borderRadius: 0,
                    borderTopLeftRadius: 8
                  }}
                >
                  Abertos
                </Button>
                <Button
                  variant={Boolean(tabOrcamento) && 'outlined'}
                  onClick={() => {
                    dispatch(alterarTabOrcamento(true));
                  }}
                  style={{
                    width: '50%',
                    color: Boolean(tabOrcamento) && '#F18750',
                    borderColor: '#F18750',
                    borderWidth: 0,
                    borderBottomWidth: 3,
                    borderRadius: 0,
                    borderTopRightRadius: 8
                  }}
                >
                  Faturados
                </Button>
              </Container>
              <Table>
                <TableHead>
                  <TableRow>
                    {permissions.excluir && (
                      <TableCell align="center" width="5%"></TableCell>
                    )}
                    <TableCell align="center" width="5%">
                      Código
                    </TableCell>
                    <TableCell align="center" width="25%">
                      Cliente
                    </TableCell>
                    <TableCell align="center" width="25%">
                      Vendedor
                    </TableCell>
                    <TableCell align="center" width="5%">
                      Desconto
                    </TableCell>
                    <TableCell align="center" width="5%">
                      Tipo de entrega
                    </TableCell>
                    <TableCell align="center" width="5%">
                      Entrega
                    </TableCell>
                    <TableCell align="center" width="10%">
                      Total
                    </TableCell>
                    <TableCell align="center" width="12%">
                      Data de criação
                    </TableCell>
                    {tabOrcamento ? (
                      <TableCell align="center" width="12%">
                        Data de faturamento
                      </TableCell>
                    ) : null}
                    <TableCell align="center" width="12%">
                      Última modificação
                    </TableCell>
                    <TableCell align="center" width="5%">
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow hover key="key">
                      <TableCell
                        colSpan={permissions.excluir ? 7 : 6}
                        align="center"
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center'
                          }}
                        >
                          <CircularProgress color="primary" size={20} />
                          <Typography style={{ marginLeft: 10 }}>
                            Carregando...
                          </Typography>
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : orcamentos.length > 0 ? (
                    orcamentos.map(orcamento => (
                      <TableRow
                        hover
                        style={{
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          navigateToEditarOrcamento(orcamento);
                        }}
                        key={orcamento.uuid}
                      >
                        {permissions.excluir && (
                          <TableCell align="center" width="5%">
                            {!orcamento.status && (
                              <TrashIcon
                                onClick={e => confirmRemove(e, orcamento)}
                                color="#5e5e5f"
                                size={18}
                              />
                            )}
                          </TableCell>
                        )}
                        <TableCell align="center" width="5%">
                          {orcamento.codigo.toUpperCase()}
                        </TableCell>
                        <TableCell align="center" width="25%">
                          {orcamento.nome_cliente.length > 0
                            ? orcamento.nome_cliente
                            : 'Sem registro'}
                        </TableCell>
                        <TableCell align="center" width="25%">
                          {orcamento.nome_vendedor &&
                          orcamento.nome_vendedor !== 'null'
                            ? orcamento.nome_vendedor
                            : 'Sem registro'}
                        </TableCell>
                        <TableCell align="center" width="5%">
                          {Number(orcamento.desconto)}%
                        </TableCell>
                        <TableCell align="center" width="10%">
                          {mapTipoEntregaParaTexto(orcamento.tipo_entrega)}
                        </TableCell>
                        <TableCell align="center" width="5%">
                          {orcamento.tipo_entrega === 1
                            ? formatCurrency(orcamento.frete)
                            : '-'}
                        </TableCell>
                        <TableCell align="center" width="10%">
                          {formatCurrency(
                            orcamento.valor_total + orcamento.frete
                          )}
                        </TableCell>
                        <TableCell align="center" width="12%">
                          {new Date(Number(orcamento.createdAt)).toLocaleString(
                            'pt-BR'
                          )}
                        </TableCell>
                        {tabOrcamento ? (
                          <TableCell align="center" width="12%">
                            {orcamento.dataDeFaturamento
                              ? new Date(
                                  Number(orcamento.dataDeFaturamento)
                                ).toLocaleString('pt-BR')
                              : '-'}
                          </TableCell>
                        ) : null}
                        <TableCell align="center" width="12%">
                          {new Date(Number(orcamento.updatedAt)).toLocaleString(
                            'pt-BR'
                          )}
                        </TableCell>
                        <TableCell align="center" width="5%">
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <span
                              style={{
                                height: 10,
                                width: 10,
                                marginRight: 8,
                                backgroundColor: orcamento.status
                                  ? '#E83B3C'
                                  : '#2aa25d',
                                borderRadius: '50%',
                                display: 'inline-block'
                              }}
                            ></span>
                            <p>{orcamento.status ? 'Faturado' : 'Aberto'}</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow hover key="key">
                      <TableCell colSpan={10} align="center">
                        Nenhum orçamento encontrado
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            count={total}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={
              !tabOrcamento ? orcamentosAbertosPage : orcamentosFechadosPage
            }
            rowsPerPage={limit}
            rowsPerPageOptions={[15, 25, 50, 100]}
          />
        </Card>
      </Container>
      <MergeModal
        visible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        permissions={permissions}
      />
    </Page>
  );
};

export default Orcamento;
