import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {
  Modal,
  makeStyles,
  Typography,
  Button,
  TextField,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@material-ui/core';
import { Clear as ClearIcon } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'auto'
  },
  container: {
    backgroundColor: '#FFF',
    borderRadius: 6,
    padding: 10,
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      width: '90%'
    }
  },
  title: { marginBottom: 10, color: '#5e5e5f' },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20
  },
  body: {},
  quantFilter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 20
  },
  select: {
    width: '55%',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  input: {
    width: '30%',
    marginLeft: 10,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      width: '100%',
      marginLeft: 0,
      marginTop: 5
    }
  },
  clear: {
    width: '10%',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: 10
    }
  },
  categoryFilter: {}
}));

const operators = [
  'Igual',
  'Maior que',
  'Menor que',
  'Maior que ou igual',
  'Menor que ou igual'
];

const Filters = ({ open = false, onClose, onApply, categorias }, ref) => {
  const classes = useStyles();

  const [value, setValue] = useState(undefined);
  const [operator, setOperator] = useState(-1);
  const [categoria, setCategoria] = useState(categorias[0].uuid);

  useImperativeHandle(ref, () => ({
    getQuantOperator: () => operator,
    getQuantValue: () => value,
    hasQuantFilter: () => operator != -1 && value,
    getCategoria: () => categoria
  }));

  const clear = () => {
    setValue(undefined);
    setOperator(-1);
  };
  return (
    <Modal open={open} onClose={onClose} className={classes.modal}>
      <Box className={classes.container}>
        <div className={classes.body}>
          <Typography variant="h4" className={classes.title}>
            Quantidade
          </Typography>
          <div className={classes.quantFilter}>
            <FormControl variant="outlined" className={classes.select}>
              <InputLabel id="select-label">Operador</InputLabel>
              <Select
                labelId="select-label"
                value={operator}
                onChange={e => setOperator(e.target.value)}
                label="Operador"
                required
              >
                <MenuItem value={-1} disabled selected>
                  Selecionar
                </MenuItem>
                {operators.map((operator, index) => (
                  <MenuItem value={index}>{operator}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.input}>
              <TextField
                autoComplete="off"
                label="Valor"
                name="value"
                onChange={e => setValue(e.target.value)}
                required
                value={value}
                variant="outlined"
                InputLabelProps={{
                  style: { color: 'textPrimary' }
                }}
              />
            </FormControl>
            <div className={classes.clear}>
              {operator !== null && value !== null && (
                <ClearIcon
                  onClick={clear}
                  style={{
                    cursor: 'pointer',
                    color: '#333',
                    fontSize: 20,
                    marginLeft: 5
                  }}
                />
              )}
            </div>
          </div>
          <Typography variant="h4" className={classes.title}>
            Categoria
          </Typography>
          <div className={classes.categoryFilter}>
            <FormControl variant="outlined" className={classes.select}>
              <InputLabel id="select-label">Categoria</InputLabel>
              <Select
                labelId="select-label"
                value={categoria}
                onChange={e => setCategoria(e.target.value)}
                label="Operador"
                required
              >
                {categorias.map(categoria => (
                  <MenuItem value={categoria.uuid}>{categoria.nome}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className={classes.actions}>
          <Button onClick={onClose} color="secondary" variant="contained">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              onApply();
              onClose();
            }}
            style={{ marginLeft: 5 }}
            color="primary"
            variant="outlined"
            disabled={operator != -1 && (!value || value == '')}
          >
            Aplicar
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default forwardRef(Filters);
