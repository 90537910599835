import { colors } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import shadows from './shadows';
import typography from './typography';

const theme = createTheme({
  palette: {
    background: {
      dark: '#f7f7f7',
      default: colors.common.white,
      paper: colors.common.white
    },
    primary: {
      main: '#F18750' //laranja principal
    },
    secondary: {
      main: '#E83B3C' //vermelho deletar
    },
    text: {
      primary: '#5e5e5f', //texto principal escuro
      secondary: '#5e5e5f' //texto secundário claro
    }
  },
  shadows,
  typography,
  overrides: {
    MUIDataTable: {
      // ok
    },
    MuiTableCell: {
      root: {
        whiteSpace: 'nowrap'
      }
    }
  }
});

export default theme;
