import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Search as SearchIcon, Plus as PlusIcon } from 'react-feather';
import ExportProducts from './ExportProducts';
import ImportProducts from './ImportProducts';

const useStyles = makeStyles(theme => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  SearchInput: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '50%'
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 10
    },
    width: '100%'
  },
  Button: {
    marginLeft: 10,
    color: '#fff'
  }
}));

const Toolbar = ({
  className,
  setContadorProdutosSearch,
  setPage,
  produtos,
  excluirProdutos,
  setProdutosSearch,
  input,
  setInput,
  excluirList,
  handleOpen,
  onClick,
  categoria,
  hasQuantFilter,
  quantOperator,
  quantValue,
  filter,
  permissions,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box className={classes.container}>
        <Box maxWidth={500} className={classes.SearchInput}>
          <TextField
            fullWidth
            value={input}
            onChange={e => setInput(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              )
            }}
            placeholder="Buscar produto"
            variant="outlined"
            onKeyPress={e => {
              if (e.key === 'Enter') {
                onClick();
              }
            }}
          />
          <Button
            className={classes.Button}
            onClick={onClick}
            color="primary"
            variant="contained"
          >
            Buscar
          </Button>
        </Box>
        <Box>
          
          <ImportProducts />
          
          <ExportProducts
            categoria={categoria}
            hasQuantFilter={hasQuantFilter}
            quantOperator={quantOperator}
            quantValue={quantValue}
            filter={filter}
          />
          {permissions?.adicionar && (
            <Button
              onClick={handleOpen}
              color="primary"
              variant="contained"
              style={{
                marginRight: 15,
                height: 50
              }}
            >
              <Typography
                style={{
                  color: '#fff',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <PlusIcon style={{ paddingRight: 3 }} />
                Adicionar produto
              </Typography>
            </Button>
          )}

          {permissions?.excluir && (
            <Button
              color="secondary"
              variant="contained"
              onClick={async () => await excluirProdutos(excluirList)}
              disabled={excluirList.length === 0}
              style={{
                height: 50
              }}
            >
              Excluir
            </Button>
          )}
        </Box>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
