import React, { Fragment, useMemo, useState } from 'react';
import {
  Button,
  Container,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';

import Preview from './Preview';
import { formatCurrency, formatValue } from 'src/utils/currency';
import { req } from 'src/services/api';
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
  saveButton: {
    marginTop: 10,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  info: {
    fontWeight: 'normal'
  },
  infoContainer: {
    marginRight: theme.spacing(5),
    marginBottom: theme.spacing(1)
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginTop: 14,
    marginBottom: 14
  },
  rowItem: {
    backgroundColor: '#fff',
    marginRight: theme.spacing(5),
    marginBottom: theme.spacing(1),
    width: '30%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: 10
    }
  },
  divider: {
    height: 1,
    width: '100%',
    background: '#eee',
    marginBottom: 10,
    marginTop: 10
  }
}));

const mapFormaDePagamento = formaDePagemento => {
  switch (formaDePagemento) {
    case 0:
    default:
      return 'Espécie';
    case 1:
      return 'Débito';
    case 2:
      return 'Pix/Transferência';
    case 3:
      return 'Crédito 1x';
    case 4:
      return 'Crédito 2x';
    case 5:
      return 'Crédito 3x';
    case 6:
      return 'Crédito 4x';
    case 7:
      return 'Crédito 5x';
    case 8:
      return 'Crédito 6x';
    case 9:
      return 'Saldo';
  }
};

const mapBanco = banco => {
  switch (banco) {
    case 0:
    default:
      return 'Nordeste';
    case 1:
      return 'Banco do Brasil';
    case 2:
      return 'Caixa';
    case 3:
      return 'Itaú';
  }
};

const mapTipoDeCompra = tipoDeCompra => {
  switch (tipoDeCompra) {
    case 0:
    default:
      return 'WhatsApp';
    case 1:
      return 'Presencial';
  }
};

export default function OrcamentoFaturado({
  zona,
  bairro,
  orcamento,
  descontoPix,
  refreshOrcamento,
  limiteDescontoExtra
}) {
  const classes = useStyles();

  // Controle
  const [statusDeEntrega, setStatusDeEntrega] = useState(
    orcamento.statusDeEntrega || 0
  );
  const [emissaoNota, setEmissaoNota] = useState(
    orcamento.emissaoNota || false
  );

  // Crédito
  const [credito, setCredito] = useState(orcamento.credito || false);
  const [valorDeCredito, setValorDeCredito] = useState(
    orcamento.valorDeCredito || ''
  );
  const [observacoes, setObservacoes] = useState(orcamento.observacoes || '');

  const desabilitarSalvar = useMemo(
    () =>
      observacoes === orcamento.observacoes &&
      statusDeEntrega === orcamento.statusDeEntrega &&
      emissaoNota === orcamento.emissaoNota &&
      credito === orcamento.credito &&
      valorDeCredito === orcamento.valorDeCredito,
    [observacoes, statusDeEntrega, emissaoNota, credito, valorDeCredito]
  );

  async function salvar() {
    try {
      const data = await req(`
        mutation{
          atualizarOrcamentoFaturado(
            uuid: "${orcamento.uuid}"
            fields: {
              statusDeEntrega: ${statusDeEntrega}
              emissaoNota: ${emissaoNota}
              credito: ${credito}
              valorDeCredito: ${valorDeCredito || 0}
              observacoes: "${observacoes}"
            }
          )
        }
       `);
      if (data.data) {
        toast.success('Dados atualizados com sucesso!');
        if (
          observacoes !== orcamento.observacoes ||
          statusDeEntrega !== orcamento.statusDeEntrega
        ) {
          refreshOrcamento();
        }
      }
      if (data.errors) {
        toast.error(data.errors[0].message);
      }
    } catch (error) {
      toast.error('Não foi possível salvar os dados');
    }
  }

  return (
    <Fragment>
      <Container maxWidth={true}>
        <Typography color="textPrimary" variant="h4">
          Dados do orçamento
        </Typography>
        <div className={classes.row}>
          <div className={classes.infoContainer}>
            <Typography color="textPrimary" variant="h5">
              Cliente
            </Typography>
            <Typography
              color="textPrimary"
              variant="h6"
              className={classes.info}
            >
              {orcamento.nome_cliente || '-'}
            </Typography>
          </div>
          <div className={classes.infoContainer}>
            <Typography color="textPrimary" variant="h5">
              Vendedor
            </Typography>
            <Typography
              color="textPrimary"
              variant="h6"
              className={classes.info}
            >
              {orcamento.nome_vendedor && orcamento.nome_vendedor !== 'null'
                ? orcamento.nome_vendedor
                : '-'}
            </Typography>
          </div>
          <div className={classes.infoContainer}>
            <Typography color="textPrimary" variant="h5">
              Tipo de entrega
            </Typography>
            <Typography
              color="textPrimary"
              variant="h6"
              className={classes.info}
            >
              {orcamento.tipo_entrega === 0
                ? 'Retirada na loja'
                : 'Entregue no endereço'}
            </Typography>
          </div>
          <div className={classes.infoContainer}>
            <Typography color="textPrimary" variant="h5">
              Zona
            </Typography>
            <Typography
              color="textPrimary"
              variant="h6"
              className={classes.info}
            >
              {orcamento.tipo_entrega === 0 ? '-' : zona}
            </Typography>
          </div>
          <div className={classes.infoContainer}>
            <Typography color="textPrimary" variant="h5">
              Bairro
            </Typography>
            <Typography
              color="textPrimary"
              variant="h6"
              className={classes.info}
            >
              {orcamento.tipo_entrega === 0 ? '-' : bairro}
            </Typography>
          </div>
          <div className={classes.infoContainer}>
            <Typography color="textPrimary" variant="h5">
              Desconto total
            </Typography>
            <Typography
              color="textPrimary"
              variant="h6"
              className={classes.info}
            >
              {orcamento.desconto}%
            </Typography>
          </div>
          <div className={classes.infoContainer}>
            <Typography color="textPrimary" variant="h5">
              Desconto extra
            </Typography>
            <Typography
              color="textPrimary"
              variant="h6"
              className={classes.info}
            >
              {orcamento.aplicar_desconto_extra ? 'Sim' : 'Não'}
            </Typography>
          </div>
        </div>
        <Typography color="textPrimary" variant="h4">
          Pagamento
        </Typography>
        <div className={classes.row}>
          <div className={classes.infoContainer}>
            <Typography color="textPrimary" variant="h5">
              Valor pago
            </Typography>
            <Typography
              color="textPrimary"
              variant="h6"
              className={classes.info}
            >
              {formatCurrency(orcamento.valorPago)}
            </Typography>
          </div>
          <div className={classes.infoContainer}>
            <Typography color="textPrimary" variant="h5">
              Forma de pagamento
            </Typography>
            <Typography
              color="textPrimary"
              variant="h6"
              className={classes.info}
            >
              {mapFormaDePagamento(orcamento.formaDePagamento)}
            </Typography>
          </div>
          <div className={classes.infoContainer}>
            <Typography color="textPrimary" variant="h5">
              Banco
            </Typography>
            <Typography
              color="textPrimary"
              variant="h6"
              className={classes.info}
            >
              {orcamento.formaDePagamento === 2
                ? mapBanco(orcamento.banco)
                : '-'}
            </Typography>
          </div>
          <div className={classes.infoContainer}>
            <Typography color="textPrimary" variant="h5">
              Tipo de compra
            </Typography>
            <Typography
              color="textPrimary"
              variant="h6"
              className={classes.info}
            >
              {mapTipoDeCompra(orcamento.tipoDeCompra)}
            </Typography>
          </div>
        </div>
        <div className={classes.divider} />
        <Typography color="textPrimary" variant="h4">
          Controle
        </Typography>
        <div className={classes.row}>
          <FormControl variant="outlined" className={classes.rowItem}>
            <InputLabel id="status-entrega">Status de entrega</InputLabel>
            <Select
              labelId="status-entrega"
              value={statusDeEntrega}
              label="Status de entrega"
              onChange={event => setStatusDeEntrega(event.target.value)}
            >
              <MenuItem value={0}>Em separação</MenuItem>
              <MenuItem value={1}>Em rota</MenuItem>
              <MenuItem value={2}>Retirada</MenuItem>
              <MenuItem value={3}>Entregue</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.rowItem}>
            <InputLabel id="emissao-nota">Emissão de nota</InputLabel>
            <Select
              labelId="emissao-nota"
              value={emissaoNota}
              label="Emissão de nota"
              onChange={event => setEmissaoNota(event.target.value)}
            >
              <MenuItem value={true}>Sim</MenuItem>
              <MenuItem value={false}>Não</MenuItem>
            </Select>
          </FormControl>
        </div>
        <Typography color="textPrimary" variant="h4">
          Crédito
        </Typography>
        <div className={classes.row}>
          <FormControl variant="outlined" className={classes.rowItem}>
            <InputLabel id="credito">Crédito</InputLabel>
            <Select
              labelId="credito"
              value={credito}
              label="Crédito"
              onChange={event => setCredito(event.target.value)}
            >
              <MenuItem value={true}>Sim</MenuItem>
              <MenuItem value={false}>Não</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Valor de crédito"
            name="credito"
            type="number"
            value={valorDeCredito}
            placeholder="0"
            onChange={e => setValorDeCredito(formatValue(e.target.value))}
            disabled={!credito}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"> R$ </InputAdornment>
              )
            }}
            className={classes.rowItem}
          />
          <TextField
            className={classes.rowItem}
            label="Observações"
            variant="outlined"
            value={observacoes}
            onChange={e => setObservacoes(e.target.value)}
          />
        </div>

        <div className={classes.saveButton}>
          <Button
            component="span"
            color="primary"
            onClick={salvar}
            variant="contained"
            disabled={desabilitarSalvar}
          >
            <Typography style={{ color: '#fff' }}>Salvar alterações</Typography>
          </Button>
        </div>
      </Container>
      <Preview
        orcamento={orcamento}
        descontoPix={descontoPix}
        limiteDescontoExtra={limiteDescontoExtra}
      />
    </Fragment>
  );
}
