import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {}
}));

const ProdutosList = ({
  className,
  ordered_products,
  ticket,
  desconto_pix,
  desconto_extra,
  valor_total,
  reduz,
  frete,
  ...rest
}) => {
  const classes = useStyles();
  const ticket_value = ticket == null ? 0 : ticket.value;
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0.0);

  const handleLimitChange = event => {
    setLimit(event.target.value);
  };

  const handlePageChange = newPage => {
    setPage(newPage);
  };

  useEffect(() => {
    var preco = 0.0;
    ordered_products.map(
      ordered_product =>
        (preco =
          preco +
          ordered_product.preco_compra * ordered_product.quantidade_pedida)
    );
    setTotal(preco - parseFloat(desconto_pix));
  }, [ordered_products]);

  return (
    <Card>
      <PerfectScrollbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{ width: '0.5rem', paddingRight: 0 }}
                align="center"
              >
                Código
              </TableCell>

              <TableCell
                style={{ width: reduz ? '3.5rem' : '8rem', paddingRight: 0 }}
              >
                Nome do Produto
              </TableCell>

              <TableCell
                style={{ width: reduz ? '3.5rem' : '8rem', paddingRight: 0 }}
                align="center"
              >
                Marca
              </TableCell>

              <TableCell
                style={{ width: '0.3rem', paddingRight: 0 }}
                align="center"
              >
                Quantidade
              </TableCell>

              <TableCell
                style={{ width: '0.3rem', paddingRight: 2 }}
                align="center"
              >
                Valor do produto
              </TableCell>

              <TableCell style={{ width: '0.5rem', paddingRight: 0 }}>
                Total
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {ordered_products.map(ordered_product => (
              <TableRow hover key={ordered_product.id}>
                <TableCell align="center">
                  {ordered_product.codigo_produto}
                </TableCell>

                <TableCell>{ordered_product.nome_formal}</TableCell>

                <TableCell align="center">
                  {ordered_product.marca_produto}
                </TableCell>

                <TableCell align="center">
                  {ordered_product.quantidade_pedida}
                </TableCell>

                <TableCell align="center">
                  {ordered_product.preco_compra.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL'
                  })}
                </TableCell>

                <TableCell>
                  {(
                    ordered_product.preco_compra *
                    ordered_product.quantidade_pedida
                  ).toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL'
                  })}
                </TableCell>
              </TableRow>
            ))}

            <TableRow hover>
              <TableCell>SubTotal</TableCell>

              <TableCell>{}</TableCell>

              <TableCell>{}</TableCell>

              <TableCell>{}</TableCell>

              <TableCell>{}</TableCell>

              <TableCell>
                {ordered_products
                  .reduce(
                    (previousValue, item) =>
                      previousValue +
                      item.preco_compra * item.quantidade_pedida,
                    0
                  )
                  .toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL'
                  })}
              </TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell>Entrega</TableCell>

              <TableCell>{}</TableCell>

              <TableCell>{}</TableCell>

              <TableCell>{}</TableCell>

              <TableCell>{}</TableCell>

              <TableCell>
                {frete.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL'
                })}
              </TableCell>
            </TableRow>

            {Boolean(ticket) && (
              <TableRow hover>
                <TableCell>
                  Cupom (<strong>{ticket.codigo}</strong>)
                </TableCell>

                <TableCell>{}</TableCell>

                <TableCell>{}</TableCell>

                <TableCell>{}</TableCell>

                <TableCell>{}</TableCell>

                <TableCell>
                  {'- '}
                  {ticket.value.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL'
                  })}
                </TableCell>
              </TableRow>
            )}

            {Boolean(desconto_pix) && (
              <TableRow hover>
                <TableCell>Desconto Pix</TableCell>

                <TableCell>{}</TableCell>

                <TableCell>{}</TableCell>

                <TableCell>{}</TableCell>

                <TableCell>{}</TableCell>

                <TableCell>
                  {'- '}
                  {desconto_pix.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL'
                  })}
                </TableCell>
              </TableRow>
            )}

            {Boolean(desconto_extra) && (
              <TableRow hover>
                <TableCell>Desconto extra</TableCell>

                <TableCell>{}</TableCell>

                <TableCell>{}</TableCell>

                <TableCell>{}</TableCell>

                <TableCell>{}</TableCell>

                <TableCell>
                  {'- '}
                  {desconto_extra.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL'
                  })}
                </TableCell>
              </TableRow>
            )}

            <TableRow hover>
              <TableCell>{}</TableCell>

              <TableCell>{}</TableCell>

              <TableCell>{}</TableCell>

              <TableCell>{}</TableCell>

              <TableCell>{}</TableCell>

              <TableCell>
                {
                  <b>
                    {valor_total.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL'
                    })}
                  </b>
                }
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </PerfectScrollbar>
      {/* <TablePagination
        component="div"
        count={ordered_products.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      /> */}
    </Card>
  );
};

ProdutosList.propTypes = {
  className: PropTypes.string,
  ordered_products: PropTypes.array.isRequired
};

export default ProdutosList;
