import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  makeStyles,
  Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { toast } from 'react-toastify';
import { req } from '../../services/api';
import { useDispatch, useSelector } from 'react-redux';
import { alterarTabPedidos } from 'src/store/reducers/tabs/actions';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const PedidosListView = ({ permissions }) => {
  const [pedidos, setPedidos] = useState([]);
  const [pedidosSearch, setPedidosSearch] = useState([]);
  const [input, setInput] = useState('');
  const [reload, setReload] = useState(0);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const [lastedOrders, setLastedOrders] = useState([]);

  const [isTabCurrentOrdersSelected, setIsTabCurrentOrdersSelected] = useState(
    true
  );

  const classes = useStyles();

  const { tabPedidos } = useSelector(state => state.tabs);

  const dispatch = useDispatch();

  const handleLimitChange = event => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  async function getOrders() {
    setLoading(true);
    try {
      const data = await req(
        `
      { 
        ${
          !tabPedidos
            ? `allCurrentOrders(skip: ${page *
                limit} take: ${limit} filter: "${input}")`
            : `allPastOrders(skip: ${page *
                limit} take: ${limit} filter: "${input}" initialDate: "${initialDate}" finalDate: "${finalDate}")`
        }{
          data {
            uuid
            status
            frete
            valor_total
            parcelas
            data_pedido
            previsao_entrega
            visualisado
            observacao
            deliveryType
            status_interno
            faturado
            paymentMethod
            {
              type
              uuid_payment_method
            }
            ordered_products 
            {
              uuid
              nome_formal
              codigo_produto
              preco_compra
              quantidade_pedida
            }
            user
            {
              nome
              cpf
              telefone
            }
            paymentMethod{
              type
            }
            cidade
            bairro
            zona
            logradouro
            numero
            complemento
            nome_destinatario
            ticket{
              value
              codigo
            }
        }
        total
        }
      }
      `
      );
      if (data.data) {
        if (!tabPedidos) {
          data.data.allCurrentOrders.data.forEach(order => {
            Object.assign(order, {
              isANewOrder: lastedOrders.find(
                element => element.uuid === order.uuid
              )
                ? false
                : true
            });
          });

          setPedidos(data.data.allCurrentOrders.data);
          setPedidosSearch(data.data.allCurrentOrders.data);
          setLastedOrders(data.data.allCurrentOrders.data);

          setTotal(data.data.allCurrentOrders.total);
        } else {
          setPedidos(data.data.allPastOrders.data);
          setPedidosSearch(data.data.allPastOrders.data);

          setTotal(data.data.allPastOrders.total);
        }
      } else if (data.errors) {
        toast.error(data.errors[0].message);
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }

  useEffect(() => {
    getOrders();
  }, [reload, tabPedidos, page, limit]);

  return (
    <Page className={classes.root} title="Pedidos">
      <Container maxWidth={false}>
        <Typography color="textPrimary" variant="h2">
          Pedidos cadastrados
        </Typography>
        <Toolbar
          input={input}
          setInput={setInput}
          reload={reload}
          setReload={setReload}
          isTabCurrentOrdersSelected={!tabPedidos}
          initialDate={initialDate}
          setInitialDate={setInitialDate}
          finalDate={finalDate}
          setFinalDate={setFinalDate}
        />

        <Box mt={3}>
          <Container
            maxWidth={false}
            style={{
              backgroundColor: 'transparent',
              paddingLeft: 0,
              paddingRight: 0,
              marginBottom: 4,
              width: '100%'
            }}
          >
            <Button
              maxWidth={false}
              variant={!tabPedidos && 'outlined'}
              onClick={() => dispatch(alterarTabPedidos(false))}
              style={{
                backgroundColor: !tabPedidos && '#ffffff',
                color: !tabPedidos && '#F18750',
                width: '50%',
                borderColor: '#F18750',
                borderWidth: 0,
                borderBottomWidth: 3,
                borderRadius: 0,
                borderTopLeftRadius: 8
              }}
            >
              Em andamento
            </Button>
            <Button
              variant={Boolean(tabPedidos) && 'outlined'}
              onClick={() => dispatch(alterarTabPedidos(true))}
              style={{
                backgroundColor: Boolean(tabPedidos) && '#ffffff',
                width: '50%',
                color: Boolean(tabPedidos) && '#F18750',
                borderColor: '#F18750',
                borderWidth: 0,
                borderBottomWidth: 3,
                borderRadius: 0,
                borderTopRightRadius: 8
              }}
            >
              Finalizados
            </Button>
          </Container>
          <Results
            total={total}
            orders={pedidosSearch}
            loading={loading}
            limit={limit}
            handleLimitChange={handleLimitChange}
            page={page}
            handlePageChange={handlePageChange}
            permissions={permissions}
            isFinished={tabPedidos}
            getOrders={getOrders}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default PedidosListView;
