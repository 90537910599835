import React, { Fragment, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Container,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';

import {
  ClienteAutocomplete,
  ProdutoAutocomplete,
  VendedorAutocomplete
} from './Autocomplete';
import QuantidadeDescontoProdutos from './QuantidadeDescontoProdutos';
import { req } from 'src/services/api';
import { toast } from 'react-toastify';
import Preview from './Preview';
import { formatValue } from 'src/utils/currency';

const useStyles = makeStyles(theme => ({
  clientVendedorInput: {
    width: '49%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 10
    }
  },
  clientVendedorContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: '10px',
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  descontoSaveButtonRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 10,
      flexDirection: 'column',
      justifyContent: 'space-between'
    }
  },
  descontos: {
    backgroundColor: '#fff',
    width: '24%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: 10
    }
  },
  label: {
    marginTop: 20,
    marginBottom: 12
  },
  saveButton: {
    marginTop: 10,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  enderecoRow: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      justifyContent: 'center'
    }
  },
  tipoEntrega: {
    width: '49%',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  zonaBairro: {
    width: '24%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 10
    }
  },
  observacoes: {
    width: '49.5%',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  }
}));

const produtosFormatados = produtos =>
  produtos.map(bp => ({
    uuid: bp.budget_product.uuid_produto,
    codigo: bp.budget_product.codigo_produto,
    nome_formal: bp.budget_product.nome_formal_produto,
    marca: bp.budget_product.marca_produto,
    preco: bp.budget_product.valor_unitario_produto,
    preco_promocional: bp.budget_product.valor_unitario_produto,
    unidade_preco: bp.budget_product.unidade,
    images: [
      {
        url: bp.url_imagem_produto
      }
    ],
    quant: bp.budget_product.quantidade_pedida_produto,
    desconto: bp.budget_product.desconto,
    desconto_pix: bp.budget_product.desconto_pix,
    quantidade_estoque: bp.quantidade_estoque
  }));

export default function OrcamentoAberto({
  zonas,
  bairros,
  orcamento,
  descontoPix,
  refreshOrcamento,
  limiteDescontoExtra
}) {
  // dados
  const [cliente, setCliente] = useState(orcamento.nome_cliente || '');
  const [vendedor, setVendedor] = useState(orcamento.nome_vendedor || null);

  // produtos
  const [produtos, setProdutos] = useState(
    produtosFormatados(orcamento.budget_products)
  );
  const [produtoSelecionado, setProdutoSelecionado] = useState(null);

  // entrega
  const [tipoEntrega, setTipoEntrega] = useState(orcamento.tipo_entrega || 0);
  const [zona, setZona] = useState(orcamento.zona || '');
  const [bairro, setBairro] = useState(orcamento.bairro || '');

  // pagamento
  const [desconto, setDesconto] = useState(orcamento?.desconto || '');
  const [aplicarDescontoExtra, setAplicarDescontoExtra] = useState(
    orcamento.aplicar_desconto_extra || false
  );

  // observações
  const [observacoes, setObservacoes] = useState(orcamento.observacoes || '');

  const classes = useStyles();

  const disableSaveButton = useMemo(() => false, []);

  const bairrosPorZona = useMemo(
    () =>
      zona
        ? [
            ...(bairros || []).filter(bairro => bairro.zone_id === zona),
            { uuid: '0', name: 'Outro' }
          ]
        : [],
    [zona]
  );

  async function removerProduto(uuid) {
    try {
      const data = await req(`
        mutation{
          removeProductFromBudget(uuid: "${orcamento.uuid}" product_uuid: "${uuid}")
        }
       `);
      if (data.errors) {
        toast.error(data.errors[0].message);
      }
      refreshOrcamento();
    } catch (error) {
      toast.error('Não foi possível remover o produto');
      console.log(error);
    }
  }

  async function alterarQuantidadeDesconto(uuid, quantidade, desconto) {
    try {
      const data = await req(`
        mutation{
            updateBudget(
                uuid: "${orcamento.uuid}"
                fields: {
                    quant_desconto_update: {
                        product_uuid: "${uuid}"
                        quant: ${Number(quantidade)}
                        desconto: ${Number(desconto)}
                    }
                }
            )
        }
       `);
      if (data.errors) {
        toast.error(data.errors[0].message);
      }
      refreshOrcamento();
    } catch (error) {
      toast.error('Não foi possível salvar os dados');
      console.log(error);
    }
  }

  function handleProdutoSelecionado(prop, value) {
    setProdutoSelecionado({
      ...produtoSelecionado,
      [prop]: value
    });
  }

  async function aplicarAlterações() {
    if (tipoEntrega === 1 && (bairro === '' || zona === '')) {
      toast.warning('Os campos zona e bairro devem ser preenchidos.');
      return;
    }

    const produtosSelecionados = produtos.map(produto => produto.uuid);
    const produtosOrcamentos = orcamento.budget_products.map(
      produto => produto.budget_product.uuid_produto
    );

    const produtosAdicionados = produtosSelecionados.filter(
      produto => !produtosOrcamentos.includes(produto)
    );

    const produtosRemovidos = produtosOrcamentos.filter(
      produto => !produtosSelecionados.includes(produto)
    );

    let mutation = '';

    if (produtosAdicionados.length) {
      produtosAdicionados.forEach(
        (uuid, index) =>
          (mutation += `addProductToBudget${index}: addProductToBudget(uuid: "${orcamento.uuid}" product_uuid: "${uuid}")`)
      );
    }

    if (produtosRemovidos.length) {
      produtosRemovidos.forEach(
        (uuid, index) =>
          (mutation += `removeProductFromBudget${index}: removeProductFromBudget(uuid: "${orcamento.uuid}" product_uuid: "${uuid}")`)
      );
    }

    try {
      const data = await req(`
        mutation{
            ${mutation}
            updateBudget(
                uuid: "${orcamento.uuid}"
                fields: {
                    nome_vendedor: "${vendedor}"
                    nome_cliente: "${cliente}"
                    desconto: ${Number(desconto)}
                    tipo_entrega: ${tipoEntrega}
                    bairro: "${bairro}"
                    zona: "${zona}"
                    aplicar_desconto_extra: ${aplicarDescontoExtra}
                    observacoes: "${observacoes}"
                    ${
                      produtoSelecionado
                        ? `
                        quant_desconto_update: {
                            product_uuid: "${produtoSelecionado.uuid}"
                            quant: ${produtoSelecionado.quant}
                            desconto: ${Number(produtoSelecionado.desconto)}
                        }
                        `
                        : ''
                    }
                }
            )
        }
       `);
      if (data.errors) {
        toast.error(data.errors[0].message);
      }
      refreshOrcamento();
    } catch (error) {
      toast.error('Não foi possível salvar os dados');
      console.log(error);
    }
  }

  useEffect(() => {
    const lastProduct = produtos.at(-1);
    setProdutoSelecionado(
      lastProduct
        ? {
            ...lastProduct,
            quant: lastProduct.quant || 1,
            desconto: lastProduct.desconto || ''
          }
        : null
    );
  }, [produtos]);

  return (
    <Fragment>
      <Container maxWidth={true}>
        <Typography color="textPrimary" variant="h4" className={classes.label}>
          Dados
        </Typography>
        <div className={classes.clientVendedorContainer}>
          <div className={classes.clientVendedorInput}>
            <ClienteAutocomplete value={cliente} setSelected={setCliente} />
          </div>
          <div className={classes.clientVendedorInput}>
            <VendedorAutocomplete value={vendedor} setSelected={setVendedor} />
          </div>
        </div>

        <Typography color="textPrimary" variant="h4" className={classes.label}>
          Produtos
        </Typography>

        <ProdutoAutocomplete
          budgetUuid={orcamento.uuid}
          selected={produtos}
          setSelected={setProdutos}
          setLastAddedProduct={() => {}}
          validarEstoque
          descontoPix={descontoPix}
          descontoOrcamento={desconto}
        />

        <QuantidadeDescontoProdutos
          produtos={produtos}
          produtoSelecionado={produtoSelecionado}
          handleProdutoSelecionado={setProdutoSelecionado}
          handleQuantidade={value => handleProdutoSelecionado('quant', value)}
          handleDesconto={value => handleProdutoSelecionado('desconto', value)}
        />

        <Typography color="textPrimary" variant="h4" className={classes.label}>
          Entrega
        </Typography>
        <div className={classes.enderecoRow}>
          <FormControl variant="outlined" className={classes.tipoEntrega}>
            <InputLabel id="tipo-entrega">Tipo de entrega</InputLabel>
            <Select
              labelId="tipo-entrega"
              value={tipoEntrega}
              label="Tipo de entrega"
              onChange={event => setTipoEntrega(event.target.value)}
            >
              <MenuItem value={0}>Retirada na loja</MenuItem>
              <MenuItem value={1}>Entregue no endereço</MenuItem>
            </Select>
          </FormControl>

          <FormControl variant="outlined" className={classes.zonaBairro}>
            <InputLabel id="zona">Zona</InputLabel>
            <Select
              labelId="zona"
              value={zona}
              label="Zona"
              disabled={tipoEntrega !== 1}
              onChange={event => setZona(event.target.value)}
            >
              {zonas.map(zona => (
                <MenuItem value={zona.uuid}>{zona.name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="outlined" className={classes.zonaBairro}>
            <InputLabel id="bairro">Bairro</InputLabel>
            <Select
              labelId="bairro"
              value={bairro}
              label="Bairro"
              disabled={!zona || tipoEntrega !== 1}
              onChange={event => setBairro(event.target.value)}
            >
              {(bairrosPorZona || []).map(bairro => (
                <MenuItem value={bairro.uuid}>{bairro.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <Typography color="textPrimary" variant="h4" className={classes.label}>
          Descontos
        </Typography>

        <div className={classes.descontoSaveButtonRow}>
          <TextField
            fullWidth
            label="Valor de desconto total"
            name="desconto"
            value={desconto}
            placeholder="0"
            type="number"
            onChange={e => setDesconto(formatValue(e.target.value))}
            variant="outlined"
            InputProps={{
              endAdornment: <InputAdornment position="end"> % </InputAdornment>
            }}
            className={classes.descontos}
            InputLabelProps={{
              style: { color: '#c4c4c4' }
            }}
          />
          <FormControl variant="outlined" className={classes.descontos}>
            <InputLabel id="desconto-extra">Aplicar desconto extra</InputLabel>
            <Select
              labelId="desconto-extra"
              value={aplicarDescontoExtra}
              label="Aplicar desconto extra"
              onChange={event => setAplicarDescontoExtra(event.target.value)}
            >
              <MenuItem value={true}>Sim</MenuItem>
              <MenuItem value={false}>Não</MenuItem>
            </Select>
          </FormControl>

          <TextField
            className={classes.observacoes}
            label="Observações"
            variant="outlined"
            value={observacoes}
            onChange={e => setObservacoes(e.target.value)}
          />
        </div>

        <div className={classes.saveButton}>
          <Button
            component="span"
            color="primary"
            onClick={aplicarAlterações}
            variant="contained"
            disabled={disableSaveButton}
          >
            <Typography style={{ color: '#fff' }}>
              Aplicar alterações
            </Typography>
          </Button>
        </div>
      </Container>
      <Preview
        orcamento={orcamento}
        descontoPix={descontoPix}
        removerProduto={removerProduto}
        alterarQuantidadeDesconto={alterarQuantidadeDesconto}
        limiteDescontoExtra={limiteDescontoExtra}
      />
    </Fragment>
  );
}
