import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';

import { req } from 'src/services/api';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'auto'
  },
  inner: {
    padding: theme.spacing(3),
    backgroundColor: 'white',
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      width: '95%'
    },
    overflowY: 'scroll'
  }
}));

const MergeModal = ({ visible, onClose, permissions }) => {
  const classes = useStyles();

  const navigate = useNavigate();

  const [orcamentos, setOrcamentos] = useState([]);
  const [selected, setSelected] = useState([]);
  const [skip, setSkip] = useState(0);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  async function juntarOrcamentos() {
    setIsLoading(true);

    try {
      const orcamento = await req(
        `
            mutation{
              mergeBudgets(budgets: [${selected
                .map(budget => `"${budget.uuid}"`)
                .join(', ')}]){
                uuid
                status
                codigo
              }
            }
            `
      );
      setIsLoading(false);
      if (orcamento.data) {
        navigate(`/orcamento/${orcamento.data.mergeBudgets.uuid}`, {
          state: { permissions }
        });
      } else if (orcamento.errors) {
        toast.error(orcamento.errors[0].message);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error('Não foi possível criar novo orçamento');
    }
  }

  async function loadOrcamentos(filter, skip, clear = false) {
    try {
      const data = await req(
        `
                {
                    readBudgets(
                    skip: ${skip * 50}
                    take: 50
                    filter: "${filter}"
                    status: null
                  ){
                    data {
                      uuid
                      codigo
                    }
                    total
                  }
                }
                `
      );
      if (data.data) {
        if (clear) {
          setOrcamentos(data.data.readBudgets.data);
        } else {
          setOrcamentos([...orcamentos, ...data.data.readBudgets.data]);
        }
      }
    } catch (err) {}
  }

  useEffect(() => {
    loadOrcamentos('', 0, true);
  }, []);

  return (
    <Modal open={visible} onClose={onClose} className={classes.modal}>
      <div className={classes.inner}>
        <Typography variant="h3" color="textPrimary">
          Juntar orçamentos
        </Typography>
        <div style={{ marginTop: 20 }}>
          <div style={{ width: '100%' }}>
            <Autocomplete
              id="orcamento-autocomplete"
              multiple
              disableClearable
              disableCloseOnSelect
              onChange={(_, value) => setSelected(value)}
              noOptionsText="Nenhum orçamento encontrado"
              options={orcamentos}
              onInputChange={(_, value, reason) => {
                setSkip(0);
                if (reason == 'reset') {
                  loadOrcamentos(inputValue, 0, true);
                } else {
                  setInputValue(value);
                  loadOrcamentos(value, 0, true);
                }
              }}
              onSelect={event => setSelected(event.target.value)}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Selecionar orçamentos"
                  variant="outlined"
                  value={inputValue}
                  placeholder="Selecionar..."
                  onChange={event => setInputValue(event.target.value)}
                  style={{ backgroundColor: '#fff' }}
                />
              )}
              ListboxProps={{
                onScroll: event => {
                  const listboxNode = event.currentTarget;

                  if (
                    Math.round(
                      listboxNode.scrollTop + listboxNode.clientHeight
                    ) === listboxNode.scrollHeight
                  ) {
                    setSkip(skip + 1);
                    loadOrcamentos(inputValue, skip + 1);
                  }
                }
              }}
              getOptionLabel={option => option?.codigo}
              renderOption={option => <p>{option.codigo}</p>}
            />
          </div>
        </div>
        <div style={{ width: '100%', marginTop: 20 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}
          >
            <Button
              style={{ marginRight: 10 }}
              color="secondary"
              variant="outlined"
              onClick={onClose}
            >
              Cancelar
            </Button>
            {isLoading ? (
              <p>Carregando...</p>
            ) : (
              <Button
                disabled={selected.length === 0 || isLoading}
                color="primary"
                variant="outlined"
                onClick={juntarOrcamentos}
              >
                Confirmar
              </Button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MergeModal;
