import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Container,
  makeStyles,
  Typography,
  Modal
} from '@material-ui/core';
import Page from 'src/components/Page';
import RegisterProduct from './RegisterProduct';
import Toolbar from './Toolbar';
import Results from './Results';
import { req } from '../../../services/api';
import { toast } from 'react-toastify';
import Filters from './Filters';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'auto'
  }
}));

const Produtos = ({ permissions }) => {
  const classes = useStyles();
  const [produtos, setProdutos] = useState([]);
  const [produtosSearch, setProdutosSearch] = useState([]);
  const [input, setInput] = useState('');
  const [image, setImage] = useState([]);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [categorias, setCategorias] = useState([]);

  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  const [openQuantFilterModal, setOpenFilterModal] = useState(false);

  const quantFilterModalRef = useRef();

  function handleOpen() {
    setOpenModalAdd(true);
  }

  function handleClose() {
    setOpenModalAdd(false);
  }

  const [produtoCarregado, setProdutoCarregado] = useState({
    codigo: '',
    nomeTecnico: '',
    nomePopular: '',
    categoria: 'nao_selecionado',
    marca: '',
    unidade_preco: '',
    descricao: '',
    preco: '',
    desconto_pix: '',
    estoque: ''
  });

  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);

  const [sortFilter, setSortFilter] = useState(null);

  const handleChange = event => {
    setProdutoCarregado({
      ...produtoCarregado,
      [event.target.name]: event.target.value
    });
  };

  const handleSelectAll = event => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = produtosSearch.map(produto => produto.uuid);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event, uuid) => {
    const selectedIndex = selectedCustomerIds.indexOf(uuid);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds,
        uuid
      );
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(1)
      );
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  async function loadCategorias() {
    try {
      const data = await req(
        `{
            readCategory{
                uuid
                nome
              }
          }`
      );
      if (data?.data) {
        setCategorias(data?.data?.readCategory);
      }
    } catch (err) {}
  }

  async function loadProdutos() {
    setLoading(true);
    try {
      const data = await req(
        `
          {
            readProduct(
              skip: ${page * limit}
              take: ${limit}
              filter: "${input}"
              ${
                !!sortFilter
                  ? `sort: {cell: "${sortFilter.cell}", sort: "${sortFilter.sort}"}`
                  : ''
              }
              ${
                quantFilterModalRef.current.getCategoria() !== 0
                  ? `categoria: "${quantFilterModalRef.current.getCategoria()}"`
                  : ''
              }
              ${
                quantFilterModalRef.current.hasQuantFilter()
                  ? `
                numberFilters:
                [
                  {column: "quantidade_estoque", operator: ${quantFilterModalRef.current.getQuantOperator()}, value: ${quantFilterModalRef.current.getQuantValue()}}
                ]
                `
                  : ''
              }
            ){
              data {
                uuid
                codigo
                nome_formal
                category{
                  nome
                }
                marca
                quantidade_estoque
                preco
                desconto_pix
              }
              total
            }
          }
          `
      );
      if (data.data) {
        setProdutos(data.data.readProduct.data);
        setTotal(data.data.readProduct.total);
        setSelectedCustomerIds([]);
      } else if (data.errors) {
        alert(data.errors);
      }
    } catch (err) {
      alert(err);
    }
    setLoading(false);
  }

  useEffect(() => {
    loadProdutos();
  }, [page, limit, sortFilter]);

  useEffect(() => {
    loadCategorias();
  }, []);

  async function carregarProduto(uuid) {
    try {
      const data = await req(
        `
        {
          searchProductById(uuid: "${uuid}"){
                uuid
                codigo
                nome_popular
                nome_formal
                quantidade_estoque
                preco
                desconto_pix
                category{
                  uuid
                  nome
                }
                marca
                unidade_preco
                descricao
                images{
                  uuid
                  url
                }
          }
        }
        `
      );
      if (data.data) {
        setProdutoCarregado({
          uuid: data.data.searchProductById.uuid,
          codigo: data.data.searchProductById.codigo,
          nomeTecnico: data.data.searchProductById.nome_formal,
          nomePopular: data.data.searchProductById.nome_popular,
          categoria: data.data.searchProductById.category.uuid,
          marca: data.data.searchProductById.marca,
          unidade_preco: data.data.searchProductById.unidade_preco,
          descricao: data.data.searchProductById.descricao,
          preco: data.data.searchProductById.preco
            ? parseFloat(data.data.searchProductById.preco).toFixed(2)
            : data.data.searchProductById.preco,
          desconto_pix: data.data.searchProductById.desconto_pix
            ? parseFloat(data.data.searchProductById.desconto_pix).toFixed(2)
            : data.data.searchProductById.desconto_pix,
          estoque: data.data.searchProductById.quantidade_estoque
        });
        var arrayImageToBeSaved = [];
        data.data.searchProductById.images.map(async image => {
          if (image.url) {
            const pieces = image.url.split('/');
            arrayImageToBeSaved.push({
              nome: pieces[pieces.length - 1],
              url: image.url
            });
          }
        });
        setImage(arrayImageToBeSaved);
      } else if (data.errors) {
        alert(data.errors);
      }
    } catch (err) {
      alert(err);
    }
  }

  async function excluirProdutos(excluirList) {
    var i;
    var excluidos = '[';
    if (excluirList.length === 0) {
      return;
    }
    for (i = 0; i < excluirList.length; i++) {
      excluidos = excluidos + '"' + excluirList[i] + '", ';
    }
    excluidos = excluidos + ']';
    const dataDelete = await req(
      `mutation{
      deleteProduct (
        uuid: ${excluidos}
      )  
    }`
    );

    if (dataDelete.data) {
      toast.success('Produto excluído com sucesso!');
      loadProdutos();
    } else if (dataDelete.errors) {
      toast.error('Não foi possível exluir o produto');
    }
  }

  const handleLimitChange = event => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  function handleSearch() {
    setPage(0);
    loadProdutos();
  }

  return (
    <Page className={classes.root} title="Produtos">
      <Filters
        ref={quantFilterModalRef}
        open={openQuantFilterModal}
        onClose={() => setOpenFilterModal(false)}
        onApply={() => loadProdutos()}
        categorias={[{ uuid: 0, nome: 'Todas' }, ...categorias]}
      />
      <Modal
        open={openModalAdd}
        onClose={() => {
          setOpenModalAdd(false);
        }}
        className={classes.modal}
      >
        <RegisterProduct
          image={image}
          setImage={setImage}
          setProdutoCarregado={setProdutoCarregado}
          loadProdutos={loadProdutos}
          handleChange={handleChange}
          produtoCarregado={produtoCarregado}
          handleClose={handleClose}
          toast={toast}
        />
      </Modal>
      <Container maxWidth={false}>
        <Typography color="textPrimary" variant="h2">
          Produtos cadastrados
        </Typography>
        <Toolbar
          produtos={produtos || []}
          setProdutosSearch={setProdutosSearch}
          input={input}
          setInput={setInput}
          excluirList={selectedCustomerIds}
          excluirProdutos={excluirProdutos}
          setPage={setPage}
          handleOpen={handleOpen}
          onClick={handleSearch}
          categoria={() => quantFilterModalRef.current.getCategoria()}
          hasQuantFilter={() => quantFilterModalRef.current.hasQuantFilter()}
          quantOperator={() => quantFilterModalRef.current.getQuantOperator()}
          quantValue={() => quantFilterModalRef.current.getQuantValue()}
          filter={input}
          permissions={permissions}
        />
        <Box mt={3}>
          <Results
            handleSelectOne={handleSelectOne}
            page={page}
            limit={limit}
            produtos={produtos || []}
            handleLimitChange={handleLimitChange}
            handlePageChange={handlePageChange}
            handleSelectAll={handleSelectAll}
            selectedCustomerIds={selectedCustomerIds}
            carregarProduto={carregarProduto}
            handleOpen={handleOpen}
            loading={loading}
            total={total}
            setOpenFilterModal={setOpenFilterModal}
            canEdit={permissions.editar}
            canRemove={permissions.excluir}
            sortFilter={sortFilter}
            handleSortFilter={setSortFilter}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default Produtos;
