import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  Button,
  Typography
} from '@material-ui/core';
import {
  Search as SearchIcon,
  Plus as PlusIcon,
  Minimize2 as Minimize2Icon
} from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { req } from 'src/services/api';
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3)
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  SearchInput: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
    width: '100%'
  },
  Button: {
    marginLeft: 10,
    marginRight: 10,
    color: '#fff',
    height: 55,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: 10,
      marginBottom: 10,
      marginLeft: 0,
    }
  },
  buscar: {
    width: '40%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: 10
    }
  },
  produtos: {
    width: '60%',
    marginLeft: 10,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: 0
    }
  }
}));

const Toolbar = ({
  className,
  input,
  setInput,
  openModal,
  onClick,
  permissions,
  handleOpenModal,
  produtosSelecionados,
  setProdutosSelecionados,
  ...rest
}) => {
  const classes = useStyles();

  const navigate = useNavigate();

  async function navigateToNovoOrcamento() {
    try {
      const orcamento = await req(
        `
          mutation{
            createBudget{
              uuid
              status
              codigo
            }
          }
          `
      );

      if (orcamento.data) {
        navigate(`/orcamento/${orcamento.data.createBudget.uuid}`, {
          state: { permissions }
        });
      } else if (orcamento.errors) {
        toast.error(orcamento.errors[0].message);
      }
    } catch (error) {
      console.log(error);
      toast.error('Não foi possível criar novo orçamento');
    }
  }

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box mt={3} className={classes.container}>
        <Box maxWidth={1200} className={classes.SearchInput}>
          <TextField
            fullWidth
            value={input}
            className={classes.buscar}
            onChange={e => setInput(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              )
            }}
            placeholder="Buscar orçamento"
            variant="outlined"
            onKeyPress={e => {
              if (e.key === 'Enter') {
                onClick();
              }
            }}
          />
          {/* <div className={classes.produtos}>
            <ProdutoAutocomplete
              selected={produtosSelecionados}
              setSelected={setProdutosSelecionados}
            />
          </div> */}
          <Button
            className={classes.Button}
            onClick={onClick}
            color="primary"
            variant="contained"
          >
            Buscar
          </Button>
        </Box>
        {permissions.adicionar && (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button
              onClick={handleOpenModal}
              color="primary"
              variant="contained"
              style={{
                width: 55,
                height: 55,
                marginRight: 10
              }}
            >
              <Typography
                style={{
                  color: '#fff',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: 14
                }}
              >
                <Minimize2Icon
                  style={{ fontSize: 28, transform: `rotate(45deg)` }}
                />
              </Typography>
            </Button>
            <Button
              onClick={navigateToNovoOrcamento}
              color="primary"
              variant="contained"
              style={{
                width: 55,
                height: 55
              }}
            >
              <Typography
                style={{
                  color: '#fff',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: 14
                }}
              >
                <PlusIcon style={{ fontSize: 28 }} />
              </Typography>
            </Button>
          </div>
        )}
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
