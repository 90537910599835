import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  TextField,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '50px'
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: 1,
    marginTop: 10,
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  SearchInput: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: 10,
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 10
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: '50%'
    },
    width: '100%'
  },
  dateInputBox: {
    margin: 0,
    padding: 0,
    marginRight: 10
  },
  Button: {
    color: '#fff',
    marginRight: 10
  }
}));

function convertStatus(status) {
  if (status === 1) {
    return 'Aguardando o Pagamento';
  } else if (status === 2) {
    return 'Preparando Para a Entrega';
  } else if (status === 3) {
    return 'Saiu para Entrega';
  } else if (status === 4) {
    return 'Entregue';
  } else return 'Cancelado';
}

const Toolbar = ({
  className,
  input,
  setInput,
  reload,
  setReload,
  isTabCurrentOrdersSelected,
  initialDate,
  setInitialDate,
  finalDate,
  setFinalDate,
  ...rest
}) => {
  const classes = useStyles();

  function handleReload() {
    setReload(!reload);
  }

  function handleClear() {
    setInput('');
    setInitialDate('');
    setFinalDate('');
    handleReload();
  }

  const handleChangeDate = (type, event) => {
    console.log(event.target.value, 'date');
    if (type === 'De') {
      event.target.value !== ''
        ? setInitialDate(event.target.value + ' 00:00:00.000000')
        : setInitialDate(event.target.value);
    } else {
      event.target.value !== ''
        ? setFinalDate(event.target.value + ' 23:59:59.999999')
        : setFinalDate(event.target.value);
    }
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box className={classes.container}>
        <Box className={classes.dateInputBox}>
          <TextField
            id="date"
            label="DE"
            type="date"
            variant="outlined"
            onChange={event => handleChangeDate('De', event)}
            InputLabelProps={{
              shrink: true
            }}
            value={initialDate.replace(' 00:00:00.000000', '')}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                handleReload();
              }
            }}
          />
        </Box>

        <Box className={classes.dateInputBox}>
          <TextField
            id="date"
            label="ATÉ"
            type="date"
            variant="outlined"
            onChange={event => handleChangeDate('Ate', event)}
            InputLabelProps={{
              shrink: true
            }}
            value={finalDate.replace(' 23:59:59.999999', '')}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                handleReload();
              }
            }}
          />
        </Box>

        <Button
          className={classes.Button}
          onClick={handleReload}
          color="primary"
          variant="contained"
        >
          Buscar
        </Button>

        <Button
          className={classes.Button}
          onClick={handleClear}
          color="secondary"
          variant="contained"
        >
          Limpar
        </Button>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
