import React, { Fragment, useEffect, useState } from 'react';
import Page from 'src/components/Page';
import { HelpCircle as HelpCircleIcon } from 'react-feather';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import clsx from 'clsx';
import { req } from '../../services/api';
import { toast } from 'react-toastify';
import FAQCard from './faqCard';
import { Pagination } from '@material-ui/lab';
import { ImSad2 } from 'react-icons/im';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  title: {
    paddingBottom: theme.spacing(1),
    marginLeft: theme.spacing(2)
  },
  input: {
    display: 'none'
  },
  inputLabel: {
    paddingBottom: theme.spacing(1)
  }
}));

const FAQ = ({ permissions }) => {
  const [titulo, setTitulo] = useState('');
  const [resposta, setResposta] = useState('');
  const [faqs, setFaqs] = useState('');

  const [loadingNewFAQ, setLoadingNewFAQ] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [input, setInput] = useState('');

  const classes = useStyles();

  const isInputValid = () => titulo !== '' && resposta !== '';

  function clearInputs() {
    setTitulo('');
    setResposta('');
  }

  async function loadFAQ() {
    setLoading(true);
    try {
      const resp = await req(
        `
            {
                readFAQManager{
                    uuid
                    titulo
                    resposta
                }
            }
            `
      );
      if (resp.data) {
        console.log(resp.data);
        setFaqs(resp.data.readFAQManager);
        setPage(1);
      } else if (resp.errors) {
        alert(resp.errors);
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }

  useEffect(() => {
    loadFAQ();
  }, []);

  async function handleNewFAQ(e) {
    e.preventDefault();
    setLoadingNewFAQ(true);

    try {
      const data = await req(
        `
              mutation{
                insertFAQ(
                    fields: {
                        titulo: "${titulo}"
                        resposta: "${resposta}"
                      }
                )
              }
            `
      );
      if (data.data) {
        toast.success('FAQ cadastrado com sucesso!');
        clearInputs();
      } else if (data.errors) {
        toast.error(data.errors[0].message);
      }
    } catch (err) {
      alert(err);
    }
    setLoadingNewFAQ(false);
    loadFAQ();
  }

  return (
    <Page className={classes.root} title="FAQ">
      <Container maxWidth={false}>
        {permissions.adicionar && (
          <Fragment>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <HelpCircleIcon color="#5e5e5f" style={{ margin: 10 }} />
              <Typography color="textPrimary" variant="h2">
                Adicionar FAQ
              </Typography>
            </div>

            <form
              autoComplete="off"
              onSubmit={() => {}}
              className={clsx(classes.root)}
            >
              <Card>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Título"
                        name="titulo"
                        value={titulo}
                        onChange={event => setTitulo(event.target.value)}
                        variant="outlined"
                        InputLabelProps={{
                          style: { color: '#c4c4c4' }
                        }}
                      />
                    </Grid>
                    <Box width="100%" />
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Resposta"
                        name="resposta"
                        value={resposta}
                        multiline={true}
                        rows={5}
                        onChange={event => setResposta(event.target.value)}
                        variant="outlined"
                        InputLabelProps={{
                          style: { color: '#c4c4c4' }
                        }}
                      />
                    </Grid>
                    <Box width="100%" />
                    <Grid item xs={6} sm={2}>
                      <Button
                        onClick={handleNewFAQ}
                        color="primary"
                        variant="contained"
                        disabled={!isInputValid()}
                        style={{
                          height: 55
                        }}
                      >
                        {loadingNewFAQ ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : (
                          <Typography
                            style={{
                              color: '#fff',
                              display: 'flex'
                            }}
                          >
                            Cadastrar
                          </Typography>
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </form>
          </Fragment>
        )}

        {loading ? (
          <div
            style={{
              width: '100%',
              height: '70vh',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <CircularProgress color="primary" size={50} />
          </div>
        ) : (
          <>
            {faqs.length > 0 ? (
              <div>
                <Box mt={3}>
                  <Grid container spacing={3}>
                    {faqs.map(faq => (
                      <Grid item key={faq.uuid} lg={4} md={6} xs={12}>
                        <FAQCard
                          className={classes.faqCard}
                          faq={faq}
                          loadFAQ={loadFAQ}
                          permissions={permissions}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
                {input === '' && (
                  <Box mt={3} display="flex" justifyContent="center">
                    <Pagination
                      color="primary"
                      boundaryCount={1}
                      variant="outlined"
                      page={page}
                      onChange={(event, value) => {
                        setPage(value);
                      }}
                      count={Math.ceil(faqs.length / 9)}
                      size="small"
                    />
                  </Box>
                )}
              </div>
            ) : (
              <div
                style={{
                  paddingTop: 30,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <ImSad2 size={150} color="lightgray"></ImSad2>
                <h2 style={{ paddingTop: 10, color: 'gray' }}>
                  Nenhuma faq encontrada
                </h2>
              </div>
            )}
          </>
        )}
      </Container>
    </Page>
  );
};

export default FAQ;
