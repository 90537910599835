import { combineReducers } from 'redux';

import login from './login/reducer';
import auth from './auth/reducer';
import tabs from './tabs/reducer';

const rootReducer = combineReducers({
  login,
  auth,
  tabs
});

export default rootReducer;
