import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
  Modal,
  CircularProgress
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import MarcaCard from './marcaCard';
import AddMarca from './AddMarca';
import { ImSad2 } from 'react-icons/im';
import { toast } from 'react-toastify';

import { req } from '../../../services/api';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  marcaCard: {
    height: '100%'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const MarcaList = ({ permissions }) => {
  const classes = useStyles();

  const [marcas, setMarcas] = useState([]);
  const [marcasSearch, setMarcasSearch] = useState([]);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState('');
  const [page, setPage] = useState(1);

  async function loadMarcas() {
    setLoading(true);
    try {
      const resp = await req(
        `
        {
          readMark{
            uuid
            nome
            url
          }
        }
        `
      );
      if (resp.data) {
        setMarcas(resp.data.readMark);
        setMarcasSearch(resp.data.readMark.slice(0, 9));
        setPage(1);
      } else if (resp.errors) {
        alert(resp.errors);
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }

  useEffect(() => {
    loadMarcas();
  }, []);

  function handleOpen() {
    setOpenModalAdd(true);
  }

  function handleClose() {
    setOpenModalAdd(false);
  }

  useEffect(() => {
    if (input === '') {
      setMarcasSearch(marcas.slice((page - 1) * 9, (page - 1) * 9 + 9));
    }
  }, [page, input, marcas]);

  return (
    <Page className={classes.root} title="Marcas">
      <Container maxWidth={false}>
        <Modal
          open={openModalAdd}
          onClose={() => {
            setOpenModalAdd(false);
          }}
          className={classes.modal}
        >
          <AddMarca
            loadMarcas={loadMarcas}
            handleClose={handleClose}
            toast={toast}
          />
        </Modal>
        <Typography color="textPrimary" variant="h2">
          Marcas cadastradas
        </Typography>
        {loading ? (
          <div
            style={{
              width: '100%',
              height: '70vh',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <CircularProgress color="primary" size={50} />
          </div>
        ) : (
          <>
            <Toolbar
              marcas={marcas}
              setMarcasSearch={setMarcasSearch}
              input={input}
              setInput={setInput}
              openModal={handleOpen}
              permissions={permissions}
            />
            {marcasSearch.length > 0 ? (
              <div>
                <Box mt={3}>
                  <Grid container spacing={3}>
                    {marcasSearch.map(marca => (
                      <Grid item key={marca.uuid} lg={4} md={6} xs={12}>
                        <MarcaCard
                          className={classes.marcaCard}
                          marca={marca}
                          loadMarcas={loadMarcas}
                          permissions={permissions}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
                {input === '' && (
                  <Box mt={3} display="flex" justifyContent="center">
                    <Pagination
                      color="primary"
                      boundaryCount={1}
                      variant="outlined"
                      page={page}
                      onChange={(event, value) => {
                        setPage(value);
                      }}
                      count={Math.ceil(marcas.length / 9)}
                      size="small"
                    />
                  </Box>
                )}
              </div>
            ) : (
              <div
                style={{
                  paddingTop: 30,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <ImSad2 size={150} color="lightgray"></ImSad2>
                <h2 style={{ paddingTop: 10, color: 'gray' }}>
                  Nenhuma marca econtrada
                </h2>
              </div>
            )}
          </>
        )}
      </Container>
    </Page>
  );
};

export default MarcaList;
