import React, { useState, useEffect } from 'react';import {
    Box,
    Grid,
    Button,
    Card,
    CardContent,
    makeStyles,
    Typography,
    TextField
} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { store } from '../../store';
import { Auth } from '../../store/reducers/auth/actions';
import { connect } from 'react-redux';
import { req } from '../../services/api';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
        backgroundColor: "white",
        width: '85%',
        [theme.breakpoints.down('xs')]: {
            width: '95%',
            height: '95%',
        },
        overflowY: 'scroll'
    },
    title: {
        paddingBottom: theme.spacing(1)
    },
    cover: {
        width: 360,
        height: 200,
    },
    input: {
        display: 'none'
    },
    inputLabel: {
        paddingBottom: theme.spacing(1),
    },
    imagesContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
        gridGap: '16px',
        flexDirection: 'row',
    }
}));


const AddPromocao = ({ loadCupons, dispatch, handleClose, toast,cupom,setCupom,today}) => {

    const [vendedores, setVendedores] = useState([]);
    const classes = useStyles();

    const handleChange = (event) => {
    setCupom({
      ...cupom,
      [event.target.name]: event.target.value
    });
  };

    

    useEffect(() => {

        async function loadVendedores() {
            const data = await req(
                `{
                    readSellers{
                        id
                        uuid
                        nome
                      }
                  }`
            );
            
            if (data && data.data?.readSellers) {
                var listaDeVendedores = [];
                listaDeVendedores.push({
                    value: "0",
                    label: "Selecionar vendedor",
                });
                for (var i = 0; i < data.data.readSellers.length; i++) {
                    listaDeVendedores.push({
                        value: data.data.readSellers[i].id,
                        label: data.data.readSellers[i].nome,
                    });
                }
                setVendedores(listaDeVendedores);
            } else if (data && data.errors) {
                //alert(data.errors);
            }else if (!data) {
                alert("Erro: Não foi possível se comunicar com o servidor!");
            }
        }

        if (vendedores.length == 0) {
            loadVendedores();
        }

    }, [vendedores])


    async function atualizarCupom(event) {
        event.preventDefault();
        console.log(cupom)
        try {
             const req_ = await req(
              `mutation{
                    updateTicket
                    (
                       uuid:"${cupom.uuid}"
                        codigo:"${cupom.codigo}"
                        value:${cupom.value}
                        expiration_date:"${cupom.expiration_date}"
                        min_value:${cupom.min_value}
                        seller_id:${cupom.seller_id}
                    )
                  }
              `
              )
           if(!req_.data.errors)
                toast.success("Cupom cadastrado com sucesso!");
            else
                toast.error("Erro ao cadastrar cupom: "+req_.errors.message);
        } catch (error) {
            toast.error("Erro ao cadastrar cupom: ");
            handleClose();
            return;
        }
        handleClose();
        loadCupons();
           
    }


     async function salvarCupom(event) {
        event.preventDefault();
        try {
            const req_ = await req(
              `mutation{
                    create_ticket(
                        codigo:"${cupom.codigo}"
                        value:${cupom.value}
                        expiration_date:"${cupom.expiration_date}"
                        min_value:${cupom.min_value}
                        seller_id:${cupom.seller_id}
                      )  
                  }
              `
              )
            if(req_ && req_.data.create_ticket)
                toast.success("Cupom cadastrado com sucesso!");
            else if (req_ && req_.data.errors)
                toast.error("Erro ao cadastrar cupom: "+req_.errors.message);
        } catch (error) {
            toast.error("Erro ao cadastrar cupom: ");
            handleClose();
            return;
        }
        handleClose();
        loadCupons();
        
           
    }
   
    return (
        <Card style={{padding:"1rem",marginLeft:"1.5rem",marginRight:"1.5rem"}}>
            <form
            autoComplete="off"
            onSubmit={(event) => cupom.isEdit?atualizarCupom(event):salvarCupom(event)}
            >
            <Typography
                className={classes.title}
                variant="h2"
                color="textPrimary"
            >
                Cadastrar novo cupom
            </Typography>
            <Card>
                <CardContent>
                    <Grid
                        container
                        spacing={2}
                    >
                    
                        <Grid
                            item
                            xs={12}
                            sm={2}
                        >
                            <TextField
                                fullWidth
                                label="Código"
                                autoComplete="new-password"
                                name="codigo"
                                onChange={handleChange}
                                required
                                value={cupom.codigo}
                                InputProps={{inputProps: { maxlength:8} }}
                                variant="outlined"
                                InputLabelProps={{
                                    style: { color: '#c4c4c4' },
                                }}
                            />
                        </Grid>
                        
                        <Grid
                            item
                            xs={12}
                            sm={2}
                        >
                            <TextField
                                name="expiration_date"
                                label="Data de expiracao"
                                id="date"
                                type="date"
                                defaultValue={today}
                                fullWidth
                                required
                                variant="outlined"
                                onChange={handleChange}
                                value={cupom.expiration_date.replace( new RegExp("(T.+)","gm"),"")}
                                InputProps={{inputProps: { min:today} }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                        </Grid>

                        <Grid
                            item
                            xs={6}
                            sm={2}
                        >
                            <TextField
                                fullWidth
                                type="number"
                                label="Valor Minimo da Compra"
                                name="min_value"
                                onChange={(event) => { event.target.value = event.target.value.replace('.', ''); var numbers = /^[0-9]*$/; if (event.target.value.match(numbers)) { event.target.value = event.target.value.replace(/(.)(?=(\d{2})$)/g, '$1.');handleChange(event) } }}
                                required
                                value={cupom.min_value}
                                variant="outlined"
                                InputLabelProps={{
                                    style: { color: '#c4c4c4' },
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            sm={2}
                        >
                            <TextField
                                fullWidth
                                required
                                name="numberformat"
                                label="Valor do Cupom"
                                name="value"
                                type="number"
                                onChange={(event) => { event.target.value = event.target.value.replace('.', ''); var numbers = /^[0-9]*$/; if (event.target.value.match(numbers)) { event.target.value = event.target.value.replace(/(.)(?=(\d{2})$)/g, '$1.');handleChange(event) } }}
                                value={cupom.value}
                                variant="outlined"
                                InputLabelProps={{
                                    style: { color: '#c4c4c4' },
                                }}
                            />
                        </Grid>

                        <Grid
                            item
                            xs={6}
                            sm={4}
                        >
                            <TextField
                                fullWidth
                                name="seller_id"
                                onChange={handleChange}
                                required
                                select
                                SelectProps={{ native: true }}
                                value={cupom.seller_id}
                                variant="outlined"
                                InputLabelProps={{
                                    style: { color: '#c4c4c4' },
                                }}
                            >
                                {vendedores.map((option) => (
                                    <option key={option.value} value={option.value} >
                                        {option.label}
                                    </option>
                                ))}
                            </TextField>
                        </Grid>
                        
                
                        

                        <div style={{width:"100%", flexDirection: 'row', display: 'flex',justifyContent: 'flex-end' }}>
                            <Button
                                color="secondary"
                                variant="contained"
                                style={{ color: "#fff", margin: 10 }}
                                onClick={handleClose}
                            >
                                Cancelar
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                                style={{ color: '#fff', margin: 10 }}
                            >
                                Salvar Cupom
                            </Button>
                        </div>

               
                    </Grid>
                </CardContent>
            </Card>
            </form >
        </Card>
    )
}

export default connect()(AddPromocao);