import React, { useState, useRef } from 'react';
import { Container, makeStyles } from '@material-ui/core';

import Page from 'src/components/Page';
import Zonas from './zonas';
import Bairros from './bairros';
import { formatValue } from 'src/utils/currency';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

export default function EntregaView({ permissions }) {
  const classes = useStyles();

  const [zonas, setZonas] = useState([]);

  const zonaRef = useRef();

  return (
    <Page className={classes.root} title="Entrega">
      <Container maxWidth={false}>
        <Zonas
          ref={zonaRef}
          zonas={zonas}
          setZonas={setZonas}
          formatValue={formatValue}
          permissions={permissions}
        />
        <Bairros
          zonas={zonas}
          formatValue={formatValue}
          refreshZonas={() => zonaRef?.current?.refresh()}
          permissions={permissions}
        />
      </Container>
    </Page>
  );
}
