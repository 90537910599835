import React, { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Code } from 'react-feather';
import { toast } from 'react-toastify';

import Page from 'src/components/Page';
import { req } from 'src/services/api';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  button: {
    marginTop: theme.spacing(2)
  }
}));

const Api = ({ className, permissions, ...rest }) => {
  const classes = useStyles();
  const user = useSelector(state => state.auth);

  const [token, setToken] = useState('');

  async function carregarToken() {
    try {
      const data = await req(
        `
                {
                    getApiToken
                }
                `
      );
      if (data.data) {
        setToken(data.data.getApiToken);
      } else if (data.errors) {
        toast.error('Erro ao resgatar informações de token');
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function atualizarToken() {
    try {
      const data = await req(
        `
            mutation{
              updateApiToken
            }
            `
      );

      if (data.data) {
        carregarToken();
        toast.success('Token de API atualizado com sucesso');
      } else if (data.errors) {
        toast.error('Não foi possível gerar novo token');
      }
    } catch (error) {
      alert(error);
    }
  }

  useEffect(() => {
    carregarToken();
  }, []);

  return (
    <Page className={classes.root} title="Api">
      <Container maxWidth={false}>
        {user.role === 0 && (
          <Fragment>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <Code color="#5e5e5f" style={{ margin: 10 }} />
              <Typography color="textPrimary" variant="h2">
                Api Construrapi
              </Typography>
            </div>

            <div className={classes.root}>
              <Card>
                <CardContent>
                  <Grid container spacing={1}>
                    <Typography variant="h4">Token: {token}</Typography>
                  </Grid>
                  <Grid container className={classes.button}>
                    <Button
                      onClick={atualizarToken}
                      color="primary"
                      variant="contained"
                      style={{
                        height: 55
                      }}
                    >
                      <Typography
                        style={{
                          color: '#fff',
                          display: 'flex'
                        }}
                      >
                        Gerar novo token
                      </Typography>
                    </Button>
                  </Grid>
                </CardContent>
              </Card>
            </div>
          </Fragment>
        )}
      </Container>
    </Page>
  );
};

export default Api;
