import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle
} from 'react';
import { Truck as TruckIcon, Plus as PlusIcon } from 'react-feather';
import {
  Button,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { Trash as TrashIcon } from 'react-feather';

import { req } from 'src/services/api';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2)
  },
  center: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

function Zonas({ formatValue, zonas, setZonas, permissions }, ref) {
  const classes = useStyles();

  const [novaZona, setNovaZona] = useState(null);

  useImperativeHandle(ref, () => ({
    refresh: loadZonas
  }));

  async function loadZonas() {
    try {
      const data = await req(`{ 
        readZones{
            uuid
            name
            min_value
            value    
            delivery_time
            min_value_free
        } 
        }`);
      if (data.data) {
        setZonas(data.data.readZones);
      } else if (data.errors) {
        toast.error('Não foi possível carregar os dados.');
      }
    } catch (e) {}
  }

  async function salvar() {
    if (!novaZona) return;
    try {
      const data = await req(`
        mutation{ 
            createZone( 
              name: "${novaZona.name}"
        ) 
       }`);
      if (data.data) {
        const uuid = data.data.createZone;
        setZonas([...zonas, { ...novaZona, uuid }]);
        setNovaZona(null);
      } else if (data.errors) {
        toast.error('Erro ao criar nova zona.');
      }
    } catch (e) {
      toast.error('Erro ao criar nova zona.');
    }
  }

  async function excluir(uuid) {
    try {
      const data = await req(`mutation{
        deleteZone(uuid: "${uuid}")
      }`);
      if (data.data) {
        setZonas(zonas.filter(zona => zona.uuid !== uuid));
      } else if (data.errors) {
        toast.error('Erro ao excluir zona.');
      }
    } catch (e) {
      toast.error('Erro ao excluir zona.');
    }
  }

  function onChange(event, uuid, prop) {
    setZonas(
      zonas.map(zona =>
        zona.uuid !== uuid
          ? zona
          : {
              ...zona,
              [prop]: ['min_value', 'value'].includes(prop)
                ? formatValue(event.target.value)
                : event.target.value,
              dirty: true
            }
      )
    );
  }

  async function atualizar(uuid) {
    const zona = zonas.find(zona => zona.uuid === uuid);
    const props = Object.entries(zona).map(([key, value]) =>
      key === 'dirty' || key === 'id'
        ? ''
        : ['uuid', 'name'].includes(key)
        ? `${key}: "${value}"`
        : `${key}: ${value}`
    );
    if (zona) {
      try {
        await req(`
        mutation{ 
            updateZone( 
                ${props.join(' ')}
        ) 
        }`);
      } catch (e) {
        toast.error('Erro ao atualizar zona.');
      }
      setZonas(
        zonas.map(zona =>
          zona.uuid !== uuid ? zona : { ...zona, dirty: false }
        )
      );
    }
  }

  useEffect(() => {
    loadZonas();
  }, []);

  return (
    <div>
      <div className={classes.header}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <TruckIcon color="#5e5e5f" style={{ margin: 10 }} />
          <Typography color="textPrimary" variant="h2">
            Zonas
          </Typography>
        </div>
        {permissions?.adicionar && (
          <Button
            onClick={() => setNovaZona({ name: '', value: '', min_value: '' })}
            component="span"
            color="primary"
            variant="contained"
          >
            <Typography
              style={{
                color: '#fff',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              align="center"
            >
              <PlusIcon style={{ paddingRight: 3 }} />
              Novo
            </Typography>
          </Button>
        )}
      </div>
      <Card>
        <CardContent>
          {zonas.map(zona => (
            <Grid
              key={`${zona.uuid}`}
              container
              spacing={5}
              style={{ alignItems: 'center' }}
            >
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  label="Zona"
                  variant="outlined"
                  name="name"
                  value={zona.name}
                  disabled={true}
                  InputLabelProps={{
                    style: { color: '#f18750' }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  fullWidth
                  label="Valor"
                  variant="outlined"
                  name="value"
                  disabled={true}
                  value={zona.value}
                  InputLabelProps={{
                    style: { color: '#f18750' }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"> R$ </InputAdornment>
                    )
                  }}
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  fullWidth
                  label="Valor mínimo"
                  variant="outlined"
                  name="min_value"
                  disabled={true}
                  value={zona.min_value}
                  InputLabelProps={{
                    style: { color: '#f18750' }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"> R$ </InputAdornment>
                    )
                  }}
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  fullWidth
                  label="Compra mínima grátis"
                  variant="outlined"
                  name="min_value_free"
                  disabled={true}
                  value={zona.min_value_free}
                  InputLabelProps={{
                    style: { color: '#f18750' }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"> R$ </InputAdornment>
                    )
                  }}
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  fullWidth
                  label="Tempo de entrega"
                  variant="outlined"
                  name="tempo"
                  placeholder="0"
                  disabled={true}
                  value={zona.delivery_time}
                  InputLabelProps={{
                    style: { color: '#f18750' }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end"> horas </InputAdornment>
                    )
                  }}
                  type="number"
                />
              </Grid>
            </Grid>
          ))}
          {novaZona && (
            <Grid container spacing={5} style={{ alignItems: 'center' }}>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  label="Zona"
                  variant="outlined"
                  name="name"
                  value={novaZona.name}
                  onChange={event =>
                    setNovaZona({ ...novaZona, name: event.target.value })
                  }
                  InputLabelProps={{
                    style: { color: '#f18750' }
                  }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={3}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start'
                }}
              >
                <Button
                  onClick={salvar}
                  component="span"
                  color="primary"
                  variant="outline"
                  style={{ height: 55, width: '30%' }}
                  disabled={novaZona.name.length === 0}
                >
                  <Typography
                    style={{
                      color: '#f18750',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                    align="center"
                  >
                    Salvar
                  </Typography>
                </Button>
                <button
                  style={{
                    backgroundColor: '#fff',
                    borderWidth: 0,
                    marginLeft: 10
                  }}
                  onClick={() => setNovaZona(null)}
                >
                  <TrashIcon
                    style={{
                      cursor: 'pointer',
                      color: '#E83B3C',
                      fontSize: 24
                    }}
                  />
                </button>
              </Grid>
            </Grid>
          )}
          {zonas.length === 0 && !novaZona ? (
            <div className={classes.center}>
              <Typography>Não existem zonas cadastradas!</Typography>
            </div>
          ) : null}
        </CardContent>
      </Card>
    </div>
  );
}

export default forwardRef(Zonas);
