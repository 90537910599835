import React, { useState, useEffect } from 'react';
import { Key as KeyIcon } from 'react-feather';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  ListItemText,
  makeStyles,
  Typography
} from '@material-ui/core';
import { toast } from 'react-toastify';

import Page from 'src/components/Page';
import { req } from 'src/services/api';
import { formatPermissions } from 'src/utils/permissions';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  list: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: theme.spacing(3)
  },
  subList: {},
  item: {
    marginRight: theme.spacing(5),
    marginBottom: theme.spacing(3)
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2)
  }
}));

const getLabel = name => {
  switch (name) {
    case 'produtos':
      return 'Produtos';
    case 'exibirAba':
      return 'Exibir aba';
    case 'exibirBotao':
      return 'Exibir botão';
    case 'adicionar':
      return 'Adicionar';
    case 'editar':
      return 'Editar';
    case 'excluir':
      return 'Excluir';
    case 'categoriasApp':
      return 'Categorias (app)';
    case 'editarValorDeEntrega':
      return 'Editar valor de entrega';
    case 'editarLimite':
      return 'Editar limite';
    case 'editarMensagemDeAlerta':
      return 'Editar mensagem de alerta';
    case 'editarValorDeCompraMinima':
      return 'Editar valor de compra mínima';
    case 'editarTempoDeExpiracaoPix':
      return 'Editar tempo de expiração do pix';
    case 'editarDescontoPix':
      return 'Editar desconto pix';
    case 'editarLimiteDescontoPix':
      return 'Editar limite de desconto pix';
    case 'carrinho':
      return 'Carrinho';
    case 'orcamentos':
      return 'Orçamentos';
    case 'faq':
      return 'FAQ';
    case 'informacoesAdicionais':
      return 'Informações adicionais';
    case 'editarPrazoAtual':
      return 'Editar prazo atual';
    case 'usuarios':
      return 'Usuários';
    case 'ativarUsuario':
      return 'Ativar usuário';
    case 'faturar':
      return 'Faturar';
    case 'contato':
      return 'Contato';
    case 'editarTelefone':
      return 'Editar telefone';
    case 'editarEmail':
      return 'Editar email';
    case 'editarEndereco':
      return 'Editar endereço';
    case 'editarLinksMapa':
      return 'Editar links para mapas';
    case 'cupons':
      return 'Cupons';
    case 'vendedores':
      return 'Vendedores';
    case 'downloadRelatorio':
      return 'Download relatório';
    case 'promocoes':
      return 'Promoções';
    case 'pedidos':
      return 'Pedidos';
    case 'marcas':
      return 'Marcas';
    case 'categoriasSite':
      return 'Categorias (site)';
    case 'alterarStatus':
      return 'Alterar status';
    case 'entrega':
      return 'Entrega';
    default:
      return '';
  }
};

export default function Permissions() {
  const classes = useStyles();

  const [items, setItems] = useState(null);

  function onToggleCheckbox(parent, self) {
    setItems({
      ...items,
      [parent]: { ...items[parent], [self]: !items[parent][self] }
    });
  }

  async function loadPermissions() {
    try {
      const data = await req(
        `{
          getPermissions(user: "2"){
              produtosExibirAba
              produtosAdicionar
              produtosEditar
              produtosExcluir
              categoriasAppExibirAba
              categoriasAppAdicionar
              categoriasAppEditar
              categoriasAppExcluir
              categoriasSiteExibirBotao
              categoriasSiteAdicionar
              categoriasSiteExcluir
              marcasExibirAba
              marcasAdicionar
              marcasEditar
              marcasExcluir
              pedidosExibirAba
              pedidosAlterarStatus
              promocoesExibirAba
              promocoesAdicionar
              promocoesExcluir
              vendedoresExibirAba
              vendedoresAdicionar
              vendedoresEditar
              vendedoresExcluir
              vendedoresDownloadRelatorio
              cuponsExibirAba
              cuponsAdicionar
              cuponsEditar
              cuponsExcluir
              contatoExibirAba
              contatoEditarTelefone
              contatoEditarEmail
              contatoEditarEndereco
              contatoEditarLinksMapa
              usuariosExibirAba
              usuariosAtivarUsuario
              informacoesAdicionaisExibirAba
              informacoesAdicionaisEditarPrazoAtual
              faqExibirAba
              faqAdicionar
              faqEditar
              faqExcluir
              orcamentosExibirAba
              orcamentosAdicionar
              orcamentosExcluir
              orcamentosFaturar
              carrinhoExibirAba
              carrinhoEditarValorDeEntrega
              carrinhoEditarLimite
              carrinhoEditarMensagemDeAlerta
              carrinhoEditarValorDeCompraMinima
              carrinhoEditarTempoDeExpiracaoPix
              carrinhoEditarDescontoPix
              carrinhoEditarLimiteDescontoPix
              entregaExibirAba
              entregaAdicionar
              entregaEditar
              }
          }`
      );
      if (data?.data) {
        setItems(formatPermissions(data?.data?.getPermissions));
      }
    } catch (err) {}
  }

  async function updatePermissions() {
    const input = {};

    Object.entries(items).forEach(([item, values]) => {
      Object.entries(values).forEach(([subitem, value]) => {
        input[
          item + subitem.charAt(0).toUpperCase() + subitem.slice(1)
        ] = value;
      });
    });

    //const delay = ms => new Promise(res => setTimeout(res, ms));
    try {
      const data = await req(
        `
            mutation{
              updatePermissions(
                input: ${JSON.stringify(input).replace(/(['"])/g, '')}
                user: "2"
              )
            }
          `
      );
      if (data?.data) {
        toast.success('Permissões atualizadas com sucesso!');
        //await delay(500);
      } else if (data?.errors) {
        toast.error('Erro ao atualizar permissões');
      }
    } catch (e) {
      toast.error('Erro ao atualizar permissões');
    }
  }

  useEffect(() => {
    loadPermissions();
  }, []);

  return (
    <Page className={classes.root} title="Orçamentos">
      <Container maxWidth={false}>
        <div className={classes.header}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <KeyIcon color="#5e5e5f" style={{ margin: 10 }} />
            <Typography color="textPrimary" variant="h2">
              Permissões de usuário
            </Typography>
          </div>
          <Button
            onClick={updatePermissions}
            component="span"
            color="primary"
            variant="contained"
          >
            <Typography style={{ color: '#fff' }} align="center">
              Salvar alterações
            </Typography>
          </Button>
        </div>
        {items && (
          <Card>
            <Box minWidth={1050}>
              <div className={classes.list}>
                {Object.keys(items).map((item, index) => (
                  <div key={`item-${index}`} className={classes.item}>
                    <Typography color="textPrimary" variant="h4">
                      {getLabel(item)}
                    </Typography>
                    <div className={classes.subList}>
                      {Object.entries(items[item]).map(
                        ([subitem, value], idx) => (
                          <div key={`subitem-${idx}`} className={classes.row}>
                            <Checkbox
                              checked={value}
                              onChange={() => onToggleCheckbox(item, subitem)}
                              style={{
                                color: '#f18750'
                              }}
                            />
                            <ListItemText>{getLabel(subitem)}</ListItemText>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </Box>
          </Card>
        )}
      </Container>
    </Page>
  );
}
