import React, { useEffect, useState } from 'react';
import {
  Container,
  Modal,
  Typography,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button
} from '@material-ui/core';
import { req } from 'src/services/api';
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  container: {
    background: '#fff',
    padding: 20,
    width: 300,
    borderRadius: 5
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginTop: 14,
    marginBottom: 14,
    justifyContent: 'space-between'
  },
  rowItem: {
    backgroundColor: '#fff',
    marginBottom: theme.spacing(1),
    width: '100%'
  },
  actions: {
    marginTop: 10,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

export default function ModalFaturamento({
  visible,
  pedido,
  onClose,
  atualizarLista
}) {
  const classes = useStyles();

  const [vendedor, setVendedor] = useState(null);
  const [vendedores, setVendedores] = useState([]);

  async function carregarVendedores() {
    const data = await req(
      `{
            readSellers{
                nome
              }
          }`
    );
    if (data?.data) {
      setVendedores(data.data.readSellers);
    }
  }

  async function onConfirm() {
    const data = await req(`
        mutation{ faturarPedido(uuid: "${pedido.uuid}", vendedor: "${vendedor}") }
    `);
    if (data?.data) {
      toast.success('Pedido faturado com sucesso!');
      atualizarLista();
      onClose();
    }
  }

  useEffect(() => {
    carregarVendedores();
  }, []);

  return (
    <Modal open={visible} onClose={onClose} className={classes.modal}>
      <Container className={classes.container}>
        <Typography color="textPrimary" variant="h4">
          Faturar pedido
        </Typography>
        <div className={classes.row}>
          <FormControl variant="outlined" className={classes.rowItem}>
            <InputLabel id="vendedor">Vendedor</InputLabel>
            <Select
              labelId="vendedor"
              value={vendedor}
              label="Vendedor"
              onChange={event => setVendedor(event.target.value)}
            >
              {vendedores.map(vendedor => (
                <MenuItem value={vendedor.nome}>{vendedor.nome}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={classes.actions}>
          <Button
            component="span"
            color="primary"
            onClick={onConfirm}
            variant="outlined"
            disabled={!vendedor}
          >
            <Typography style={{ color: '#f18750' }}>Confirmar</Typography>
          </Button>
          <Button
            component="span"
            color="secondary"
            onClick={onClose}
            variant="contained"
            disabled={false}
            style={{ marginLeft: 20 }}
          >
            <Typography style={{ color: '#fff' }}>Cancelar</Typography>
          </Button>
        </div>
      </Container>
    </Modal>
  );
}
