import React, { useEffect, useState } from 'react';
import {
  Container,
  makeStyles,
  Typography,
  Button,
  CircularProgress
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';

import Page from 'src/components/Page';
import { req } from 'src/services/api';

import OrcamentoFaturado from './OrcamentoFaturado';
import OrcamentoAberto from './OrcamentoAberto';
import ModalFaturamento from './ModalFaturamento';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    background: '#fff',
    width: '100%'
  },
  fullDiv: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  faturarButton: {
    width: 250,
    height: 40,
    [theme.breakpoints.down('sm')]: {
      height: 60
    }
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 24
  }
}));

const delay = ms => new Promise(res => setTimeout(res, ms));

const NovoOrcamento = ({ permissions }) => {
  const classes = useStyles();

  const uuid = useParams().uuid;

  const [orcamento, setOrcamento] = useState();
  const [bairros, setBairros] = useState();
  const [zonas, setZonas] = useState();
  const [descontoPix, setDescontoPix] = useState();
  const [limiteDescontoExtra, setLimiteDescontoExtra] = useState();
  const [loading, setLoading] = useState();
  const [loadingFaturamento, setLoadingFaturamento] = useState();

  const [modalFaturamentoVisible, setModalFaturamentoVisible] = useState(false);

  async function carregarDadosIniciais() {
    setLoading(true);
    try {
      const data = await req(`
        {
          readBudget(
                uuid: "${uuid}"
            ){
              uuid
              codigo
              nome_vendedor
              nome_cliente
              desconto
              status
              createdAt
              updatedAt
              frete
              min_value
              min_value_free
              desconto_extra
              aplicar_frete
              aplicar_desconto_extra
              observacoes
              tipo_entrega
              bairro
              zona
              dataDeFaturamento
              valorPago
              formaDePagamento
              banco
              statusDeEntrega
              emissaoNota
              tipoDeCompra
              credito
              valorDeCredito
              budget_products{
                budget_product {
                  uuid_produto
                  codigo_produto
                  nome_formal_produto
                  marca_produto
                  valor_unitario_produto
                  unidade
                  quantidade_pedida_produto
                  desconto
                  desconto_pix
                }
                url_imagem_produto
                quantidade_estoque
              }
            }
            readDistricts{
              uuid
              name
              value
              zone_id
              min_value_free
            }
            readZones{
              uuid
              name
              value
              min_value_free
            }
            getPixPromotion{
              valor
            }
            getDescontoExtra{
              limite
            }
        }
        `);
      if (data.data) {
        setOrcamento(data.data.readBudget);
        setBairros(data.data.readDistricts);
        setZonas(data.data.readZones);
        setDescontoPix(data.data.getPixPromotion.valor);
        setLimiteDescontoExtra(data.data.getDescontoExtra?.limite);
      } else if (data.errors) {
        toast.error(data.errors[0].message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function faturarOrcamento(
    valorPago,
    formaDePagamento,
    banco,
    tipoDeCompra
  ) {
    setModalFaturamentoVisible(false);
    setLoadingFaturamento(true);

    try {
      const faturamento = await req(
        `
            mutation{
              invoiceBudget(
                    uuid: "${uuid}" 
                    banco: ${banco}
                    valorPago: ${Number(valorPago)}
                    tipoDeCompra: ${tipoDeCompra}
                    formaDePagamento: ${formaDePagamento}
                  )
            }
            `
      );

      if (faturamento.data) {
        await delay(500);
        toast.success('Orçamento faturado com sucesso');
        carregarDadosIniciais();
      } else if (faturamento.errors) {
        toast.error(faturamento.errors[0].message, { autoClose: false });
      }
      setLoadingFaturamento(false);
    } catch (error) {
      toast.error('Erro ao faturar orçamento');
      setLoadingFaturamento(false);
    }
  }

  useEffect(() => {
    carregarDadosIniciais();
  }, []);

  return (
    <Page className={classes.root} title="Orçamento">
      {loading ? (
        <div className={classes.fullDiv}>
          <Typography variant="h4" color="textPrimary" align="center">
            Carregando...
          </Typography>
        </div>
      ) : orcamento ? (
        <div>
          <Container className={classes.header} maxWidth={false}>
            <Typography color="textPrimary" variant="h3">
              Orçamento Nº {orcamento.codigo.toUpperCase()} (
              {orcamento?.status ? 'Faturado' : 'Aberto'})
            </Typography>
            {!orcamento?.status && permissions?.faturar && (
              <Button
                component="span"
                color="primary"
                onClick={() => setModalFaturamentoVisible(true)}
                variant="contained"
                className={classes.faturarButton}
              >
                {loadingFaturamento ? (
                  <CircularProgress style={{ color: '#fff' }} size={20} />
                ) : (
                  <Typography style={{ color: '#fff' }} align="center">
                    Faturar orçamento
                  </Typography>
                )}
              </Button>
            )}
          </Container>

          {orcamento?.status ? (
            <OrcamentoFaturado
              orcamento={orcamento}
              descontoPix={descontoPix}
              refreshOrcamento={carregarDadosIniciais}
              limiteDescontoExtra={limiteDescontoExtra}
              zona={zonas?.find(zona => zona.uuid === orcamento.zona)?.name}
              bairro={
                orcamento.bairro === '0'
                  ? 'Outro'
                  : bairros?.find(bairro => bairro.uuid === orcamento.bairro)
                      ?.name
              }
            />
          ) : (
            <OrcamentoAberto
              zonas={zonas}
              bairros={bairros}
              orcamento={orcamento}
              descontoPix={descontoPix}
              refreshOrcamento={carregarDadosIniciais}
              limiteDescontoExtra={limiteDescontoExtra}
            />
          )}
        </div>
      ) : (
        <div className={classes.fullDiv}>
          <Typography variant="h4" color="textPrimary" align="center">
            Orçamento não encontrado
          </Typography>
        </div>
      )}
      <ModalFaturamento
        onConfirm={faturarOrcamento}
        visible={modalFaturamentoVisible}
        onClose={() => setModalFaturamentoVisible(false)}
      />
    </Page>
  );
};

export default NovoOrcamento;
