import React, { useState, useEffect } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  CircularProgress,
  makeStyles,
  Typography,
  FormLabel,
  FormGroup
} from '@material-ui/core';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import { toast } from 'react-toastify';
import { req } from '../../services/api';
import MUIDataTable from 'mui-datatables';
import { startOfMonth, endOfMonth, format } from 'date-fns';
import './dados.css';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    padding: '1.5rem',
    overflowX: 'scroll',
    scrollbarWidth: '13px'
  }
}));

const CustomFilter = props => {
  const { filterList, onFilterUpdate } = props;

  const handleInputChange = (event, filterIndex) => {
    const newFilterList = filterList.slice();
    newFilterList[filterIndex] = event.target.value;
    onFilterUpdate(filterIndex, event.target.value);
  };

  return (
    <div>
      <FormControl variant="outlined">
        <InputLabel htmlFor="comparison-select">Comparação</InputLabel>
        <Select
          label="Comparação"
          value={filterList[0] || ''}
          onChange={e => handleInputChange(e, 0)}
          inputProps={{
            name: 'comparison',
            id: 'comparison-select'
          }}
        >
          <MenuItem value="">Igual</MenuItem>
          <MenuItem value="=">Igual</MenuItem>
          <MenuItem value="<">Menor</MenuItem>
          <MenuItem value=">">Maior</MenuItem>
        </Select>
      </FormControl>

      <TextField
        variant="outlined"
        label="Valor"
        type="number"
        value={filterList[1] || ''}
        onChange={e => handleInputChange(e, 1)}
      />
    </div>
  );
};

const Dados = () => {
  const currentDate = new Date(); // Data atual
  const firstDayOfMonth = startOfMonth(currentDate); // Primeiro dia do mês atual
  const lastDayOfMonth = endOfMonth(currentDate); // Último dia do mês atual
  const dateFormat = 'yyyy-MM-dd'; // Formato desejado: 'YYYY-MM-DD'
  const formattedFirstDay = format(firstDayOfMonth, dateFormat);
  const formattedLastDay = format(lastDayOfMonth, dateFormat);

  const [dados, setDados] = useState([]);
  const [reload, setReload] = useState(0);
  const [initialDate, setInitialDate] = useState(formattedFirstDay);
  const [finalDate, setFinalDate] = useState(formattedLastDay);
  const [Loading, setLoading] = useState(false);

  const options = {
    search: true,
    download: true,
    print: false,
    viewColumns: true,
    filter: true,
    filterType: 'dropdown',
    isRowSelectable: false,
    fixedSelectColumn: false,
    selectableRows: false,
    selectableRowsHeader: false,
    textLabels: {
      filter: {
        all: 'Todas as opções', // Defina o rótulo "All" como "Todos"
        reset: 'Limpar'
      }
    }
    //responsive: 'scrollMaxHeight'
  };

  const columns = [
    { name: 'codigo', label: 'Código', options: { filter: true } },
    { name: 'nome_cliente', label: 'Cliente', options: { filter: true } },
    { name: 'nome_vendedor', label: 'Vendedor', options: { filter: true } },
    { name: 'bairro', label: 'Bairro', options: { filter: true } },
    { name: 'zona', label: 'Zona', options: { filter: true } },
    {
      name: 'valorPago',
      label: 'Valor',
      options: {
        filter: true,
        filterType: 'custom',
        customFilterListOptions: {
          render: v => {
            if (v[0] && v[1]) {
              return [`Valor ${v[0]} ${v[1]}`];
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, '');
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }
            return filterList;
          }
        },
        filterOptions: {
          names: [],
          logic: (value, filters) => {
            if (filters[0] && filters[1]) {
              const comparador = filters[0];
              const valor = parseFloat(filters[1]);
              console.log(value, comparador, valor);

              if (comparador === 'maior que') {
                console.log('(comparador === "maior que"', value > valor);
                return !(value > valor);
              } else if (comparador === 'igual a') {
                console.log('((comparador === "igual a"', value === valor);
                return !(value === valor);
              } else if (comparador === 'menor que') {
                console.log('(comparador === "menor que"', value < valor);
                return !(value < valor);
              }
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel>Valor Pago</FormLabel>
              <FormGroup row>
                <FormControl style={{ width: '45%', marginRight: '5%' }}>
                  <InputLabel>Comparador</InputLabel>
                  <Select
                    labelId="comparador-label"
                    id="comparador-select"
                    value={filterList[index][0] || 1}
                    onChange={event => {
                      filterList[index][0] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                  >
                    <MenuItem value={'maior que'}>Maior</MenuItem>
                    <MenuItem value={'igual a'}>Igual</MenuItem>
                    <MenuItem value={'menor que'}>Menor</MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  label="Valor"
                  value={filterList[index][1] || ''}
                  onChange={event => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: '45%' }}
                />
              </FormGroup>
            </div>
          )
        },
        print: false
      }
    },
    { name: 'totalItens', label: 'Total de itens', options: { filter: true } },
    {
      name: 'totalProdutos',
      label: 'Total de produtos',
      options: { filter: true }
    },
    {
      name: 'dataDeCriacao',
      label: 'Data de Criação',
      options: { filter: true }
    },
    {
      name: 'dataDeFaturamento',
      label: 'Data de Faturamento',
      options: { filter: true }
    },
    {
      name: 'statusEntrega',
      label: 'Status da Entrega',
      options: { filter: true }
    },
    {
      name: 'emissaoNota',
      label: 'Emissão da Nota',
      options: { filter: true }
    },
    {
      name: 'formaDePagamento',
      label: 'Forma de Pagamento',
      options: { filter: true }
    },
    { name: 'banco', label: 'Banco', options: { filter: true } },
    {
      name: 'tipoDeCompra',
      label: 'Tipo de Compra',
      options: { filter: true }
    },
    { name: 'credito', label: 'Crédito', options: { filter: true } },
    {
      name: 'valorCredito',
      label: 'Valor de Crédito',
      options: { filter: true }
    },
    { name: 'observacoes', label: 'Observações', options: { filter: true } }
  ];
  const classes = useStyles();

  useEffect(() => {
    async function getOrders() {
      try {
        setLoading(true);
        const resp = await req(
          ` 
          {
            getCombinedData(data_inicio:"${initialDate}",data_fim:"${finalDate}"){
              codigo
              nome_cliente
              nome_vendedor
              bairro
              zona
              valorPago   
              dataDeFaturamento
              statusEntrega
              emissaoNota
              formaDePagamento
              banco
              tipoDeCompra
              credito
              valorCredito
              dataDeCriacao
              totalItens
              totalProdutos
            }
        }
        `
        );
        if (resp.data) {
          setDados(resp.data.getCombinedData);
          setLoading(false);
        } else if (resp.errors) {
          toast.error(resp.errors[0].message);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }
    getOrders();
  }, [reload]);

  return (
    <Page className={classes.root} title="Pedidos" height={'100%'}>
      <Typography color="textPrimary" variant="h2">
        Dados - Pedidos e Orçamentos
      </Typography>

      <Toolbar
        reload={reload}
        setReload={setReload}
        initialDate={initialDate}
        setInitialDate={setInitialDate}
        finalDate={finalDate}
        setFinalDate={setFinalDate}
      />

      {!Loading && dados.length > 0 ? (
        <MUIDataTable
          data={dados}
          options={options}
          columns={columns}
          className="container"
        />
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          margin={15}
        >
          <Typography
            color="textPrimary"
            variant="h4"
            style={{ marginRight: 12 }}
          >
            Carregando
          </Typography>

          <CircularProgress color="primary" size={50} />
        </Box>
      )}
    </Page>
  );
};

export default Dados;
