import React, { useState } from 'react';
import { Box, Grid, TextField, Button, Card, CardMedia, makeStyles, Typography, } from '@material-ui/core';
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Auth } from '../../../store/reducers/auth/actions';
import {store} from '../../../store'
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(5),
        backgroundColor: "white",
    },
    input: {
        display: 'none'
    },
    cardMedia: {
        height: "100%"
    },
    media: {
        height: 175
    },
    inputLabel: {
        paddingBottom: theme.spacing(1),
    },
    root: {
        display: 'flex',
        width: 151,
    },
    rootForSiteCategory: {
        width: 500,
        height: 250,
        [theme.breakpoints.down('xs')]: {
            width: 300,
            height: 150,
        },
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 151,
        height: 140,
    },
    coverForSite: {
        width: 500,
        height: 250,
        [theme.breakpoints.down('xs')]: {
            width: 300,
            height: 150,
        },
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
    },
    playIcon: {
        height: 38,
        width: 38,
    },
    buttons: {
        width: '100%',
        padding: theme.spacing(2),
    },
    selectImageButton: {
        width: 500,
        height: 250,
        [theme.breakpoints.down('xs')]: {
            width: 300,
            height: 150,
        },
    },
    containerForAppCategory: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 151,
        width: '100%',
    },
    dimessionsLabel: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
    },
}));

const AddCategoria = (props) => {

    const { loadCategorias, dispatch, handleClose, toast, isCategoryForSite } = props;
    const classes = useStyles();

    const UploadMutation = gql`
    mutation($image: Upload!,$name:String!) {
        ${isCategoryForSite ? 'create_category_site' : 'create_category'}(image: $image,name:$name) 
    }
    `;

    const [name, setName] = useState('');
    const [image, setImage] = useState('');
    const [imageFile, setImageFile] = useState();
    const [uploadFile] = useMutation(UploadMutation);

    const handleSetFile = (event) => {
        setImage(URL.createObjectURL(event.target.files[0]))
        setImageFile(event.target.files[0])
    }

    const handleCadastrarCategoria = async (e) => {
        handleClose();
        try {
            await uploadFile({
                variables: { image: imageFile, name: name },
                context: {
                    headers:{
                        "authorization": store.getState().auth.accessToken,
                        "x-refresh-token": store.getState().auth.refreshToken,
                        'Access-Control-Allow-Origin':'*'       
                    }
                },
                onCompleted: () => {},
            });
            
            setImage('')
            setImageFile('')
            setName('')
            toast.success("Categoria cadastrada com sucesso!");
            loadCategorias();
        } catch (error) {
            if(error.message === "Network error: forward is not a function"){
                dispatch(Auth({accessToken: '', refreshToken: '', nome: ''}));
                alert("Tempo de login expirado. Faça login novamente!");
                window.location.reload();
            }
            toast.error("Erro ao cadastrar categoria!");
        }

    }

    return (
        <div className={classes.container}>
            <Typography
            color="textPrimary"
            variant="h2"
            style={{paddingBottom: 20}}
          >
            Cadastrar nova categoria
          </Typography>
            <Grid
                container
                justify="left"
                spacing={5}
            >
                <Grid
                    item
                >
                    <TextField
                        fullWidth
                        autoComplete="off"
                        label="Nome"
                        name="Nome"
                        onChange={(e) => setName(e.target.value)}
                        required
                        value={name}
                        variant="outlined"
                        InputLabelProps={{
                            style: { color: 'textPrimary' },
                        }}
                    />
                </Grid>

                <Grid
                    item
                    xs={12}
                    lg={isCategoryForSite? 6 : 4}
                    md={isCategoryForSite? 8 : 6}
                >
                    <Typography
                        variant="h5"
                        color="textPrimary"
                        className={classes.inputLabel}
                    >
                        Pré-visualização
                    </Typography>
                    <Card className={isCategoryForSite ? classes.rootForSiteCategory : classes.root}
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }}
                    >
                        {image !== '' ?
                            <CardMedia
                                className={isCategoryForSite ? classes.coverForSite : classes.cover}
                                image={image}
                            />
                            :
                            <div className={!isCategoryForSite && classes.containerForAppCategory}>
                                <input
                                    accept="image/*"
                                    id="raised-button-file"
                                    className={classes.input}
                                    type="file"
                                    onChange={handleSetFile}
                                />
                                <label htmlFor="raised-button-file">
                                    <Button
                                        raised
                                        component="span"
                                        color="primary"
                                        className={isCategoryForSite && classes.selectImageButton}
                                    >
                                        Selecionar
                                    </Button>
                                </label>
                            </div>
                        }
                    </Card>
                    {isCategoryForSite &&
                        <Typography
                        variant="h6"
                        color="textPrimary"
                        className={classes.dimessionsLabel}
                        >
                            Proporções da Imagem: 2400x1200
                        </Typography>
                    }
                </Grid>


            </Grid>
            <Grid item className={classes.buttons}>
                <Box flexGrow={1} />
                <Box p={2}>
                    <Grid
                        container
                        justify="center"
                        spacing={2}
                    >
                        <Grid

                            item
                        >
                            <Button
                                color="secondary"
                                onClick={() => {
                                    handleClose()
                                }}
                            >
                                Cancelar
                    </Button>
                        </Grid>
                        <Grid

                            item
                        >
                            <Button
                                color="primary"
                                variant="contained"
                                disabled={name === '' || image === ''}
                                onClick={handleCadastrarCategoria}
                                style={{ color: '#fff' }}
                            >
                                Cadastrar
                    </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>

        </div>
    )
}

export default connect()(AddCategoria);