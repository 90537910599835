import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
  Modal,
  CircularProgress
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import CuponCard from './CupomCard';
import AddCupom from './AddCupom';
import { ImTux } from 'react-icons/im';
import { toast } from 'react-toastify';

import { req } from '../../services/api';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  categoriaCard: {
    height: '100%'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const CuponsView = ({ permissions }) => {
  const classes = useStyles();
  const [cupons, setCupons] = useState([]);
  const [cuponsSearch, setCuponsSearch] = useState([]);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [input, setInput] = useState('');
  const [page, setPage] = useState(1);

  const today = new Date().toISOString().split('T')[0];
  const [cupom, setCupom] = useState({
    codigo: '',
    value: 0,
    expiration_date: today,
    seller_id: -1,
    min_value: 0,
    isEdit: false
  });

  const carregarCupom = (
    codigo,
    value,
    expiration_date,
    seller_id,
    min_value,
    uuid,
    isEdit
  ) => {
    setCupom({
      uuid,
      codigo,
      value,
      expiration_date,
      seller_id,
      min_value,
      isEdit
    });
  };

  function handleOpen() {
    setOpenModalAdd(true);
  }

  function handleClose() {
    setOpenModalAdd(false);
    carregarCupom('', '', '', '', '', '', false);
  }

  async function loadCupons() {
    setLoading(true);
    try {
      const req_ = await req(
        `{ 
        readTickets{
          uuid
          codigo
          value
          expiration_date
          min_value
          is_valid
          seller{
            id
            nome
          }
        }
      }
      `
      );

      if (req_.data) {
        setCupons(req_.data.readTickets);
        setCuponsSearch(req_.data.readTickets);
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }

  useEffect(() => {
    loadCupons();
  }, [refresh]);

  return (
    <Page className={classes.root} title="Cupons" key={'cupom1'}>
      <Container maxWidth={false} key={'cupom2'}>
        <Modal
          open={openModalAdd}
          onClose={() => {
            setOpenModalAdd(false);
          }}
          className={classes.modal}
        >
          <AddCupom
            loadCupons={loadCupons}
            handleClose={handleClose}
            toast={toast}
            cupom={cupom}
            today={today}
            setCupom={setCupom}
          />
        </Modal>
        <Typography color="textPrimary" variant="h2">
          Cupons cadastrados
        </Typography>

        <Toolbar
          cupons={cupons}
          setCuponsSearch={setCuponsSearch}
          input={input}
          setInput={setInput}
          openModal={handleOpen}
          setRefresh={setRefresh}
          permissions={permissions}
        />

        {loading ? (
          <div
            style={{
              width: '100%',
              height: '70vh',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <CircularProgress color="primary" size={50} />
          </div>
        ) : cuponsSearch.length > 0 ? (
          <div>
            <Box mt={2}>
              <Grid container spacing={1}>
                {cuponsSearch
                  .slice((page - 1) * 9, (page - 1) * 9 + 9)
                  .map(cupom => (
                    <Grid item key={cupom.uuid} lg={4} md={4} sm={6} xs={12}>
                      <CuponCard
                        item
                        cupom={cupom}
                        loadCupons={loadCupons}
                        carregarCupom={carregarCupom}
                        handleOpen={handleOpen}
                        permissions={permissions}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Box>
            <Box mt={3} display="flex" justifyContent="center">
              <Pagination
                color="primary"
                boundaryCount={1}
                variant="outlined"
                page={page}
                onChange={(event, value) => {
                  setPage(value);
                }}
                count={Math.ceil(cuponsSearch.length / 9)}
                size="small"
              />
            </Box>
          </div>
        ) : (
          <div
            style={{
              paddingTop: 30,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <ImTux size={150} color="lightgray"></ImTux>
            <h2 style={{ paddingTop: 10, color: 'gray' }}>
              Nenhum cupom encontrado
            </h2>
          </div>
        )}
      </Container>
    </Page>
  );
};

export default CuponsView;
