import React from 'react';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {
  Card,
  CardMedia,
  CardActionArea,
  CardActions,
  makeStyles,
  Button,
  Typography
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { req } from '../../services/api';
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: 500,
    [theme.breakpoints.down('xs')]: {
      width: 300
    }
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  },
  media: {
    height: 140
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    height: '20%',
    width: '30%',

    padding: theme.spacing(3),

    [theme.breakpoints.down('xs')]: {
      height: '20%',
      width: '90%'
    }
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 0 auto'
  },
  cover: {
    width: 500,
    height: 250,
    [theme.breakpoints.down('xs')]: {
      width: 300,
      height: 150
    }
  },
  controls: {
    display: 'flex',
    alignItems: 'center'
  },
  playIcon: {
    height: 38,
    width: 38
  }
}));

const PromocaoCard = ({
  className,
  promocao,
  loadPromotions,
  canRemove,
  ...rest
}) => {
  const classes = useStyles();

  function exluirPromocao() {
    confirmAlert({
      title: `Exluir promoção?`,
      buttons: [
        {
          label: 'Sim',
          onClick: async () => {
            try {
              const delay = ms => new Promise(res => setTimeout(res, ms));
              const data = await req(
                `
                  mutation{
                    deletePromotion(uuid: "${promocao.uuid}")
                  }
                `
              );
              if (data.data) {
                toast.success('Promoção deletada com sucesso!');
                await delay(500);
                loadPromotions();
              } else if (data.errors) {
                toast.error(data.errors[0].message);
              }
            } catch (err) {
              toast.error('Erro ao deletar promoção!');
            }
          }
        },
        {
          label: 'Não',
          onClick: () => {}
        }
      ]
    });
  }

  return (
    <>
      <Card
        className={classes.root}
        style={{
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <CardActionArea>
          <a>
            <CardMedia className={classes.cover} image={promocao.url} />
          </a>
        </CardActionArea>
        <CardActions
          style={{
            justifyContent: 'space-between'
          }}
        >
          <Typography>
            <strong>Código do produto:</strong> {promocao.product.codigo}
          </Typography>
          {canRemove && (
            <Button size="small" color="primary" onClick={exluirPromocao}>
              <Delete className={classes.statsIcon} color="secondary" />
            </Button>
          )}
        </CardActions>
      </Card>
    </>
  );
};

PromocaoCard.propTypes = {
  className: PropTypes.string,
  promocao: PropTypes.object.isRequired
};

export default PromocaoCard;
