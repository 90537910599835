import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles,
  Modal,
  Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { toast } from 'react-toastify';
import { req } from '../../services/api';
import AddVendedor from './AddVendedor';
import ModalDataRelatorio from './ModalDataRelatorio';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const VendedoresListView = ({ permissions }) => {
  const [vendedores, setVendedores] = useState([]);
  const [vendedoresSearch, setVendedoresSearch] = useState([]);
  const [input, setInput] = useState('');
  const [reload, setReload] = useState(0);

  const [loading, setLoading] = useState(false);

  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(0);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalDownload, setOpenModalDownload] = useState(false);

  const [vendedor, setVendedor] = useState({
    nome: '',
    id: '',
    cpf: '',
    isEdit: false,
    uuid: ''
  });

  const carregarVendedor = (uuid, nome, cpf, id) => {
    setVendedor({
      id,
      uuid,
      nome,
      cpf,
      isEdit: true
    });
  };
  const handleChange = event => {
    setVendedor({
      ...vendedor,
      [event.target.name]: event.target.value
    });
  };

  const classes = useStyles();

  const until = 25;

  function handleOpen() {
    setOpenModalAdd(true);
  }

  function handleClose() {
    setOpenModalAdd(false);
  }

  function handleOpenDownload() {
    setOpenModalDownload(true);
  }

  function handleCloseDownload() {
    setOpenModalDownload(false);
  }

  async function getSellers() {
    setLoading(true);
    try {
      const req_ = await req(
        `{ 
        readSellers{
          id
          uuid
          nome
          cpf
          
        }
      }
      `
      );
      setVendedores(req_.data.readSellers);
      setVendedoresSearch(req_.data.readSellers);
      setVendedor({
        nome: '',
        cpf: ''
      });
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }

  useEffect(() => {
    getSellers();
  }, []);

  return (
    <>
      <Modal
        open={openModalAdd}
        onClose={() => {
          setOpenModalAdd(false);
        }}
        className={classes.modal}
      >
        <AddVendedor
          loadVendedores={getSellers}
          handleClose={handleClose}
          toast={toast}
          handleChange={handleChange}
          vendedor={vendedor}
        />
      </Modal>

      <Modal
        open={openModalDownload}
        onClose={() => {
          setOpenModalDownload(false);
        }}
        className={classes.modal}
      >
        <ModalDataRelatorio
          loadVendedores={getSellers}
          handleClose={handleCloseDownload}
          toast={toast}
          handleChange={handleChange}
          vendedor={vendedor}
        />
      </Modal>
      <Page className={classes.root} title="Vendedores">
        <Container maxWidth={'100%'}>
          <Typography color="textPrimary" variant="h2">
            Vendedores cadastrados
          </Typography>

          <Toolbar
            vendedores={vendedores || []}
            setVendedoresSearch={setVendedoresSearch}
            input={input}
            setInput={setInput}
            openModal={handleOpen}
            permissions={permissions}
          />

          <Box mt={3}>
            <Results
              vendedores={vendedoresSearch}
              loading={loading}
              limit={limit}
              setLimit={setLimit}
              page={page}
              setPage={setPage}
              carregarVendedor={carregarVendedor}
              handleOpen={handleOpen}
              loadVendedores={getSellers}
              openModal={handleOpenDownload}
              permissions={permissions}
            />
          </Box>
        </Container>
      </Page>
    </>
  );
};

export default VendedoresListView;
