import React, { useState, useEffect } from 'react';
import { req } from '../../services/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const NotificationComponent = ({ permissions }) => {
    const navigate = useNavigate();

    const fetchData = async () => {
        try {
            const resp = await req(`{ get_visualized_orders{ uuid } }`);

            if (resp.data) {
                return resp.data.get_visualized_orders;
            }

            return [];
        } catch (error) {
            // Lida com erros de requisição
            console.error(error);
        }
    };

    const handleNotificationClick = () => {
        // Redireciona para a página desejada
        if (window.location.href.indexOf('/pedidos')) {
            window.location.reload();
        } else {
            navigate('/pedidos');
        }

    };

    const playNotificationSound = () => {
        toast.info('Um novo pedido foi realizado e não foi visualizado!', {
            position: toast.POSITION.TOP_RIGHT,
            onClick: handleNotificationClick,
            autoClose: 3500, // Duração da notificação em milissegundos
            hideProgressBar: false, // Exibir ou ocultar a barra de progresso
            closeOnClick: true, // Fechar a notificação quando clicar nela
            pauseOnHover: true, // Pausar a contagem regressiva ao passar o mouse sobre a notificação
            draggable: true, // Permitir arrastar a notificação
            onOpen: () => {
                // Reproduzir o som da notificação
                const audio = new Audio('/audio/notification.mp3');
                audio.play();
            },
        });
    };

    const notificationEventHandler = async () => {
        const newOrders = await fetchData();
        const hasNewOrders = newOrders?.length > 0;
        if (hasNewOrders) {
            playNotificationSound()
        }
    }

    useEffect(() => {
        // Verifica se as notificações são suportadas pelo navegador
        if ('Notification' in window && Notification.permission !== 'granted') {
            // Solicita permissão para exibir notificações
            Notification.requestPermission();
        }

        const interval = setInterval(notificationEventHandler, 5000); // A cada 5 segundos, por exemplo

        // Limpe o intervalo quando o componente for desmontado
        return () => clearInterval(interval);
    }, []);

    return (
        <div id="notificationComponent" />
    );
};

export default NotificationComponent;
