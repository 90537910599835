import React from 'react';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useNavigate, Link } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  Button
} from '@material-ui/core';
import { Edit, Delete } from '@material-ui/icons';
import { req } from '../../services/api';
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  },
  media: {
    height: 140
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    height: '20%',
    width: '30%',

    padding: theme.spacing(3),

    [theme.breakpoints.down('xs')]: {
      height: '20%',
      width: '90%'
    }
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 0 auto'
  },
  cover: {
    width: 151,
    height: 140
  },
  controls: {
    display: 'flex',
    alignItems: 'center'
  },
  playIcon: {
    height: 38,
    width: 38
  }
}));

const FAQCard = ({ className, faq, loadFAQ, permissions, ...rest }) => {
  const classes = useStyles();
  const navigation = useNavigate();

  async function deleteFAQ() {
    const delay = ms => new Promise(res => setTimeout(res, ms));
    try {
      const data = await req(
        `
          mutation{
            deleteFAQ(
              uuid: "${faq.uuid}"
            )
          }
        `
      );

      if (data.data) {
        toast.success('FAQ deletada com sucesso!');
        await delay(500);
        loadFAQ();
      } else if (data.errors) {
        toast.error(data.errors[0].message);
      }
    } catch (error) {
      console.log(error);
      toast.error('Erro ao deletar FAQ!');
    }
  }

  function excluirFAQ() {
    confirmAlert({
      title: `Excluir FAQ ${faq.titulo}?`,
      buttons: [
        {
          label: 'Sim',
          onClick: () => deleteFAQ()
        },
        {
          label: 'Não',
          onClick: () => {}
        }
      ]
    });
  }

  function editarFAQ() {
    navigation('editar');
  }

  return (
    <>
      <Card
        className={classes.root}
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography
              component="h4"
              variant="h4"
              style={{ fontWeight: 'bold', marginBottom: 5 }}
            >
              {faq.titulo}
            </Typography>
            <Typography component="h5" variant="h5">
              {faq.resposta}
            </Typography>
          </CardContent>
          <div className={classes.controls}>
            <Box p={1} style={{ paddindLeft: 5 }}>
              <Grid container justify="center" spacing={2}>
                {permissions.excluir && (
                  <Grid className={classes.statsItem} item>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={excluirFAQ}
                    >
                      <Delete className={classes.statsIcon} />
                    </Button>
                  </Grid>
                )}
                {permissions.editar && (
                  <Grid className={classes.statsItem} item>
                    <Link to={'/faqchange'} state={faq}>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={editarFAQ}
                      >
                        <Edit
                          className={classes.statsIcon}
                          style={{
                            color: '#fff'
                          }}
                        />
                      </Button>
                    </Link>
                  </Grid>
                )}
              </Grid>
            </Box>
          </div>
        </div>
      </Card>
    </>
  );
};

FAQCard.propTypes = {
  className: PropTypes.string,
  faq: PropTypes.object.isRequired
};

export default FAQCard;
