import React from 'react';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {
  Card,
  CardActions,
  CardContent,
  makeStyles,
  Button,
  Typography
} from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { IoTicketOutline } from 'react-icons/io5';

import { req } from '../../services/api';
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: 500,
    [theme.breakpoints.down('xs')]: {
      width: 300
    }
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  },
  media: {
    height: 140
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    height: '20%',
    width: '30%',

    padding: theme.spacing(3),

    [theme.breakpoints.down('xs')]: {
      height: '20%',
      width: '90%'
    }
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 0 auto'
  },
  cover: {
    width: 500,
    height: 250,
    [theme.breakpoints.down('xs')]: {
      width: 300,
      height: 150
    }
  },
  controls: {
    display: 'flex',
    alignItems: 'center'
  },
  playIcon: {
    height: 38,
    width: 38
  }
}));

const CupomCard = ({
  className,
  cupom,
  loadCupons,
  carregarCupom,
  handleOpen,
  permissions,
  ...rest
}) => {
  const classes = useStyles();

  async function deleteCupom() {
    try {
      const data = await req(
        `
          mutation{
            deleteTicket(
              uuid: "${cupom.uuid}"
            )
          }
        `
      );

      if (data.data) {
        toast.success('Cupom deletado com sucesso!');
      } else if (data.errors) {
        toast.error(data.errors[0].message);
      }
    } catch (error) {
      console.log(error);
      toast.error('Erro ao deletar Cupom!');
    }
    loadCupons();
  }

  function excluiCupom() {
    confirmAlert({
      title: `Exluir cupom ${cupom.codigo.toUpperCase()}?`,
      buttons: [
        {
          label: 'Sim',
          onClick: () => deleteCupom()
        },
        {
          label: 'Não',
          onClick: () => {}
        }
      ]
    });
  }

  return (
    <Card
      style={{
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      <CardActions
        style={{
          justifyContent: 'space-between'
        }}
      >
        <CardContent style={{ padding: 0, paddingLeft: 3 }}>
          <IoTicketOutline
            size={'2.8rem'}
            color={cupom.is_valid ? '#F18750' : 'secondary'}
          />
        </CardContent>

        <CardContent style={{ padding: 0 }}>
          <Typography>
            <strong>Código do cupom:</strong> {cupom.codigo} {cupom.is_valid}
          </Typography>
          <Typography>
            <strong>Data de Expiração:</strong>{' '}
            {new Date(cupom.expiration_date).toLocaleDateString('pt-br', {
              timeZone: 'UTC'
            })}
          </Typography>
          <Typography>
            <strong>Valor do Cupom:</strong> R$ {cupom.value}
          </Typography>

          <Typography>
            <strong>Valor minimo:</strong> R$ {cupom.min_value}
          </Typography>

          {cupom.seller && (
            <Typography>
              <strong>Vendedor:</strong> {cupom.seller.nome}
            </Typography>
          )}
        </CardContent>

        <CardContent
          style={{ display: 'flex', flexDirection: 'column', padding: 0 }}
        >
          {permissions.editar && (
            <Button
              size="small"
              color="black"
              style={{ marginBottom: '0.5rem' }}
              onClick={() => {
                const seller_id = cupom.seller ? cupom.seller.id : '';
                handleOpen();
                carregarCupom(
                  cupom.codigo,
                  cupom.value,
                  cupom.expiration_date,
                  seller_id,
                  cupom.min_value,
                  cupom.uuid,
                  true
                );
              }}
            >
              <Edit className={classes.statsIcon} color="black" />
            </Button>
          )}
          {permissions.excluir && (
            <Button size="small" color="primary" onClick={excluiCupom}>
              <Delete className={classes.statsIcon} color={'secondary'} />
            </Button>
          )}
        </CardContent>
      </CardActions>
    </Card>
  );
};

CupomCard.propTypes = {
  className: PropTypes.string,
  cupom: PropTypes.object.isRequired
};

export default CupomCard;
