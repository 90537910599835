import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  makeStyles,
  Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import { toast } from 'react-toastify';
import { req } from '../../services/api';
import MUIDataTable from 'mui-datatables';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Logs = ({ permissions }) => {
  const [dados, setDados] = useState([]);
  const [Loading, setLoading] = useState(false);

  const options = {
    search: true,
    download: true,
    print: false,
    viewColumns: true,
    filter: true,
    filterType: 'dropdown',
    isRowSelectable: false,
    fixedSelectColumn: false,
    selectableRows: false,
    selectableRowsHeader: false
  };

  const columns = [
    { name: 'content', label: 'Linha', options: { filter: false } }
  ];
  const classes = useStyles();

  useEffect(() => {
    async function getLogs() {
      try {
        setLoading(true);
        const resp = await req(
          ` 
          {
            readLogs{
              content
            }
        }
        `
        );
        if (resp.data) {
          setDados(resp.data.readLogs);
          setLoading(false);
        } else if (resp.errors) {
          toast.error(resp.errors[0].message);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }
    getLogs();
  }, []);

  const theme = () => {
    return createMuiTheme(
      {
        overrides: {
          MuiTableCell: {
            root: {
              whiteSpace: 'normal',
            },
          },
        }
      }
    )
  }

  return (
    <Page className={classes.root} title="Pedidos">
      <Container maxWidth={false}>
        <Typography color="textPrimary" variant="h2">
          Logs da Aplicação
        </Typography>

        {!Loading && dados.length > 0 ? (
          <Box mt={3}>
            <MuiThemeProvider theme={theme}>
              <MUIDataTable
                data={dados.reverse()}
                options={options}
                columns={columns}
              />
            </MuiThemeProvider>
          </Box>
        ) : (
          <Box mt={3}>
            <CircularProgress color="primary" size={50} />
          </Box>
        )}
      </Container>
    </Page>
  );
};

export default Logs;
