import React, { useState } from 'react';
import {
    Box,
    Grid,
    Button,
    Card,
    CardMedia,
    makeStyles,
    Typography,
    TextField
} from '@material-ui/core';
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { store } from '../../store';
import { Auth } from '../../store/reducers/auth/actions';
import { connect } from 'react-redux';

const UploadMutation = gql`
mutation($image: Upload!, $codigo: String!) {
    createPromotion(image: $image, codigo: $codigo) 
}
`;

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(5),
        backgroundColor: "white",
    },
    input: {
        display: 'none',
    },
    cardMedia: {
        height: "100%"
    },
    media: {
        height: 175
    },
    inputLabel: {
        paddingBottom: theme.spacing(1),
    },
    dimessionsLabel: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
    },
    root: {
        width: 500,
        height: 250,
        [theme.breakpoints.down('xs')]: {
            width: 300,
            height: 150,
        },
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 500,
        height: 250,
        [theme.breakpoints.down('xs')]: {
            width: 300,
            height: 150,
        },
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
    },
    playIcon: {
        height: 38,
        width: 38,
    },
    buttons: {
        width: '100%',
    },
    button: {
        width: 500,
        height: 250,
        [theme.breakpoints.down('xs')]: {
            width: 300,
            height: 150,
        },
    }
}));

const AddPromocao = ({ loadPromotions, dispatch, handleClose, toast }) => {

    const classes = useStyles();

    const [image, setImage] = useState('');
    const [imageFile, setImageFile] = useState();
    const [uploadFile] = useMutation(UploadMutation);
    const [codigo, setCodigo] = useState('');

    const handleSetFile = (event) => {
        setImage(URL.createObjectURL(event.target.files[0]))
        setImageFile(event.target.files[0])
    }

    const handleCadastrarPromocao = async (e) => {
        try {
            const data = await uploadFile({
                variables: { image: imageFile, codigo: codigo },
                context: {
                    headers: {
                        "authorization": store.getState().auth.accessToken,
                        "x-refresh-token": store.getState().auth.refreshToken,
                        'Access-Control-Allow-Origin': '*'
                    }
                },
                onCompleted: () => {},
            });
            setImage('')
            setCodigo('')
            setImageFile('')
            handleClose();
            loadPromotions();
            toast.success("Promoção cadastrada com sucesso!");
        } catch (error) {
            if (error.message === "Network error: forward is not a function") {
                dispatch(Auth({ accessToken: '', refreshToken: '', nome: '' }));
                alert("Tempo de login expirado. Faça login novamente!");
            }else
            
            console.log("Erro ao cadastrar promoção!")
        }

    }

    return (
        <div className={classes.container}>
            <Typography
                color="textPrimary"
                variant="h2"
                style={{marginBottom: 10}}
            >
                Cadastrar nova promoção
            </Typography>
            <Grid
                container
                justify="left"
                spacing={5}
            >
                <Grid
                    item
                >
                    <TextField
                        fullWidth
                        autoComplete="off"
                        label="Código do produto"
                        name="codigo"
                        onChange={(e) => {setCodigo(e.target.value)}}
                        required
                        value={codigo}
                        variant="outlined"
                        InputLabelProps={{
                            style: { color: 'textPrimary' },
                        }}
                    />
                </Grid>

                <Grid
                    item
                    xs={12}
                    lg={6}
                    md={8}
                >
                    <Typography
                        variant="h5"
                        color="textPrimary"
                        className={classes.inputLabel}
                    >
                        Pré-visualização
                    </Typography>
                    <Card className={classes.root}
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }}
                    >
                        {image !== '' ?
                            <CardMedia
                                className={classes.cover}
                                image={image}
                                title=""
                            />
                            :
                            <div>
                                <input
                                    accept="image/*"
                                    id="raised-button-file"
                                    className={classes.input}
                                    type="file"
                                    onChange={handleSetFile}
                                />
                                <label htmlFor="raised-button-file">
                                    <Button
                                        raised
                                        component="span"
                                        color="primary"
                                        className={classes.button}
                                    >
                                        Selecionar imagem
                                    </Button>
                                </label>
                            </div>
                        }
                    </Card>
                    
                    <Typography
                        variant="h6"
                        color="textPrimary"

                        className={classes.dimessionsLabel}
                    >
                       Proporções da Imagem: 500x250, 700x350, 1000x500.
                    </Typography>

                </Grid>


            </Grid>
            <Grid item className={classes.buttons}>
                <Box flexGrow={1} />
                <Box p={2}>
                    <Grid
                        container
                        justify="center"
                        spacing={2}
                    >
                        <Grid
                            item
                        >
                            <Button
                                color="secondary"
                                onClick={handleClose}
                            >
                                Cancelar
                            </Button>
                        </Grid>
                        <Grid
                            item
                        >
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={handleCadastrarPromocao}
                                style={{ color: '#fff' }}
                                disabled={image === '' || codigo === ''}
                            >
                                Cadastrar
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>

        </div>
    )
}

export default connect()(AddPromocao);