import React, { Component } from 'react';
import { req } from 'src/services/api';
import { store } from '../store';
import { formatPermissions } from '../utils/permissions';

export function withPermissions() {
  return function(BaseComponent) {
    return class extends Component {
      constructor(props) {
        super(props);
        this.state = {
          permissions: null,
          firstPage: 'produtos',
          error: null
        };
        this.getPermissions = this.getPermissions.bind(this);
      }

      componentDidMount() {
        if (store.getState().auth.accessToken) {
          this.getPermissions();
        }
      }

      async getPermissions() {
        try {
          const data = await req(
            `{
              getPermissions(user: "${store.getState().auth.role}"){
                  produtosExibirAba
                  produtosAdicionar
                  produtosEditar
                  produtosExcluir
                  categoriasAppExibirAba
                  categoriasAppAdicionar
                  categoriasAppEditar
                  categoriasAppExcluir
                  categoriasSiteExibirBotao
                  categoriasSiteAdicionar
                  categoriasSiteExcluir
                  marcasExibirAba
                  marcasAdicionar
                  marcasEditar
                  marcasExcluir
                  pedidosExibirAba
                  pedidosAlterarStatus
                  promocoesExibirAba
                  promocoesAdicionar
                  promocoesExcluir
                  vendedoresExibirAba
                  vendedoresAdicionar
                  vendedoresEditar
                  vendedoresExcluir
                  vendedoresDownloadRelatorio
                  cuponsExibirAba
                  cuponsAdicionar
                  cuponsEditar
                  cuponsExcluir
                  carrinhoExibirAba
                  carrinhoEditarValorDeEntrega
                  carrinhoEditarLimite
                  carrinhoEditarMensagemDeAlerta
                  carrinhoEditarValorDeCompraMinima
                  carrinhoEditarTempoDeExpiracaoPix
                  carrinhoEditarDescontoPix
                  carrinhoEditarLimiteDescontoPix
                  contatoExibirAba
                  contatoEditarTelefone
                  contatoEditarEmail
                  contatoEditarEndereco
                  contatoEditarLinksMapa
                  usuariosExibirAba
                  usuariosAtivarUsuario
                  informacoesAdicionaisExibirAba
                  informacoesAdicionaisEditarPrazoAtual
                  faqExibirAba
                  faqAdicionar
                  faqEditar
                  faqExcluir
                  orcamentosExibirAba
                  orcamentosAdicionar
                  orcamentosExcluir
                  orcamentosFaturar
                  entregaExibirAba
                  entregaAdicionar
                  entregaEditar
                  }
              }`
          );
          if (data?.data) {
            const permissions = data?.data?.getPermissions;
            const [first] = Object.entries(permissions).find(
              ([item, value]) => item.includes('ExibirAba') && value === true
            );

            this.setState({
              permissions: formatPermissions(permissions),
              firstPage: first.slice(0, first.indexOf('ExibirAba'))
            });
          }
        } catch (err) {
          this.setState({ ...this.state, error: true });
        }
      }

      render() {
        if (this.state.error) {
          return <p>Um erro inesperado ocorreu!</p>;
        }
        if (store.getState().auth.accessToken && !this.state.permissions) {
          return <p>carregando...</p>;
        }
        return (
          <BaseComponent
            {...this.props}
            auth={store.getState().auth}
            firstPage={this.state.firstPage}
            permissions={this.state.permissions}
            refreshPermissions={() => this.getPermissions()}
          />
        );
      }
    };
  };
}
