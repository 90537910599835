import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { Download as DownloadIcon } from 'react-feather';

import { req } from 'src/services/api';
import exportToExcel from 'src/utils/excel';

export default function ExportUsers({ filter }) {
  const [loading, setLoading] = useState(false);

  const formatUserAddress = addresses => {
    var address = addresses.filter(address => address.is_atual === 1);

    if (address.length === 0) return 'Sem endereço de entrega escolhido';

    return (
      address[0].cep +
      ' | ' +
      address[0].bairro +
      ', ' +
      address[0].logradouro +
      ' ' +
      address[0].numero +
      (address[0].complemento ? ', ' + address[0].complemento : '') +
      ' | ' +
      address[0].cidade +
      '-' +
      address[0].uf
    );
  };

  async function getUsers() {
    setLoading(true);
    try {
      const data = await req(`
        {
          get_app_users(
            filter: "${filter}"
          ){
            data {
              uuid
              nome
              cpf
              inscricao
              active
              email
              telefone
              address {
                cep
                uf
                cidade
                bairro
                logradouro
                numero
                complemento
                is_atual
              }
            }
            total
          }
        }
      `);
      if (data?.data) {
        const users = data.data.get_app_users.data.map(user => ({
          Nome: user.nome,
          CPF_CNPJ: user.cpf,
          Inscricao_estadual: user.inscricao === '' ? 'Sem inscrição estadual' : user.inscricao,
          Email: user.email,
          Telefone: user.telefone,
          Endereço: user.address.length === 0 ? 'Sem endereço cadastrado' : formatUserAddress(user.address),
          Ativo: user.active ? 'Sim' : 'Não'
        }));
        exportToExcel(users, `usuarios-${new Date().toLocaleString('pt-BR')}`, "Usuarios");
      } else if (data.errors) {
        alert(data.errors);
      }
    } catch (err) {
      alert(err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Button
      color="primary"
      variant="contained"
      style={{ marginRight: 15, height: 50 }}
      onClick={getUsers}
      disabled={loading}
    >
      {loading ? 'Carregando...' : <DownloadIcon color="white" />}
    </Button>
  );
}