import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import ProdutosList from './ProdutosList';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  Typography,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import { req } from 'src/services/api';
import { savePDF } from '@progress/kendo-react-pdf';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiFormLabel-root': {
      color: 'orange',
      fontWeight: 'bold'
    },
    '& .MuiCardHeader-subheader': {
      color: 'orange',
      fontWeight: 'bold'
    },
    '& .MuiOutlinedInput-input': {
      color: 'gray'
    }
  },
  card: {
    width: '100%'
  }
}));

const ProfileDetails = ({ className, uuid, permissions, ...rest }) => {
  const classes = useStyles();
  const [values, setValues] = useState(null);
  const [produtos, setProdutos] = useState([]);
  const [IsConverting, setConverting] = useState(false);
  const [paymentMethodDetails, setPaymentMethodDetails] = useState({});
  const [credit_card_last_digits, setCreditCard] = useState('');
  const [tempoEntrega, setTempoEntrega] = useState();
  const [order, setOrder] = useState(null);
  const [statusInterno, setStatusInterno] = useState('');
  const refPageToPdf = useRef(null);

  const [states, setStates] = useState([]);

  async function convertePageToPDF() {
    await setConverting(true);
    let element = refPageToPdf.current || document.body;
    savePDF(element, {
      scale: 0.5,
      margin: 2,
      paperSize: [595, 845],
      fileName: `Pedido_${order.user.nome}_${order.uuid}`
    });
    setConverting(false);
  }

  async function atualizarStatusInterno(value) {
    if (statusInterno === value) return;
    try {
      const data = await req(
        `
          mutation{
            updateOrder(uuid: "${order.uuid}", fields:{
              status_interno: "${statusInterno}"
            })
          }
          `
      );
      if (data?.errors) {
        toast.error('Não foi possível salvar as observações');
        setStatusInterno(value);
      }
    } catch (err) {}
  }

  async function loadOrder() {
    try {
      const data = await req(
        `
          {
            getOrderById(uuid: "${uuid}")
            {
              uuid
              status
              frete
              valor_total
              parcelas
              data_pedido
              previsao_entrega
              observacao
              status_interno
              deliveryType
              desconto_pix
              desconto_extra
              vendedor
              dataDeFaturamento
              faturado
              emissaoNota
              paymentMethod
              {
                type
                uuid_payment_method
              }
              ordered_products 
              {
                uuid
                nome_formal
                codigo_produto
                marca_produto
                preco_compra
                quantidade_pedida
              }
              user
              {
                nome
                cpf
                telefone
              }
              paymentMethod{
                type
              }
              cep
              cidade
              bairro
              zona
              logradouro
              numero
              complemento
              nome_destinatario
              ticket{
                value
                codigo
              }
            }
          }
        `
      );
      if (data?.data) {
        const currentOrder = data.data.getOrderById;
        setOrder(currentOrder);
        setValues(currentOrder);
        setTempoEntrega(currentOrder.previsao_entrega);
        setProdutos(currentOrder.ordered_products);
        setStatusInterno(currentOrder.status_interno);
        setStates([
          {
            value: 1,
            label: 'Aguardando pagamento'
          },
          {
            value: 2,
            label:
              currentOrder.deliveryType == 1
                ? 'Preparando Para a Entrega'
                : 'Separando os produtos'
          },
          {
            value: 3,
            label:
              currentOrder.deliveryType == 1
                ? 'Saiu para Entrega'
                : 'Pronto para retirada'
          },
          {
            value: 4,
            label: 'Entregue'
          },
          {
            value: 5,
            label: 'Cancelado'
          }
        ]);
        loadPaymentMethodDetails(currentOrder);
      } else if (data?.errors) {
        toast.error(data.errors[0].message);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function loadPaymentMethodDetails(order) {
    if (order.paymentMethod.type === 2) {
      try {
        const data = await req(
          `
           {
              credit_card(uuid: "${order.paymentMethod.uuid_payment_method}"){
                  card_last_digits,
                  card_brand
              }              
          }
          `
        );
        if (data?.data) {
          setPaymentMethodDetails(data.data.credit_card);
          const credit_card_aux = data.data.credit_card;
          const c_lenth = credit_card_aux.card_last_digits.length;
          setCreditCard(
            credit_card_aux.card_last_digits.slice(c_lenth - 4, c_lenth)
          );
        } else if (data.errors) {
          toast.error(data.errors[0].message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  useEffect(() => {
    if (uuid) loadOrder();
  }, []);

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleChangeTime = event => {
    setTempoEntrega(event.target.value);
  };

  const alterar_status = () => {
    confirmAlert({
      title: 'Confirme para enviar',
      message: 'Você tem certeza dessa alteração ?',
      buttons: [
        {
          label: 'Sim',
          onClick: async () => {
            if (values.status === '3' && order.deliveryType == 1) {
              try {
                const data = await req(
                  `
                    mutation{
                      updateOrder(uuid: "${order.uuid}", fields:{
                        status: ${values.status}
                        data_envio: "${new Date().toISOString()}"
                        previsao_entrega: "${tempoEntrega}"
                      })
                    }
                    `
                );
                if (data.data) {
                  toast.success('Status atualizado com sucesso!');
                } else if (data.errors) {
                  toast.error(data.errors[0].message);
                }
              } catch (err) {
                console.log(err);
              }
            } else if (values.status == '2' && order.deliveryType == 2) {
              try {
                const data = await req(
                  `
                    mutation{
                      updateOrder(uuid: "${order.uuid}", fields:{
                        status: ${values.status}
                        previsao_entrega: "${tempoEntrega}"
                      })
                    }
                    `
                );
                if (data.data) {
                  toast.success('Status atualizado com sucesso!');
                } else if (data.errors) {
                  toast.error(data.errors[0].message);
                }
              } catch (err) {
                console.log(err);
              }
            } else if (values.status === '4') {
              try {
                const data = await req(
                  `
                    mutation{
                      updateOrder(uuid: "${order.uuid}", fields:{
                        status: ${values.status}
                        data_entrega: "${new Date().toISOString()}"
                      })
                    }
                    `
                );
                if (data.data) {
                  toast.success('Status atualizado com sucesso!');
                } else if (data.errors) {
                  toast.error(data.errors[0].message);
                }
              } catch (err) {
                console.log(err);
              }
            } else if (values.status === '5') {
              try {
                const data = await req(
                  `
                    mutation{
                      cancel_order(uuid: "${order.uuid}")
                    }
                    `
                );
                if (data.data) {
                  toast.success('Status atualizado com sucesso!');
                } else if (data.errors) {
                  toast.error(data.errors[0].message);
                }
              } catch (err) {
                toast.error('Houve um erro ao cancelar');
              }
            } else {
              try {
                const data = await req(
                  `
                    mutation{
                      updateOrder(uuid: "${order.uuid}", fields:{
                        status: ${values.status}
                      })
                    }
                    `
                );
                if (data.data) {
                  toast.success('Status atualizado com sucesso!');
                } else if (data.errors) {
                  toast.error(data.errors[0].message);
                }
              } catch (err) {
                console.log(err);
              }
            }
          }
        },
        {
          label: 'Não',
          onClick: () => {}
        }
      ]
    });
  };

  async function handleEmissaoNota() {
    const data = await req(`
      mutation{ updateOrder(uuid: "${
        order.uuid
      }", fields: { emissaoNota: ${!order.emissaoNota} }) }
    `);
    if (data.data) {
      loadOrder();
    }
  }

  return (
    <>
      {order !== null && values !== null ? (
        <form
          autoComplete="off"
          noValidate
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card className={classes.card} id="divToPrint" ref={refPageToPdf}>
            {order.faturado && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <CardHeader
                  title={`Pedido faturado em ${new Date(
                    parseInt(order.dataDeFaturamento)
                  ).toLocaleDateString()} às ${new Date(
                    parseInt(order.dataDeFaturamento)
                  )
                    .toLocaleTimeString()
                    .slice(0, 5)} por ${order.vendedor}.`}
                />
                <FormControlLabel
                  disabled={!permissions.alterarStatus}
                  control={
                    <Checkbox
                      checked={order.emissaoNota}
                      onChange={handleEmissaoNota}
                      style={{
                        color: '#f18750'
                      }}
                    />
                  }
                  label="Emissão de nota"
                />
              </div>
            )}
            <Divider />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <CardHeader
                title={
                  'Código do Pedido: ' + order.uuid.slice(30, 36).toUpperCase()
                }
                subheader={new Date(
                  parseInt(order.data_pedido, 10)
                ).toLocaleString('pt-BR')}
              />
              <Button
                style={{
                  marginRight: '1rem',
                  color: 'white',
                  height: '2.5rem',
                  display: IsConverting ? 'none' : 'flex'
                }}
                color="primary"
                variant="contained"
                onClick={convertePageToPDF}
              >
                Download PDF
              </Button>
            </div>

            <Divider />

            <CardContent>
              <Grid container spacing={3}>
                <Grid md={8} xs={6} style={{ marginLeft: '12px' }}>
                  <Typography style={{ fontWeight: 'bold' }}>
                    Dados do Cliente:
                  </Typography>
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Nome"
                    name="name"
                    value={order.user.nome}
                    disabled
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="CPF/CNPJ"
                    name="cpf/cnpj"
                    disabled
                    value={order.user.cpf}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Telefone"
                    name="phone"
                    disabled
                    value={order.user.telefone}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Inscrição estadual"
                    name="inscription"
                    disabled
                    value={
                      order.user.cpf.length > 14
                        ? order.user.inscricao
                        : 'Cliente sem inscrição estadual'
                    }
                    variant="outlined"
                  />
                </Grid>
                <Grid md={8} xs={6} style={{ marginLeft: '12px' }}>
                  <Typography style={{ fontWeight: 'bold' }}>
                    Endereço:
                  </Typography>
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Destinatário"
                    name="addressee"
                    disabled
                    value={order.nome_destinatario}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={3} xs={6}>
                  <TextField
                    fullWidth
                    label="CEP"
                    name="addressee"
                    disabled
                    value={order.cep}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={3} xs={6}>
                  <TextField
                    fullWidth
                    label="Cidade"
                    name="city"
                    disabled
                    value={order.cidade}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Bairro"
                    name="neighborhood"
                    disabled
                    value={order.bairro}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Zona"
                    name="zone"
                    disabled
                    value={order.zona}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Logradouro"
                    name="patio"
                    disabled
                    value={
                      order.logradouro +
                      (order.numero ? ', n° ' + order.numero : '')
                    }
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Complemento"
                    name="complement"
                    disabled
                    value={order.complemento}
                    variant="outlined"
                  />
                </Grid>
                <Grid md={8} xs={6} style={{ marginLeft: '12px' }}>
                  <Typography style={{ fontWeight: 'bold' }}>
                    Pedido:
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Forma de pagamento"
                    name="paymentMethod"
                    disabled
                    value={
                      order.paymentMethod.type === 1
                        ? 'Pix'
                        : order.paymentMethod.type === 2
                        ? 'Cartão de Crédito'
                        : 'Finalizado com vendedor'
                    }
                    variant="outlined"
                  />
                </Grid>
                {order.paymentMethod.type === 2 && (
                  <Grid item md={3} xs={12}>
                    <TextField
                      fullWidth
                      label="Bandeira"
                      name="paymentMethodDetailsBrandCard"
                      disabled
                      value={`${paymentMethodDetails.card_brand}`}
                      variant="outlined"
                    />
                  </Grid>
                )}
                {order.paymentMethod.type === 2 && (
                  <Grid item md={3} xs={12}>
                    <TextField
                      fullWidth
                      label="Nº do cartão"
                      name="paymentMethodDetailsCardNumber"
                      disabled
                      value={`${'•••• '.toString() + credit_card_last_digits}`}
                      variant="outlined"
                    />
                  </Grid>
                )}
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Opção de pagamento"
                    name="paymentOption"
                    disabled
                    value={`${order.parcelas} x ${(
                      order.valor_total / order.parcelas
                    ).toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL'
                    })}`}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Tipo de entrega"
                    name="deliveryType"
                    disabled
                    value={
                      order.deliveryType == 1
                        ? 'Entrega pela Constru.rapi'
                        : 'Retirada pelo usuário'
                    }
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Observações"
                    name="obs"
                    disabled
                    value={
                      order.observacao
                        ? order.observacao
                        : 'Pedido sem observações'
                    }
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    name="status"
                    onChange={handleChange}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={values.status}
                    variant="outlined"
                  >
                    {states.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                {((values.status == '3' && order.deliveryType == 1) ||
                  (values.status == '2' && order.deliveryType == 2)) && (
                  <Grid item md={6} xs={12}>
                    <TextField
                      id="datetime-local"
                      label="Previsão"
                      type="time"
                      value={tempoEntrega}
                      onChange={handleChangeTime}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                )}
                {permissions.alterarStatus && (
                  <Grid item>
                    <Button
                      style={{
                        marginTop: 8,
                        color: 'white',
                        display: IsConverting ? 'none' : 'flex'
                      }}
                      color="primary"
                      variant="contained"
                      disabled={
                        (values.status === '3' &&
                          tempoEntrega === '' &&
                          order.deliveryType == 1) ||
                        (values.status === '2' &&
                          tempoEntrega === '' &&
                          order.deliveryType == 2)
                          ? true
                          : false
                      }
                      onClick={event => alterar_status()}
                    >
                      Atualizar Status
                    </Button>
                  </Grid>
                )}
                <Grid md={8} xs={6} style={{ marginLeft: '12px' }}>
                  <Typography style={{ fontWeight: 'bold' }}>
                    Observações (status interno):
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12} className={classes.row}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    rowsMax={4}
                    label="Observações"
                    name="status_interno"
                    value={statusInterno}
                    variant="outlined"
                    onChange={e => setStatusInterno(e.target.value)}
                    onBlur={() => atualizarStatusInterno(order.status_interno)}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />

            <Divider />

            <div
              style={{
                display: 'flex',
                alignItems: 'left',
                paddingTop: 10,
                paddingLeft: '2rem',
                paddingRight: 20,
                flexDirection: 'column'
              }}
            >
              <Typography style={{ marginBottom: '1rem' }}>
                <b> Produtos:</b>
              </Typography>

              <ProdutosList
                ordered_products={produtos}
                reduz={IsConverting}
                frete={order.frete}
                desconto_pix={order.desconto_pix}
                ticket={order.ticket}
                desconto_extra={order.desconto_extra}
                valor_total={order.valor_total}
              />
            </div>
          </Card>
        </form>
      ) : (
        <></>
      )}
    </>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string
};

export default ProfileDetails;
