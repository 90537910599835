import React, { useEffect, useState } from 'react';
import Page from 'src/components/Page';
import { Users as UsersIcon } from 'react-feather';
import {
  Box,
  Card,
  CircularProgress,
  Container,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Checkbox,
  TablePagination
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { req } from 'src/services/api';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import Toolbar from './Toolbar';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  title: {
    paddingBottom: theme.spacing(1),
    marginLeft: theme.spacing(2)
  },
  input: {
    display: 'none'
  },
  inputLabel: {
    paddingBottom: theme.spacing(1)
  }
}));

const Usuarios = ({ permissions }) => {
  const [usuarios, setUsuarios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [input, setInput] = useState('');
  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  const classes = useStyles();

  const handleLimitChange = event => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const activeUser = async user => {
    try {
      const data = await req(
        `
                mutation{
                activeUser(
                    uuid: "${user.uuid}"
                )
                }
            `
      );

      if (data.data) {
        toast.success('Usuário ativado com sucesso!');
      } else if (data.errors) {
        toast.error(data.errors[0].message);
      }
    } catch (error) {
      console.log(error);
      toast.error('Erro ao ativar usuário!');
    } finally {
      setLoading(true);
      setTimeout(loadUsers, 500);
    }
  };

  const handleActive = user => {
    confirmAlert({
      title: `Ativar usuário ${user.nome.toUpperCase()}?`,
      buttons: [
        {
          label: 'Sim',
          onClick: () => {
            activeUser(user);
          }
        },
        {
          label: 'Não',
          onClick: () => {}
        }
      ]
    });
  };

  const formatUserAddress = addresses => {
    var address = addresses.filter(address => address.is_atual == 1);

    if (address.length == 0) return 'Sem endereço de entrega escolhido';

    return (
      address[0].cep +
      ' | ' +
      address[0].bairro +
      ', ' +
      address[0].logradouro +
      ' ' +
      address[0].numero +
      ', ' +
      address[0].complemento +
      ' | ' +
      address[0].cidade +
      '-' +
      address[0].uf
    );
  };

  const formatUserList = users => {
    var formatedUser = {};
    var formatedUsers = [];

    users.forEach(user => {
      formatedUser = {
        ...user,
        uuid: user.uuid,
        nome: user.nome,
        cpf: user.cpf,
        inscricao:
          user.inscricao == '' ? 'Sem inscrição estadual' : user.inscricao,
        address:
          user.address.length == 0
            ? 'Sem endereço cadastrado'
            : formatUserAddress(user.address),
        active: user.active
      };

      formatedUsers.push(formatedUser);
    });

    return formatedUsers;
  };

  const loadUsers = async () => {
    try {
      const users = await req(
        `
                {
                    get_app_users(
                        skip: ${page * limit}
                        take: ${limit}
                        filter: "${input}"
                    ){
                        data {
                            uuid
                            nome
                            cpf
                            inscricao
                            active
                            email
                            telefone
                            address {
                                cep
                                uf
                                cidade
                                bairro
                                logradouro
                                numero
                                complemento
                                is_atual
                            }
                        }
                        total
                    }
                }
                `
      );

      if (users.data) {
        var result = formatUserList(users.data.get_app_users.data);
        setUsuarios(result);
        setTotal(users.data.get_app_users.total);
      } else if (users.errors) {
        toast.error(users.errors[0].message);
      }
    } catch (error) {
      console.log(error);
      toast.error('Erro ao resgatar os usuários');
    } finally {
      setLoading(false);
    }
  };

  function handleSearch() {
    setPage(0);
    loadUsers();
  }

  useEffect(() => {
    loadUsers();
  }, [page, limit]);

  return (
    <Page className={classes.root} title="Usuários">
      <Container maxWidth={false}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 24
          }}
        >
          <UsersIcon color="#5e5e5f" style={{ margin: 10 }} />
          <Typography color="textPrimary" variant="h2">
            Usuários
          </Typography>
        </div>
        <Toolbar input={input} setInput={setInput} onClick={handleSearch} />
        <Card>
          <PerfectScrollbar>
            <Box minWidth={1050}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" width="15%">
                      Nome
                    </TableCell>
                    <TableCell align="center" width="10%">
                      CPF/CNPJ
                    </TableCell>
                    <TableCell align="center" width="10%">
                      Inscrição estadual
                    </TableCell>
                    <TableCell align="center" width="15%">
                      E-mail
                    </TableCell>
                    <TableCell align="center" width="10%">
                      Telefone
                    </TableCell>
                    <TableCell align="center" width="30%">
                      Endereço
                    </TableCell>
                    <TableCell align="center" width="10%">
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow hover key="key">
                      <TableCell colSpan={7} align="center">
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center'
                          }}
                        >
                          <CircularProgress color="primary" size={20} />
                          <Typography style={{ marginLeft: 10 }}>
                            Carregando...
                          </Typography>
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : usuarios.length > 0 ? (
                    usuarios.map(usuario => (
                      <TableRow hover key={usuario.uuid}>
                        <TableCell align="center" width="15%">
                          {usuario.nome}
                        </TableCell>
                        <TableCell align="center" width="10%">
                          {usuario.cpf}
                        </TableCell>
                        <TableCell align="center" width="10%">
                          {usuario.inscricao}
                        </TableCell>
                        <TableCell align="center" width="15%">
                          {usuario.email || '-'}
                        </TableCell>
                        <TableCell align="center" width="10%">
                          {usuario.telefone || '-'}
                        </TableCell>
                        <TableCell align="center" width="30%">
                          {usuario.address}
                        </TableCell>
                        <TableCell align="center" width="10%">
                          <Checkbox
                            disabled={
                              usuario.active || !permissions.ativarUsuario
                            }
                            onChange={() => handleActive(usuario)}
                            checked={usuario.active}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow hover key="key">
                      <TableCell colSpan={7} align="center">
                        Nenhum usuário encontrado
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            count={total}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[15, 25, 50, 100]}
          />
        </Card>
      </Container>
    </Page>
  );
};

export default Usuarios;
