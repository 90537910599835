import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
  Modal,
  Button,
  CircularProgress
} from '@material-ui/core';
import Page from 'src/components/Page';
import AddPromocao from './AddPromocao';
import PromocaoCard from './PromocaoCard';
import { req } from '../../services/api';
import { ImSad2 } from 'react-icons/im';
import { toast } from 'react-toastify';
import { Plus as PlusIcon } from 'react-feather';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  categoriaCard: {
    height: '100%'
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 10
    }
  }
}));

const PromotionView = ({ permissions }) => {
  const classes = useStyles();

  const [promocoes, setPromocoes] = useState([]);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [loading, setLoading] = useState(false);

  function handleOpen() {
    setOpenModalAdd(true);
  }

  function handleClose() {
    setOpenModalAdd(false);
  }

  async function loadPromotions() {
    setLoading(true);
    try {
      const data = await req(
        `
        {
          readPromotions{
            uuid
            url
            product{
              codigo
            }
          }
        }
        `
      );
      if (data.data) {
        setPromocoes(data.data.readPromotions);
      } else {
        alert(data.errors);
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }

  useEffect(() => {
    loadPromotions();
  }, []);

  return (
    <Page className={classes.root} title="Promoções">
      <Container width="95%">
        <Modal
          open={openModalAdd}
          onClose={() => {
            setOpenModalAdd(false);
          }}
          className={classes.modal}
        >
          <AddPromocao
            loadPromotions={loadPromotions}
            handleClose={handleClose}
            toast={toast}
          />
        </Modal>
        <Box mt={3} className={classes.container}>
          <Typography
            color="textPrimary"
            variant="h2"
            className={classes.title}
          >
            Promoções cadastradas
          </Typography>
          {permissions.adicionar && (
            <Button
              onClick={handleOpen}
              color="primary"
              variant="contained"
              style={{
                height: 50
              }}
            >
              <Typography
                style={{
                  color: '#fff',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <PlusIcon style={{ paddingRight: 3 }} />
                Adicionar nova promoção
              </Typography>
            </Button>
          )}
        </Box>
        {loading ? (
          <div
            style={{
              width: '100%',
              height: '70vh',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <CircularProgress color="primary" size={50} />
          </div>
        ) : promocoes.length > 0 ? (
          <Grid container spacing={2} style={{ marginTop: 15 }}>
            {promocoes.map(promocao => (
              <Grid item key={promocao.uuid} xs={6} sm={6}>
                <PromocaoCard
                  className={classes.promocaoCard}
                  promocao={promocao}
                  loadPromotions={loadPromotions}
                  canRemove={permissions.excluir}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <div
            style={{
              paddingTop: 30,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <ImSad2 size={150} color="lightgray"></ImSad2>
            <h2 style={{ paddingTop: 10, color: 'gray' }}>
              Não há promoções cadastradas
            </h2>
          </div>
        )}
      </Container>
    </Page>
  );
};

export default PromotionView;
