import React, { useState, useMemo, Fragment, useEffect } from 'react';
import {
  Button,
  Card,
  CardContent,
  Container,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Download as DownloadIcon, Mail as MailIcon } from 'react-feather';
import { savePDF } from '@progress/kendo-react-pdf';
import { FaWhatsapp, FaMinusCircle } from 'react-icons/fa';

import { formatCurrency } from 'src/utils/currency';

import './styles.css';

const useStyles = makeStyles(theme => ({
  root: {
    background: '#fff',
    marginTop: theme.spacing(2)
  },
  card: {
    minHeight: '90%',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: 1200
    }
  },
  warning: {
    color: 'red'
  },
  warningContainer: {
    marginBottom: theme.spacing(2)
  },
  statusText: {
    color: 'red',
    marginTop: 20
  }
}));

const columns = [
  'Produto',
  'Código',
  'Nome',
  'Marca',
  'Quant.',
  'Unidade',
  'Valor a prazo',
  'Valor a vista',
  'Total a prazo',
  'Total a vista',
  'Desconto (%)',
  'Desconto'
];

const obterSomaPorClassName = classNames => {
  const res = [];
  for (const className of classNames) {
    let sum = 0;
    let values = document.getElementsByClassName(className);
    for (let i = 0; i < values.length; i++) {
      sum += parseFloat(Number(values[i].attributes[1].value).toFixed(2));
    }
    res.push(sum);
  }
  return res;
};

const ImageCell = ({
  status,
  uuid_produto,
  url_imagem_produto,
  removerProduto
}) => {
  const [style, setStyle] = useState({ display: 'none' });
  return (
    <td
      onMouseEnter={() => {
        setStyle({ display: 'block' });
      }}
      onMouseLeave={() => {
        setStyle({ display: 'none' });
      }}
      style={{ position: 'relative' }}
    >
      {!status && (
        <FaMinusCircle
          onClick={() => removerProduto(uuid_produto)}
          style={{
            cursor: 'pointer',
            color: '#E83B3C',
            fontSize: 16,
            position: 'absolute',
            top: 8,
            right: 8,
            ...style
          }}
        />
      )}
      <img
        style={{ cursor: 'pointer' }}
        onClick={() => window.open(url_imagem_produto)}
        src={url_imagem_produto}
        className="items-img"
      />
    </td>
  );
};

const QuantidadeCell = ({
  uuid,
  status,
  desconto,
  alterarQuantidadeDesconto,
  quantidade_pedida_produto
}) => {
  const [quantidade, setQuantidade] = useState(quantidade_pedida_produto);
  return (
    <td>
      <input
        type="number"
        onBlur={() =>
          quantidade !== quantidade_pedida_produto
            ? alterarQuantidadeDesconto(uuid, quantidade, desconto)
            : null
        }
        disabled={status}
        style={{ backgroundColor: 'transparent' }}
        onChange={e => setQuantidade(e.target.value)}
        value={quantidade}
      />
    </td>
  );
};

const DescontoCell = ({
  uuid,
  status,
  desconto_produto,
  alterarQuantidadeDesconto,
  quantidade_pedida_produto
}) => {
  const [desconto, setDesconto] = useState(desconto_produto);
  return (
    <td className="input-adornment" data-placeholder="%">
      <input
        type="number"
        onBlur={() =>
          desconto_produto !== desconto
            ? alterarQuantidadeDesconto(
                uuid,
                quantidade_pedida_produto,
                desconto
              )
            : null
        }
        disabled={status}
        style={{ backgroundColor: 'transparent' }}
        onChange={e =>
          e.target.value <= 100 ? setDesconto(e.target.value) : null
        }
        value={desconto}
      />
    </td>
  );
};

const Preview = ({
  orcamento,
  descontoPix,
  limiteDescontoExtra,
  removerProduto,
  alterarQuantidadeDesconto
}) => {
  const [date, setDate] = useState('DD/MM/AAAA');
  const [hour, setHour] = useState('HH:MM');
  const [alert, setAlert] = useState(true);
  const [pageLoaded, setPageLoaded] = useState(false);

  const classes = useStyles();

  const totalItens = useMemo(() =>
    orcamento.budget_products
      ?.map(product => product.budget_product.quantidade_pedida_produto)
      .reduce((a, b) => Number(a) + Number(b), 0)
  );

  const [totalAVista, totalAPrazo, totalDeDesconto] = useMemo(
    () =>
      obterSomaPorClassName([
        'produto-valor-total-vista',
        'produto-valor-total-prazo',
        'produto-valor-total-desconto'
      ]),
    [pageLoaded]
  );

  const quantoFaltaParaFrete = useMemo(
    () =>
      totalAPrazo < orcamento.min_value
        ? orcamento.min_value - totalAPrazo
        : null,
    [totalAPrazo]
  );

  const quantoFaltaParaDescontoExtra = useMemo(
    () =>
      totalAPrazo < limiteDescontoExtra
        ? limiteDescontoExtra - totalAPrazo
        : null,
    [totalAPrazo]
  );

  function calculaValorAVistaProduto(produto) {
    if (produto.desconto > 0)
      return {
        valor:
          produto.valor_unitario_produto -
          parseFloat(
            ((produto.valor_unitario_produto * produto.desconto) / 100).toFixed(
              2
            )
          ),
        mensagem: `Desconto produto aplicado: ${produto.desconto}%`,
        desconto: produto.desconto
      };
    if (produto.desconto_pix > 0 || descontoPix > 0) {
      if (produto.desconto_pix > descontoPix) {
        return {
          valor:
            produto.valor_unitario_produto -
            parseFloat(
              (
                (produto.valor_unitario_produto * produto.desconto_pix) /
                100
              ).toFixed(2)
            ),
          mensagem: `Desconto produto pix aplicado: ${produto.desconto_pix}%`,
          desconto: produto.desconto_pix
        };
      }

      return {
        valor:
          produto.valor_unitario_produto -
          parseFloat(
            ((produto.valor_unitario_produto * descontoPix) / 100).toFixed(2)
          ),
        mensagem: `Desconto pix aplicado: ${descontoPix}%`,
        desconto: descontoPix
      };
    }

    // if (orcamento.desconto > 0) {
    //   return {
    //     valor:
    //       produto.valor_unitario_produto -
    //       (produto.valor_unitario_produto * orcamento.desconto) / 100,
    //     mensagem: `Desconto total aplicado: ${orcamento.desconto}%`
    //   };
    // }

    return {
      valor: produto.valor_unitario_produto,
      mensagem: 'Nenhum desconto aplicado'
    };
  }

  function converterParaPDF() {
    savePDF(document.getElementById('orcamento-preview'), {
      scale: 0.7,
      margin: 2,
      paperSize: [1100, 595],
      landscape: true,
      fileName: `orcamento-${orcamento.codigo.toLowerCase()}${
        orcamento.nome_cliente
          ? '-' +
            orcamento.nome_cliente
              .split(' ')[0]
              .toLowerCase()
              .trim()
          : ''
      }`
    });
  }

  function valorAVista(product) {
    const res = calculaValorAVistaProduto(product.budget_product);
    return <td title={res.mensagem}>{formatCurrency(res.valor)}</td>;
  }

  useEffect(() => {
    const onPageLoad = () => {
      setPageLoaded(true);
    };

    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  return (
    <Container maxWidth={false} className={classes.root}>
      <div className={classes.warningContainer}>
        {orcamento.tipo_entrega === 1 && (
          <Typography className={classes.warning}>
            {quantoFaltaParaFrete
              ? `Faltam ${formatCurrency(
                  quantoFaltaParaFrete
                )} para atingir o valor
            mínimo para este endereço.`
              : 'Limite mínimo para entrega no endereço atingido.'}
          </Typography>
        )}

        {!orcamento.status && (
          <Typography className={classes.warning}>
            {quantoFaltaParaDescontoExtra
              ? `Faltam ${formatCurrency(
                  quantoFaltaParaDescontoExtra
                )} para atingir o
              valor mínimo para desconto extra.`
              : 'Limite mínimo para desconto extra antigido.'}
          </Typography>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 10
        }}
      >
        <Typography color="textPrimary" variant="h4">
          Pré-visualização
        </Typography>
        <Button
          component="span"
          color="primary"
          onClick={() => {
            setDate(new Date().toLocaleDateString());
            setHour(new Date().toLocaleTimeString().slice(0, 5));
            setAlert(false);
            setTimeout(() => {
              converterParaPDF();
            }, 1500);
            setTimeout(() => {
              setAlert(true);
            }, 3000);
          }}
          variant="outlined"
        >
          <Typography
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
              fontSize: 16,
              color: '#f18750'
            }}
            color="textPrimary"
            variant="h2"
          >
            Baixar <DownloadIcon size={16} style={{ marginLeft: 8 }} />
          </Typography>
        </Button>
      </div>
      <div style={{ overflow: 'scroll' }}>
        <Card id="orcamento-preview" className={classes.card}>
          <CardContent>
            <div>
              <div>
                <div className="title-container">
                  <img
                    className="logo"
                    src="/static/images/logo/logo.png"
                    style={{
                      width: 50,
                      marginTop: 5
                    }}
                  />
                  <div className="title">
                    <p>M & W MATERIAIS DE CONSTRUÇÃO LTDA / CONSTRURAPI</p>
                    <p>
                      Endereço: Rua Agostinho Alves, 1905 - Fátima, Teresina/PI
                      - CEP 64.049-478
                    </p>
                    <p>CNPJ: 42.782.370/0001-78</p>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 10
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center'
                        }}
                      >
                        <MailIcon size={16} style={{ marginRight: 8 }} />
                        <span>contato@construrapi.com</span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center'
                        }}
                      >
                        <FaWhatsapp size={16} style={{ marginRight: 6 }} />
                        <span>(86) 8114-6400</span>
                      </div>
                    </div>
                  </div>
                  {orcamento.statusDeEntrega === 2 && (
                    <Typography className={classes.statusText} variant="h2">
                      Retirada
                    </Typography>
                  )}
                  {orcamento.statusDeEntrega === 3 && (
                    <Typography className={classes.statusText} variant="h2">
                      Entrega
                    </Typography>
                  )}
                </div>
                <br />
                <hr />
                <br />
              </div>
            </div>
            <div className="orcamento-info">
              <div>
                <p>
                  <strong>Código do orçamento: </strong>
                  {orcamento.codigo}
                </p>
                {orcamento.nome_cliente?.length > 0 && (
                  <p>
                    <strong>Referente a:</strong> {orcamento.nome_cliente}
                  </p>
                )}
                {orcamento.nome_vendedor && orcamento.nome_vendedor !== 'null' && (
                  <p>
                    <strong>Vendedor:</strong> {orcamento.nome_vendedor}
                  </p>
                )}
              </div>
              <p>
                {orcamento.status && !!orcamento.dataDeFaturamento
                  ? `Faturado em ${new Date(
                      parseInt(orcamento.dataDeFaturamento)
                    ).toLocaleDateString()} às ${new Date(
                      parseInt(orcamento.dataDeFaturamento)
                    )
                      .toLocaleTimeString()
                      .slice(0, 5)}`
                  : `Emitido em ${date} às ${hour}`}
              </p>
            </div>
            <table className="table-preview">
              <thead>
                <tr>
                  <th className="table-title" colSpan={columns.length}>
                    Lista de materiais
                  </th>
                </tr>
                <tr style={{ background: '#fff' }}>
                  {columns.map(value => (
                    <th>{value}</th>
                  ))}
                </tr>
              </thead>
              <tbody className="background">
                {(orcamento.budget_products || []).map(product => (
                  <tr
                    title={
                      product.budget_product.quantidade_pedida_produto >
                        product.quantidade_estoque && !orcamento.status
                        ? `Quantidade do produto em estoque é ${product.quantidade_estoque}`
                        : ''
                    }
                    key={product.uuid_produto}
                    style={{
                      color:
                        product.budget_product.quantidade_pedida_produto >
                          product.quantidade_estoque &&
                        !orcamento.status &&
                        alert
                          ? '#E83B3C'
                          : '#000000'
                    }}
                  >
                    <ImageCell
                      status={orcamento.status}
                      removerProduto={removerProduto}
                      url_imagem_produto={product.url_imagem_produto}
                      uuid_produto={product.budget_product.uuid_produto}
                    />
                    <td>{product.budget_product.codigo_produto}</td>
                    <td>{product.budget_product.nome_formal_produto}</td>
                    <td>{product.budget_product.marca_produto}</td>
                    <QuantidadeCell
                      uuid={product.budget_product.uuid_produto}
                      status={orcamento.status}
                      alterarQuantidadeDesconto={alterarQuantidadeDesconto}
                      desconto={product.budget_product.desconto}
                      quantidade_pedida_produto={
                        product.budget_product.quantidade_pedida_produto
                      }
                    />
                    <td>{product.budget_product.unidade}</td>
                    <td>
                      {formatCurrency(
                        product.budget_product.valor_unitario_produto
                      )}
                    </td>
                    {valorAVista(product)}
                    <td
                      className="produto-valor-total-prazo"
                      value={(
                        product.budget_product.quantidade_pedida_produto *
                        product.budget_product.valor_unitario_produto
                      ).toFixed(2)}
                    >
                      {formatCurrency(
                        product.budget_product.quantidade_pedida_produto *
                          product.budget_product.valor_unitario_produto
                      )}
                    </td>

                    <td
                      className="produto-valor-total-vista"
                      value={(
                        product.budget_product.quantidade_pedida_produto *
                        calculaValorAVistaProduto(product.budget_product).valor
                      ).toFixed(2)}
                    >
                      {formatCurrency(
                        product.budget_product.quantidade_pedida_produto *
                          calculaValorAVistaProduto(product.budget_product)
                            .valor
                      )}
                    </td>
                    <td>
                      <DescontoCell
                        uuid={product.budget_product.uuid_produto}
                        status={orcamento.status}
                        desconto_produto={
                          calculaValorAVistaProduto(product.budget_product)
                            .desconto
                        }
                        alterarQuantidadeDesconto={alterarQuantidadeDesconto}
                        quantidade_pedida_produto={
                          product.budget_product.quantidade_pedida_produto
                        }
                      />
                    </td>
                    <td
                      style={{ color: '#E83B3C' }}
                      className="produto-valor-total-desconto"
                      value={(
                        product.budget_product.quantidade_pedida_produto *
                        (product.budget_product.valor_unitario_produto -
                          calculaValorAVistaProduto(product.budget_product)
                            .valor)
                      ).toFixed(2)}
                    >
                      {formatCurrency(
                        product.budget_product.quantidade_pedida_produto *
                          (product.budget_product.valor_unitario_produto -
                            calculaValorAVistaProduto(product.budget_product)
                              .valor)
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                {/* {!temDesconto && (
                  <tr className="total-column">
                    {[...Array(6).keys()].map(() => (
                      <td></td>
                    ))}

                    <td>Subtotal</td>
                    <td className="text-subtotal">
                      {formatCurrency(totalPrazo)}
                    </td>
                  </tr>
                )} */}

                <tr className="total-column">
                  <td colSpan={9}>
                    <span style={{ fontWeight: 'bold' }}>
                      Total de produtos:
                    </span>{' '}
                    {orcamento?.budget_products?.length}
                  </td>
                  {orcamento.tipo_entrega === 0 ? (
                    <td>Retirada na loja</td>
                  ) : (
                    <>
                      <td>Entrega</td>
                      <td
                        style={{
                          color: orcamento.frete == 0 ? '#32cd32' : ''
                        }}
                      >
                        {orcamento.frete == 0
                          ? 'Grátis'
                          : formatCurrency(orcamento.frete)}
                      </td>
                    </>
                  )}
                </tr>

                <tr className="total-column">
                  <td colSpan={9}>
                    <span style={{ fontWeight: 'bold' }}>Total de itens:</span>{' '}
                    {totalItens}
                  </td>
                  <td>Total a prazo</td>
                  <td className="text-subtotal">
                    {formatCurrency(totalAPrazo + orcamento.frete)}
                  </td>
                </tr>

                <tr className="total-column">
                  {[...Array(9).keys()].map(() => (
                    <td></td>
                  ))}

                  <td>Desconto pix</td>
                  <td className="text-subtotal" style={{ color: '#E83B3C' }}>
                    - {formatCurrency(totalDeDesconto)}
                  </td>
                </tr>
                {orcamento.tipo_entrega === 1 && (
                  <tr className="total-column">
                    {[...Array(9).keys()].map(() => (
                      <td></td>
                    ))}

                    <td
                      style={{
                        borderBottomWidth: 2,
                        borderBottomStyle: orcamento.aplicar_desconto_extra
                          ? 'solid'
                          : 'none'
                      }}
                    >
                      <strong>Total a vista entrega</strong>{' '}
                    </td>
                    <td
                      style={{
                        borderBottomWidth: 2,
                        borderBottomStyle: orcamento.aplicar_desconto_extra
                          ? 'solid'
                          : 'none'
                      }}
                      className="text-subtotal"
                    >
                      <strong>
                        {formatCurrency(totalAVista + orcamento.frete)}
                      </strong>
                    </td>
                  </tr>
                )}
                {orcamento.aplicar_desconto_extra ? (
                  <tr className="total-column">
                    {[...Array(9).keys()].map(() => (
                      <td></td>
                    ))}

                    <td>
                      <p>Desconto extra</p>{' '}
                    </td>
                    <td className="text-subtotal">
                      <p>- {formatCurrency(orcamento.desconto_extra)}</p>
                    </td>
                  </tr>
                ) : null}
                {(orcamento.aplicar_desconto_extra ||
                  orcamento.tipo_entrega === 0) && (
                  <Fragment>
                    <tr className="total-column">
                      {[...Array(9).keys()].map(() => (
                        <td></td>
                      ))}

                      <td>
                        <strong>Total a vista retirada</strong>{' '}
                      </td>
                      <td className="text-subtotal">
                        <strong>
                          {formatCurrency(
                            totalAVista - orcamento.desconto_extra
                          )}
                        </strong>
                      </td>
                    </tr>
                  </Fragment>
                )}
              </tfoot>
            </table>
            <div className=" observacoes">
                <span>
                  *Informamos que materiais adquiridos exclusivamente de forma online têm um prazo de devolução de 7 dias a partir do recebimento. Este prazo não se aplica a compras realizadas diretamente em nossa loja física, onde não serão aceitas devoluções, conforme o Art. 49 do CDC.
                </span>
              </div>
            {orcamento.desconto > 0 ? (
              <div className=" observacoes">
                <span>
                  *Valor de desconto referente apenas a pagamentos via pix ou
                  transferência.
                </span>
              </div>
            ) : null}
            {orcamento.min_value && totalAPrazo >= orcamento.min_value ? (
              <div className="observacoes">
                <span>
                  *Em caso de pagamento via cartão de crédito, parcelamos em até
                  <strong> 6x {formatCurrency(totalAPrazo / 6)}</strong>.
                </span>
              </div>
            ) : null}

            {orcamento.tipo_entrega === 1 &&
            !orcamento.status &&
            orcamento.min_value_free > 0 ? (
              <div className="observacoes">
                <span>
                  *Entrega grátis apenas para compras acima de{' '}
                  {formatCurrency(orcamento.min_value_free)}.
                </span>
              </div>
            ) : null}
            <div className="observacoes">
              <span>
                *A disponibilidade dos produtos acima estará sujeita a alteração
                sem aviso prévio devido as vendas online através de nosso
                Aplicativo.
              </span>
            </div>
            {orcamento.observacoes?.length > 0 ? (
              <div className="observacoes">
                <span>*{orcamento.observacoes}</span>
              </div>
            ) : null}
          </CardContent>
        </Card>
      </div>
    </Container>
  );
};

export default Preview;
