import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import clsx from 'clsx';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  Typography,
  CardMedia,
} from '@material-ui/core';
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import {store} from '../../store'

const UploadMutation = gql`
  mutation($image:Upload!, $name:String!, $uuid:String!) {
    updateCategory(image:$image ,name:$name, uuid:$uuid) 
  }
  `;

const UpdateName = gql`
  mutation($name:String!, $uuid:String!) {
    updateNameCategory(name:$name, uuid:$uuid) 
  }
  `;

const useStyles = makeStyles((theme) => ({
  input: {
    display: 'none'
  },
  cardMedia: {
    height: "100%"
  },
  media: {
    height: 175
  },
  inputLabel: {
    paddingBottom: theme.spacing(1),
    color: '#FFB500'
  },
  root: {
    "& .MuiFormLabel-root": {
      color: "orange",
      fontWeight: "bold"
    },
    "& .MuiCardHeader-subheader": {
      color: "orange",
      fontWeight: "bold"
    },
    '& .MuiOutlinedInput-input': {
      color: 'gray',
    },
  },
  card: {
    display: 'flex',
    width: 151,
    height: 140
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 151,
    height: 140,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  buttons: {
    width: 351,
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  }
}));

export default function CategoriaView({ className, ...rest }) {

  const navigate = useNavigate();

  const params = useParams()

  console.log(params);

  const categoria = useLocation().state;

  const classes = useStyles();

  const [uploadFile] = useMutation(UploadMutation);

  const [updateName] = useMutation(UpdateName);

  const [image, setImage] = useState(categoria.url);

  const [ImageFile, setImageFile] = useState(false);

  const [nome, setNome] = useState(categoria.nome);

  const handleSetFile = (event) => {
    setImage(URL.createObjectURL(event.target.files[0]))
    setImageFile(event.target.files[0])
  }

  const alterar_dados = () => {
    confirmAlert({
      title: 'Confirme para enviar',
      message: 'Você tem certeza dessa alteração ?',
      buttons: [
        {
          label: 'Sim',
          onClick: async () => {
            try {
              if (ImageFile)
                await uploadFile({
                  variables: { image: ImageFile, name: nome, uuid: categoria.uuid },
                  context: {
                    headers:{
                        "authorization": store.getState().auth.accessToken,
                        "x-refresh-token": store.getState().auth.refreshToken,
                        'Access-Control-Allow-Origin':'*'       
                    }
                  },
                  onCompleted: () => { },
                });
              else
                await updateName({
                  variables: { name: nome, uuid: categoria.uuid },
                  context: {
                    headers:{
                        "authorization": store.getState().auth.accessToken,
                        "x-refresh-token": store.getState().auth.refreshToken,
                        'Access-Control-Allow-Origin':'*'       
                    }
                  },
                  onCompleted: () => { },
                });

              navigate('/categorias');
            } catch (error) {
              alert(error)
            }
          }
        },
        {
          label: 'Não',
          onClick: () => { }
        }
      ]
    });
  };

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          title={"Alterar categoria"}
        />
        <Divider />

        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="Nome"
                name="nome"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                variant="outlined"

              />
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <Typography
                className={classes.inputLabel}
              >
                Imagem
              </Typography>
              <Card className={classes.card}
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}
              >
                {image !== '' ?
                  <CardMedia
                    className={classes.cover}
                    image={image}
                    title=""
                  />
                  :
                  <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 140,
                    width: '100%',
                  }}>
                    <input
                      accept="image/*"
                      id="raised-button-file"
                      className={classes.input}
                      type="file"
                      onChange={handleSetFile}
                    />
                    <label htmlFor="raised-button-file">
                      <Button
                        raised
                        component="span"
                        color="primary"
                      >
                        Selecionar
                  </Button>
                    </label>
                  </div>
                }
              </Card>
              {image !== '' &&

                <Button
                  color="secondary"
                  onClick={() => {
                    setImage('');
                  }}
                >
                  Limpar
                </Button>
              }
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            justify="flex-start"
          >
            <Grid
              item
            >
              <Button
                style={{ color: "white" }}
                color="primary"
                variant="contained"
                onClick={(event) => alterar_dados()}
              >
                Alterar Dados
                </Button>
            </Grid>
            <Grid
              item
            >
              <Button
                style={{ color: "white" }}
                color="secondary"
                variant="contained"
                onClick={() => navigate('/categorias')}
              >
                Cancelar
                </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  )
}
