import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import ApolloClient from "apollo-client";
import { createUploadLink } from "apollo-upload-client";
import { ApolloProvider,ApolloLink } from "@apollo/react-hooks";
import { InMemoryCache } from "apollo-cache-inmemory";
import {onError} from 'apollo-link-error';
import { toast, ToastContainer } from 'react-toastify';

const httpLink = createUploadLink({
  uri: "https://gerencia.construrapi.store/graphql", // Ar (esconder)
  //uri: "http://192.168.18.6:4000/graphql" // Local
});

const link = onError(({ graphQLErrors, networkError }) => {
  if(graphQLErrors){
    graphQLErrors.map(({ message, locations, path }) => {
      toast.error(`${message}`)
    })
  }
  if (networkError){
    toast.error(`${networkError}`); // log
  } 
})

const client = new ApolloClient({
  link: ApolloLink.from([link,httpLink]),
  cache: new InMemoryCache(),
});

ReactDOM.render((
  <BrowserRouter>
    <ApolloProvider client={client}>
      <ToastContainer />
        <App />
    </ApolloProvider>
  </BrowserRouter>
), document.getElementById('root'));

