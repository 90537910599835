import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import clsx from 'clsx';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    makeStyles
} from '@material-ui/core';
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { store } from '../../store'

const UpdateFAQ = gql`
  mutation($titulo:String!, $resposta:String!, $uuid:String!) {
    updateFAQ(titulo:$titulo, resposta:$resposta, uuid:$uuid) 
  }
  `;

const useStyles = makeStyles((theme) => ({
    input: {
        display: 'none'
    },
    cardMedia: {
        height: "100%"
    },
    media: {
        height: 175
    },
    inputLabel: {
        paddingBottom: theme.spacing(1),
        color: '#FFB500'
    },
    root: {
        "& .MuiFormLabel-root": {
            color: "orange",
            fontWeight: "bold"
        },
        "& .MuiCardHeader-subheader": {
            color: "orange",
            fontWeight: "bold"
        },
        '& .MuiOutlinedInput-input': {
            color: 'gray',
        },
    },
    card: {
        display: 'flex',
        width: 151,
        height: 140
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 151,
        height: 140,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
    },
    playIcon: {
        height: 38,
        width: 38,
    },
    buttons: {
        width: 351,
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    }
}));

export default function FAQView({ className, ...rest }) {

    const navigate = useNavigate();

    const faq = useLocation().state;

    const classes = useStyles();

    const [updateFAQ] = useMutation(UpdateFAQ);

    const [titulo, setTitulo] = useState(faq.titulo);

    const [resposta, setResposta] = useState(faq.resposta);

    const alterar_dados = () => {
        confirmAlert({
            title: 'Confirme para enviar',
            message: 'Você tem certeza dessa alteração ?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: async () => {
                        try {
                            await updateFAQ({
                                variables: { titulo: titulo, resposta: resposta, uuid: faq.uuid },
                                context: {
                                    headers: {
                                        "authorization": store.getState().auth.accessToken,
                                        "x-refresh-token": store.getState().auth.refreshToken,
                                        'Access-Control-Allow-Origin': '*'
                                    }
                                },
                                onCompleted: () => { },
                            });

                            navigate('/faq');
                        } catch (error) {
                            alert(error)
                        }
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    };

    return (
        <form
            autoComplete="off"
            noValidate
            className={clsx(classes.root, className)}
            {...rest}
        >
            <Card>
                <CardHeader
                    title={"Alterar faq"}
                />
                <Divider />

                <CardContent>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            xs={12}
                            sm={6}
                        >
                            <TextField
                                fullWidth
                                label="Título"
                                name="titulo"
                                value={titulo}
                                onChange={(event) => setTitulo(event.target.value)}
                                variant="outlined"
                                InputLabelProps={{
                                    style: { color: '#c4c4c4' },
                                }}

                            />
                        </Grid>
                        <Box width="100%" />
                        <Grid
                            item
                            xs={12}
                            sm={6}
                        >
                            <TextField
                                fullWidth
                                label="Resposta"
                                name="resposta"
                                value={resposta}
                                multiline={true}
                                rows={5}
                                onChange={(event) => setResposta(event.target.value)}
                                variant="outlined"
                                InputLabelProps={{
                                    style: { color: '#c4c4c4' },
                                }}

                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={3}
                        justify="flex-start"
                    >
                        <Grid
                            item
                        >
                            <Button
                                style={{ color: "white" }}
                                color="primary"
                                variant="contained"
                                onClick={(event) => alterar_dados()}
                            >
                                Alterar Dados
                            </Button>
                        </Grid>
                        <Grid
                            item
                        >
                            <Button
                                style={{ color: "white" }}
                                color="secondary"
                                variant="contained"
                                onClick={() => navigate('/faq')}
                            >
                                Cancelar
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </form>
    )
}
