import axios from 'axios';
import { store } from '../store';
import { Auth } from '../store/reducers/auth/actions';
import { connect } from 'react-redux';

axios.defaults.baseURL = 'https://gerencia.construrapi.store/'; // Ar
//axios.defaults.baseURL = "http://192.168.0.5:4000/"; // Local

export async function req(query, ignoreCtx) {
  try {
    const response = await axios.post(
      'graphql',
      { query },
      {
        headers: {
          accept: 'application/json',
          Authorization: store.getState().auth.accessToken,
          'x-refresh-token': store.getState().auth.refreshToken,
          'Access-Control-Allow-Origin': '*',
          'ignore-ctx': ignoreCtx ? true : false
        }
      }
    );
    if (response.headers['x-token'] && response.headers['x-refresh-token']) {
      store.dispatch(
        Auth({
          accessToken: response.headers['x-token'],
          refreshToken: response.headers['x-refresh-token']
        })
      );
    }

    return response.data;
  } catch (err) {
    console.log('ERRO_CACTH', err.data);
    throw new Error('Não foi possível conectar-se ao servidor!');
  }
}

export default connect()(axios);
