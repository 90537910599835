import React, { useEffect, useState } from 'react';
import Page from 'src/components/Page';
import {
    User as UserIcon,
    Users as UsersIcon,
    Trash as TrashIcon
} from 'react-feather'
import { Box, Button, Card, CardContent, CircularProgress, Container, Grid, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { req } from 'src/services/api';
import CryptoJS from 'crypto-js';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    title: {
        paddingBottom: theme.spacing(1),
        marginLeft: theme.spacing(2)
    },
    input: {
        display: 'none'
    },
    inputLabel: {
        paddingBottom: theme.spacing(1),
    },
}));

const Gerenciadores = () => {

    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [nome, setNome] = useState('');
    const [cpf, setCpf] = useState('');
    const [telefone, setTelefone] = useState('');

    const [usuarios, setUsuarios] = useState([]);

    const [loading, setLoading] = useState(false);
    const [loadingNewUser, setLoadingNewUser] = useState(false);

    const classes = useStyles();

    const isInputValid = () => email !== '' && senha !== '' && senha.length >= 8 && telefone !== '' && cpf.length === 11 && nome !== '';

    function clearInputs() {
        setEmail('');
        setSenha('');
        setNome('');
        setCpf('');
        setTelefone('');
    }

    async function handleNewUser(e) {
        e.preventDefault();
        setLoadingNewUser(true);
    
        const dados = {
          email,
          senha,
          nome,
          cpf,
          telefone
        }
    
        const dadosCrypto = CryptoJS.AES.encrypt(JSON.stringify(dados), "secret", {}).toString();
        try {
          const data = await req(
            `
              mutation{
                create_web_user(
                  data: "${dadosCrypto}"
                )
              }
            `
          )
          if (data.data) {
                toast.success("Usuário cadastrado com sucesso!");
                loadUsers();
                clearInputs();
          } else if (data.errors) {
            toast.error(data.errors[0].message);
          }
        } catch (err) {
          alert(err);
        }
        setLoadingNewUser(false)
    }

    async function deleteUser(user) {
        try {
            const data = await req(
              `
                mutation{
                deleteWebuser(
                    uuid: "${user.uuid}"
                  )
                }
              `
            )
      
            if (data.data) {
              toast.success("Usuário excluído com sucesso!");
            } else if (data.errors) {
              toast.error(data.errors[0].message);
            }
      
          } catch (error) {
            console.log(error);
            toast.error("Erro ao excluir usuário!");
          }
          loadUsers()
    }

    function handleDelete(user) {
        confirmAlert({
            title: `Exluir usuário ${user.nome.toUpperCase()}?`,
            buttons: [
              {
                label: 'Sim',
                onClick: () => deleteUser(user)
              },    
              {
                label: 'Não',
                onClick: () => { }
              }
            ]
          });
    }

    async function loadUsers() {
        setLoading(true);
        try {
            const data = await req(
                `
                {
                    readWebUsers{
                        uuid
                        nome    
                        email
                        cpf
                        telefone
                        createdAt
                    }
                }
                `
            )
            if (data.data) {
                setUsuarios(data.data.readWebUsers);
            } else if (data.errors) {
                alert(data.errors);
            }
        } catch (err) {
            alert(err);
        }
        setLoading(false);
    }

    useEffect(() => {
        loadUsers();
    }, [])

    return (
        <Page
            className={classes.root}
            title="Usuários"
        >

            <Container maxWidth={false}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <UserIcon color="#5e5e5f" style={{ margin: 10 }} />
                    <Typography
                        color="textPrimary"
                        variant="h2"
                    >
                        Adicionar gerenciador
                    </Typography>
                </div>

                <form
                    autoComplete="off"
                    onSubmit={() => { }}
                    className={clsx(classes.root)}
                >
                    <Card>
                        <CardContent>
                            <Grid
                                container
                                spacing={2}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={2}
                                >
                                    <TextField
                                        fullWidth
                                        label="Nome"
                                        name="nome"
                                        value={nome}
                                        onChange={(event) => setNome(event.target.value)}
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { color: '#c4c4c4' },
                                        }}

                                    />
                                </Grid><Grid
                                    item
                                    xs={12}
                                    sm={2}
                                >
                                    <TextField
                                        fullWidth
                                        label="CPF"
                                        name="cpf"
                                        type="number"
                                        value={cpf}
                                        onChange={(event) => setCpf(event.target.value)}
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { color: '#c4c4c4' },
                                        }}

                                    />
                                </Grid><Grid
                                    item
                                    xs={12}
                                    sm={2}
                                >
                                    <TextField
                                        fullWidth
                                        label="Telefone"
                                        name="telefone"
                                        value={telefone}
                                        onChange={(event) => setTelefone(event.target.value)}
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { color: '#c4c4c4' },
                                        }}

                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={2}
                                >
                                    <TextField
                                        fullWidth
                                        label="Email"
                                        name="email"
                                        type="email"
                                        value={email}
                                        onChange={(event) => setEmail(event.target.value)}
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { color: '#c4c4c4' },
                                        }}

                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={2}
                                >
                                    <TextField
                                        fullWidth
                                        label="Senha"
                                        type="password"
                                        name="senha"
                                        value={senha}
                                        onChange={(event) => setSenha(event.target.value)}
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { color: '#c4c4c4' },
                                        }}

                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={6}
                                    sm={2}
                                >
                                    <Button
                                        onClick={handleNewUser}
                                        color="primary"
                                        variant="contained"
                                        disabled={!isInputValid()}
                                        style={{
                                            height: 55
                                        }}
                                    >{
                                        loadingNewUser ?
                                        <CircularProgress color="inherit" size={20}/>
                                        : 
                                        <Typography
                                            style={{
                                                color: '#fff',
                                                display: 'flex',

                                            }}
                                        >
                                            Cadastrar
                                        </Typography>
                                    }
                                    </Button>
                                </Grid>

                            </Grid>
                        </CardContent>
                    </Card>
                </form>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 24 }}>
                    <UsersIcon color="#5e5e5f" style={{ margin: 10 }} />
                    <Typography
                        color="textPrimary"
                        variant="h2"
                    >
                        Gerenciadores
                    </Typography>
                </div>
                <Card>
                <PerfectScrollbar>
                    <Box minWidth={1050}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Nome
                                    </TableCell>
                                    <TableCell>
                                        email
                                    </TableCell>
                                    <TableCell>
                                        cpf
                                    </TableCell>
                                    <TableCell align="center">
                                        telefone
                                    </TableCell>
                                    <TableCell align="center">
                                        Data de criação
                                    </TableCell>
                                    <TableCell>

                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ?
                                    <TableRow
                                        hover
                                        key='key'
                                    >
                                        <TableCell
                                            colSpan={7}
                                            align='center'
                                        >
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'center'
                                            }}>
                                                <CircularProgress color="primary" size={20} />
                                                <Typography style={{ marginLeft: 10 }}>
                                                    Carregando...
                                                </Typography>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    :
                                    usuarios.length > 0 ?
                                        usuarios.map((usuario) => (
                                            <TableRow
                                                hover
                                                key={usuario.uuid}
                                            >
                                                <TableCell>
                                                    {usuario.nome}
                                                </TableCell>
                                                <TableCell>
                                                    {usuario.email}
                                                </TableCell>
                                                <TableCell>
                                                    {usuario.cpf}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {usuario.telefone}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {(new Date(Number(usuario.createdAt))).toLocaleDateString()}
                                                </TableCell>
                                                <TableCell>
                                                    <button style={{ backgroundColor: "#fff", borderWidth: 0 }} onClick={() => handleDelete(usuario)} >
                                                        <TrashIcon
                                                            style={{ cursor: "pointer", color: "#E83B3C" }}
                                                        />
                                                    </button>
                                                </TableCell>
                                            </TableRow>
                                        )) :
                                        <TableRow
                                            hover
                                            key='key'
                                        >
                                            <TableCell
                                                colSpan={7}
                                                align='center'
                                            >
                                                Nenhum usuário encontrado
                                            </TableCell>
                                        </TableRow>}
                            </TableBody>
                        </Table>
                    </Box>
                </PerfectScrollbar>
            </Card>
            </Container>
        </Page>
    )
}

export default Gerenciadores;