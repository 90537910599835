import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  CreateOutlined as CreateOutlinedIcon,
  FilterList as FilterIcon
} from '@material-ui/icons';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  CircularProgress,
  Typography
} from '@material-ui/core';
import { formatCurrency } from 'src/utils/currency';
import { ArrowDown, ArrowUp } from 'react-feather';

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  cell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer'
  }
}));

const celulas = [
  {
    name: 'Código',
    slug: 'codigo'
  },
  {
    name: 'Nome',
    slug: 'nome_formal'
  },
  {
    name: 'Categoria',
    slug: 'category'
  },
  {
    name: 'Marca',
    slug: 'marca'
  },
  {
    name: 'Quantidade',
    slug: 'quantidade_estoque'
  },
  {
    name: 'Preço',
    slug: 'preco'
  },
  {
    name: 'Desconto PIX',
    slug: 'desconto_pix'
  }
];

const Results = ({
  className,
  page,
  limit,
  handleLimitChange,
  handlePageChange,
  produtos,
  selectedCustomerIds,
  handleSelectAll,
  handleSelectOne,
  carregarProduto,
  handleOpen,
  loading,
  total,
  setOpenFilterModal,
  canEdit,
  canRemove,
  sortFilter,
  handleSortFilter,
  ...rest
}) => {
  const classes = useStyles();

  const onEdit = uuid => {
    handleOpen();
    carregarProduto(uuid);
  };

  const onCellClick = cell => () => {
    if (sortFilter === null || (!!sortFilter && sortFilter.cell !== cell)) {
      handleSortFilter({ cell, sort: 'ASC' });
    } else {
      handleSortFilter({
        cell,
        sort: sortFilter.sort === 'ASC' ? 'DESC' : 'ASC'
      });
    }
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FilterIcon
                    onClick={() => setOpenFilterModal(true)}
                    style={{
                      cursor: 'pointer',
                      color: '#333',
                      fontSize: 20,
                      marginLeft: 5
                    }}
                  />
                </TableCell>
                {celulas.map(cell => (
                  <TableCell onClick={onCellClick(cell.slug)}>
                    <button
                      style={{ background: 'transparent', border: 'none' }}
                    >
                      <span className={classes.cell}>
                        <p>{cell.name}</p>
                        {sortFilter?.cell === cell.slug ? (
                          sortFilter?.sort === 'ASC' ? (
                            <ArrowUp size={16} style={{ marginLeft: 6 }} />
                          ) : (
                            <ArrowDown size={16} style={{ marginLeft: 6 }} />
                          )
                        ) : null}
                      </span>
                    </button>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow hover key="key">
                  <TableCell colSpan={8} align="center">
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center'
                      }}
                    >
                      <CircularProgress color="primary" size={20} />
                      <Typography style={{ marginLeft: 10 }}>
                        Carregando...
                      </Typography>
                    </div>
                  </TableCell>
                </TableRow>
              ) : produtos.length > 0 ? (
                produtos.map(product => (
                  <TableRow
                    hover
                    onClick={() => onEdit(product.uuid)}
                    style={{ cursor: 'pointer' }}
                    key={product.uuid}
                    selected={selectedCustomerIds.indexOf(product.uuid) !== -1}
                  >
                    <TableCell padding="checkbox">
                      {canRemove && (
                        <Checkbox
                          checked={
                            selectedCustomerIds.indexOf(product.uuid) !== -1
                          }
                          onChange={event =>
                            handleSelectOne(event, product.uuid)
                          }
                          onClick={e => e.stopPropagation()}
                          value="true"
                        />
                      )}
                    </TableCell>
                    <TableCell>{product.codigo}</TableCell>
                    <TableCell>{product.nome_formal}</TableCell>
                    <TableCell>{product.category.nome}</TableCell>
                    <TableCell align="center">{product.marca}</TableCell>
                    <TableCell align="center">
                      {product.quantidade_estoque}
                    </TableCell>
                    <TableCell>{formatCurrency(product.preco || 0)}</TableCell>
                    <TableCell align="center">
                      {Number(product.desconto_pix || 0)} %
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow hover key="key">
                  <TableCell colSpan={7} align="center">
                    Nenhum produto encontrado
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[15, 25, 50, 100]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default Results;
