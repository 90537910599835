import React, { useMemo } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { InputAdornment, makeStyles, TextField } from '@material-ui/core';
import { formatValue } from 'src/utils/currency';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 10,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      flexDirection: 'column'
    }
  },
  autocomplete: {
    width: '49%',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  input: {
    width: '24%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 10
    }
  }
}));

const QuantidadeDescontoProdutos = props => {
  const {
    produtos,
    handleDesconto,
    handleQuantidade,
    produtoSelecionado,
    handleProdutoSelecionado
  } = props;

  const value = useMemo(() => produtoSelecionado, [produtoSelecionado]);

  const classes = useStyles();

  function handleSetQuant(e) {
    if (e.target.value < 0) return;
    if (e.target.value > produtoSelecionado?.quantidade_estoque) return;
    handleQuantidade(e.target.value);
  }

  function onAutocompleteChange(_, value) {
    handleProdutoSelecionado(
      value
        ? {
            ...value,
            quant: value.quant || 1,
            desconto: value.desconto || 0
          }
        : null
    );
  }

  return (
    <>
      <div className={classes.root}>
        <Autocomplete
          id="produtos-autocomplete"
          // key={clearValue}
          value={value}
          freeSolo
          onChange={onAutocompleteChange}
          getOptionLabel={option => option?.nome_formal}
          options={produtos}
          className={classes.autocomplete}
          renderInput={params => (
            <TextField
              {...params}
              label="Produtos"
              variant="outlined"
              style={{ backgroundColor: '#fff' }}
            />
          )}
        />

        <TextField
          className={classes.input}
          label="Quantidade do produto"
          onChange={handleSetQuant}
          placeholder="0"
          disabled={!produtoSelecionado}
          type="number"
          variant="outlined"
          value={produtoSelecionado?.quant}
          style={{ backgroundColor: '#fff' }}
          InputProps={{
            endAdornment: (
              <>
                {produtoSelecionado && (
                  <InputAdornment position="end">
                    {' '}
                    MÁX: {produtoSelecionado.quantidade_estoque}{' '}
                  </InputAdornment>
                )}
              </>
            )
          }}
        />

        <TextField
          className={classes.input}
          label="Valor do produto com desconto"
          onChange={e => handleDesconto(formatValue(e.target.value))}
          type="number"
          disabled={!produtoSelecionado}
          variant="outlined"
          placeholder="0"
          value={produtoSelecionado?.desconto}
          style={{ backgroundColor: '#fff' }}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>
          }}
        />
      </div>
    </>
  );
};

export default QuantidadeDescontoProdutos;
