import React, { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import { Smartphone } from 'react-feather';
import { toast } from 'react-toastify';
import clsx from 'clsx';

import Page from 'src/components/Page';
import { req } from 'src/services/api';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  forceUpdate: {
    width: '100%'
  }
}));

const InformacoesAdicionais = ({ className, permissions, ...rest }) => {
  const classes = useStyles();

  const [version, setVersion] = useState('');
  const [buildNumber, setBuildNumber] = useState(0);
  const [forceUpdate, setForceUpdate] = useState(false);

  async function carregarInformacoesDoApp() {
    try {
      const data = await req(
        `
                {
                    getAppInfo{
                      version
                      buildNumber
                      forceUpdate
                    }
                }
                `
      );
      if (data.data) {
        setVersion(data.data.getAppInfo.version);
        setBuildNumber(data.data.getAppInfo.buildNumber);
        setForceUpdate(data.data.getAppInfo.forceUpdate);
      } else if (data.errors) {
        toast.error('Erro ao resgatar informações');
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function atualizarInformacoesDoApp() {
    try {
      const data = await req(
        `
            mutation{
              updateAppInfo
                (
                    version: "${version}"
                    buildNumber: ${buildNumber}
                    forceUpdate: ${forceUpdate}
                )
                }
            `
      );

      if (data.data) {
        toast.success('Atualizações aplicadas!');
      } else if (data.errors) {
        toast.error('As alterações não foram aplicadas!');
      }
    } catch (error) {
      alert(error);
    }
  }

  useEffect(() => {
    carregarInformacoesDoApp();
  }, []);

  return (
    <Page className={classes.root} title="Informações adicionais">
      <Container maxWidth={false}>
        {permissions.editarPrazoAtual && (
          <Fragment>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <Smartphone color="#5e5e5f" style={{ margin: 10 }} />
              <Typography color="textPrimary" variant="h2">
                Atualizações no aplicativo
              </Typography>
            </div>

            <form
              autoComplete="off"
              onSubmit={() => {}}
              className={clsx(classes.root, className)}
              {...rest}
            >
              <Card>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        fullWidth
                        label="Versão"
                        variant="outlined"
                        name="version"
                        value={version}
                        onChange={e => setVersion(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        fullWidth
                        label="Número de build"
                        name="buildNumber"
                        type='number'
                        value={buildNumber}
                        onChange={e => setBuildNumber(e.target.value)}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={12} sm={2}>
                      <FormControl
                        variant="outlined"
                        className={classes.forceUpdate}
                      >
                        <InputLabel id="force-update">
                          Forçar atualização
                        </InputLabel>
                        <Select
                          labelId="force-update"
                          value={forceUpdate}
                          label="Forçar atualização"
                          onChange={event => setForceUpdate(event.target.value)}
                        >
                          <MenuItem value={true}>Sim</MenuItem>
                          <MenuItem value={false}>Não</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={6} sm={2}>
                      <Button
                        onClick={atualizarInformacoesDoApp}
                        color="primary"
                        variant="contained"
                        style={{
                          height: 55
                        }}
                      >
                        <Typography
                          style={{
                            color: '#fff',
                            display: 'flex'
                          }}
                        >
                          Atualizar
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </form>
          </Fragment>
        )}
      </Container>
    </Page>
  );
};

export default InformacoesAdicionais;
