export const alterarTabOrcamento = tab => {
  return {
    type: '@tabs/ALTERAR_TAB_ORCAMENTO',
    payload: { tab }
  };
};

export const alterarTabPedidos = tab => {
  return {
    type: '@tabs/ALTERAR_TAB_PEDIDOS',
    payload: { tab }
  };
};
