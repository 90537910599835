const INITIAL_STATE = {
    accessToken: '',
    refreshToken: '',
    role: 0,
    nome: ''
  };
  
const auth = (state = INITIAL_STATE, action) => {
const baseAction = '@auth/';
switch (action.type) {
    default:
    return state;
    case `${baseAction}SET_TOKEN`:
    return {
        ...state,
        accessToken: action.payload.auth.accessToken,
        refreshToken: action.payload.auth.refreshToken,
        role: action.payload.auth.role,
        nome: action.payload.auth.nome
    };
}
}

export default auth;