import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  makeStyles
} from '@material-ui/core';
import LocalOfferIcon from '@material-ui/icons/LocalOfferOutlined';

import {
  Clipboard as ClipboardIcon,
  PlusSquare as PlusSquareIcon,
  LogOut as LogOutIcon,
  List as ListIcon,
  ShoppingBag as ShoppingBagIcon,
  Truck as TruckIcon,
  ShoppingCart as ShoppingCartIcon,
  Phone as PhoneIcon,
  Users as UsersIcon,
  User as UserIcon,
  HelpCircle as HelpCircleIcon,
  Code as CodeIcon,
  BookOpen as BookOpenIcon,
  Key as KeyIcon
} from 'react-feather';

import EqualizerIcon from '@material-ui/icons/Equalizer';
import TimelineIcon from '@material-ui/icons/Timeline';

import { StorefrontOutlined as MarkIcon } from '@material-ui/icons';

import NavItem from './NavItem';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile, permissions }) => {
  const classes = useStyles();
  const location = useLocation();

  const user = useSelector(state => state.auth);

  const items = [
    ...(permissions.produtos.exibirAba
      ? [
          {
            href: '/produtos',
            icon: PlusSquareIcon,
            title: 'Produtos'
          }
        ]
      : []),
    ...(permissions.pedidos.exibirAba
      ? [
          {
            href: '/pedidos',
            icon: ClipboardIcon,
            title: 'Pedidos'
          }
        ]
      : []),
    ...(permissions.orcamentos.exibirAba
      ? [
          {
            href: '/orcamentos',
            icon: BookOpenIcon,
            title: 'Orçamentos'
          }
        ]
      : []),
    ...(permissions.orcamentos.exibirAba && permissions.pedidos.exibirAba
      ? [
          {
            href: '/dados',
            icon: TimelineIcon,
            title: 'Dados'
          }
        ]
      : []),
    ...(permissions.categoriasApp.exibirAba
      ? [
          {
            href: '/categorias',
            icon: ListIcon,
            title: 'Categorias'
          }
        ]
      : []),
    ...(permissions.marcas.exibirAba
      ? [
          {
            href: '/marcas',
            icon: MarkIcon,
            title: 'Marcas'
          }
        ]
      : []),
    ...(permissions.promocoes.exibirAba
      ? [
          {
            href: '/promocoes',
            icon: ShoppingBagIcon,
            title: 'Promoções'
          }
        ]
      : []),
    ...(permissions.vendedores.exibirAba
      ? [
          {
            href: '/vendedores',
            icon: UsersIcon,
            title: 'Vendedores'
          }
        ]
      : []),
    ...(permissions.cupons.exibirAba
      ? [
          {
            href: '/cupons',
            icon: LocalOfferIcon,
            title: 'Cupons de Desconto'
          }
        ]
      : []),
    ...(permissions.carrinho.exibirAba
      ? [
          {
            href: '/carrinho',
            icon: ShoppingCartIcon,
            title: 'Carrinho'
          }
        ]
      : []),
    ...(permissions.entrega.exibirAba
      ? [
          {
            href: '/entrega',
            icon: TruckIcon,
            title: 'Entrega'
          }
        ]
      : []),
    ...(permissions.contato.exibirAba
      ? [
          {
            href: '/contato',
            icon: PhoneIcon,
            title: 'Contato'
          }
        ]
      : []),
    ...(user.role === 0
      ? [
          {
            href: '/gerenciadores',
            icon: UserIcon,
            title: 'Gerenciadores'
          }
        ]
      : []),
    ...(permissions.usuarios.exibirAba
      ? [
          {
            href: '/usuarios',
            icon: UserIcon,
            title: 'Usuários'
          }
        ]
      : []),
    ...(permissions.faq.exibirAba
      ? [
          {
            href: '/faq',
            icon: HelpCircleIcon,
            title: 'FAQ'
          }
        ]
      : []),
    ...(user.role === 0
      ? [
          {
            href: '/permissions',
            icon: KeyIcon,
            title: 'Permissões'
          }
        ]
      : []),
    ...(user.role === 0
      ? [
          {
            href: '/api',
            icon: CodeIcon,
            title: 'Api'
          }
        ]
      : [])
  ];

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box p={2} className="box" style={{ scrollbarWidth: 'none' }}>
        <List>
          {items.map(item => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
        <Divider />
        <NavItem href="" title="Sair" icon={LogOutIcon} logout={true} />
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
