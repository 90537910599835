import React, { useState, useEffect } from 'react';
import { Truck as TruckIcon, Plus as PlusIcon } from 'react-feather';
import {
  Button,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { Trash as TrashIcon } from 'react-feather';

import { req } from 'src/services/api';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 20
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2)
  },
  center: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

export default function Bairros({
  formatValue,
  zonas,
  refreshZonas,
  permissions
}) {
  const classes = useStyles();

  const [bairros, setBairros] = useState([]);
  const [novoBairro, setNovoBairro] = useState(null);

  async function loadBairros() {
    try {
      const data = await req(`{ 
        readDistricts{
            uuid
            name
            min_value
            value
            delivery_time 
            zone_id  
            min_value_free
        } 
        }`);
      if (data.data) {
        setBairros(data.data.readDistricts);
      } else if (data.errors) {
        toast.error('Não foi possível carregar os dados.');
      }
    } catch (e) {}
  }

  async function salvar() {
    if (!novoBairro) return;
    try {
      const data = await req(`
        mutation{ 
          createDistrict( 
            name: "${novoBairro.name}"
            min_value: ${Number(novoBairro.min_value)}
            value: ${Number(novoBairro.value)}
            zone_id: "${novoBairro.zone_id}"
            delivery_time: ${novoBairro.delivery_time}
            min_value_free: ${novoBairro.min_value_free}
        ) 
       }`);
      if (data.data) {
        const uuid = data.data.createDistrict;
        setBairros([...bairros, { ...novoBairro, uuid }]);
        setNovoBairro(null);
        refreshZonas();
      } else if (data.errors) {
        toast.error('Erro ao criar novo bairro.');
      }
    } catch (e) {
      toast.error('Erro ao criar novo bairro.');
    }
  }

  async function excluir(uuid, zone_id) {
    try {
      const data = await req(`mutation{
        deleteDistrict(uuid: "${uuid}", zone_id: "${zone_id}")
      }`);
      refreshZonas();
      if (data.data) {
        setBairros(bairros.filter(bairro => bairro.uuid !== uuid));
      } else if (data.errors) {
        toast.error('Erro ao excluir bairro.');
      }
    } catch (e) {
      toast.error('Erro ao excluir bairro.');
    }
  }

  async function atualizar(uuid) {
    const bairro = bairros.find(bairro => bairro.uuid === uuid);
    const props = Object.entries(bairro).map(([key, value]) =>
      key === 'dirty'
        ? ''
        : ['uuid', 'name', 'zone_id'].includes(key)
        ? `${key}: "${value}"`
        : `${key}: ${value}`
    );
    if (bairro) {
      try {
        await req(`
        mutation{ 
          updateDistrict( 
                ${props.join(' ')}
        ) 
        }`);
      } catch (e) {
        toast.error('Erro ao atualizar bairro.');
      }
      setBairros(
        bairros.map(bairro =>
          bairro.uuid !== uuid ? bairro : { ...bairro, dirty: false }
        )
      );
      refreshZonas();
    }
  }

  function onChange(event, uuid, prop) {
    setBairros(
      bairros.map(bairro =>
        bairro.uuid !== uuid
          ? bairro
          : {
              ...bairro,
              [prop]: ['min_value', 'value', 'min_value_free'].includes(prop)
                ? formatValue(event.target.value)
                : event.target.value,
              dirty: true
            }
      )
    );
  }

  useEffect(() => {
    loadBairros();
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <TruckIcon color="#5e5e5f" style={{ margin: 10 }} />
          <Typography color="textPrimary" variant="h2">
            Bairros
          </Typography>
        </div>
        {permissions?.adicionar && (
          <Button
            onClick={() =>
              setNovoBairro({
                name: '',
                value: '',
                min_value: '',
                delivery_time: '',
                min_value_free: '',
                zone_id: zonas[0]?.uuid
              })
            }
            component="span"
            color="primary"
            variant="contained"
          >
            <Typography
              style={{
                color: '#fff',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              align="center"
            >
              <PlusIcon style={{ paddingRight: 3 }} />
              Novo
            </Typography>
          </Button>
        )}
      </div>
      <Card>
        <CardContent>
          {(bairros || []).map(bairro => (
            <Grid
              key={`${bairro.uuid}`}
              container
              spacing={2}
              style={{ alignItems: 'center' }}
            >
              <Grid item xs={12} sm={2}>
                <TextField
                  fullWidth
                  label="Bairro"
                  variant="outlined"
                  name="name"
                  value={bairro.name}
                  disabled={true}
                  InputLabelProps={{
                    style: { color: '#f18750' }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <TextField
                  fullWidth
                  name="zona"
                  onChange={event => onChange(event, bairro.uuid, 'zone_id')}
                  select
                  SelectProps={{ native: true }}
                  value={bairro.zone_id}
                  variant="outlined"
                  InputLabelProps={{
                    style: { color: '#c4c4c4' }
                  }}
                >
                  {(zonas || []).map(zona => (
                    <option key={zona.uuid} value={zona.uuid}>
                      {zona.name}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  fullWidth
                  label="Valor"
                  variant="outlined"
                  name="valor"
                  value={bairro.value}
                  onChange={event => onChange(event, bairro.uuid, 'value')}
                  InputLabelProps={{
                    style: { color: '#f18750' }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"> R$ </InputAdornment>
                    )
                  }}
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  fullWidth
                  label="Valor mínimo"
                  variant="outlined"
                  name="min_value"
                  value={bairro.min_value}
                  onChange={event => onChange(event, bairro.uuid, 'min_value')}
                  InputLabelProps={{
                    style: { color: '#f18750' }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"> R$ </InputAdornment>
                    )
                  }}
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  fullWidth
                  label="Compra mínima grátis"
                  variant="outlined"
                  name="min_value_free"
                  value={bairro.min_value_free}
                  onChange={event =>
                    onChange(event, bairro.uuid, 'min_value_free')
                  }
                  InputLabelProps={{
                    style: { color: '#f18750' }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"> R$ </InputAdornment>
                    )
                  }}
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  fullWidth
                  label="Tempo de entrega"
                  variant="outlined"
                  name="tempo"
                  placeholder="0"
                  value={bairro.delivery_time}
                  onChange={event =>
                    onChange(event, bairro.uuid, 'delivery_time')
                  }
                  InputLabelProps={{
                    style: { color: '#f18750' }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end"> horas </InputAdornment>
                    )
                  }}
                  type="number"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={1}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center'
                }}
              >
                {permissions?.editar && (
                  <Button
                    onClick={() => atualizar(bairro.uuid)}
                    component="span"
                    color="primary"
                    variant="outline"
                    style={{ height: 55, width: '30%' }}
                    disabled={!bairro.dirty}
                  >
                    <Typography
                      style={{
                        color: '#f18750',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                      align="center"
                    >
                      Atualizar
                    </Typography>
                  </Button>
                )}
              </Grid>
            </Grid>
          ))}
          {novoBairro && (
            <Grid container spacing={2} style={{ alignItems: 'center' }}>
              <Grid item xs={12} sm={2}>
                <TextField
                  fullWidth
                  label="Bairro"
                  variant="outlined"
                  name="name"
                  value={novoBairro.name}
                  onChange={event =>
                    setNovoBairro({ ...novoBairro, name: event.target.value })
                  }
                  InputLabelProps={{
                    style: { color: '#f18750' }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <TextField
                  fullWidth
                  name="zona"
                  onChange={event =>
                    setNovoBairro({
                      ...novoBairro,
                      zone_id: event.target.value
                    })
                  }
                  select
                  SelectProps={{ native: true }}
                  value={novoBairro.zone_id}
                  variant="outlined"
                  InputLabelProps={{
                    style: { color: '#c4c4c4' }
                  }}
                >
                  {(zonas || []).map(zona => (
                    <option key={zona.uuid} value={zona.uuid}>
                      {zona.name}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  fullWidth
                  label="Valor"
                  variant="outlined"
                  name="valor"
                  value={novoBairro.value}
                  placeholder="0,00"
                  onChange={event =>
                    setNovoBairro({
                      ...novoBairro,
                      value: formatValue(event.target.value)
                    })
                  }
                  InputLabelProps={{
                    style: { color: '#f18750' }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"> R$ </InputAdornment>
                    )
                  }}
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  fullWidth
                  label="Valor mínimo"
                  variant="outlined"
                  name="min_value"
                  value={novoBairro.min_value}
                  placeholder="0,00"
                  onChange={event =>
                    setNovoBairro({
                      ...novoBairro,
                      min_value: formatValue(event.target.value)
                    })
                  }
                  InputLabelProps={{
                    style: { color: '#f18750' }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"> R$ </InputAdornment>
                    )
                  }}
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  fullWidth
                  label="Compra mínima grátis"
                  variant="outlined"
                  name="min_value_free"
                  value={novoBairro.min_value_free}
                  onChange={event =>
                    setNovoBairro({
                      ...novoBairro,
                      min_value_free: formatValue(event.target.value)
                    })
                  }
                  InputLabelProps={{
                    style: { color: '#f18750' }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"> R$ </InputAdornment>
                    )
                  }}
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  fullWidth
                  label="Tempo de entrega"
                  variant="outlined"
                  name="tempo"
                  placeholder="0"
                  value={novoBairro.delivery_time}
                  onChange={event =>
                    setNovoBairro({
                      ...novoBairro,
                      delivery_time: formatValue(event.target.value)
                    })
                  }
                  InputLabelProps={{
                    style: { color: '#f18750' }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end"> horas </InputAdornment>
                    )
                  }}
                  type="number"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={1}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start'
                }}
              >
                <Button
                  onClick={salvar}
                  component="span"
                  color="primary"
                  variant="outline"
                  style={{ height: 55, width: '30%' }}
                  disabled={
                    novoBairro.name.length === 0 ||
                    !novoBairro.value ||
                    !novoBairro.min_value ||
                    !novoBairro.delivery_time ||
                    !novoBairro.min_value_free
                  }
                >
                  <Typography
                    style={{
                      color: '#f18750',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                    align="center"
                  >
                    Salvar
                  </Typography>
                </Button>
                <button
                  style={{
                    backgroundColor: '#fff',
                    borderWidth: 0,
                    marginLeft: 10
                  }}
                  onClick={() => setNovoBairro(null)}
                >
                  <TrashIcon
                    style={{
                      cursor: 'pointer',
                      color: '#E83B3C',
                      fontSize: 24
                    }}
                  />
                </button>
              </Grid>
            </Grid>
          )}
          {bairros.length === 0 && !novoBairro ? (
            <div className={classes.center}>
              <Typography>Não existem bairros cadastrados!</Typography>
            </div>
          ) : null}
        </CardContent>
      </Card>
    </div>
  );
}
