import React, { createContext, useContext, useState } from 'react';

const PaginationContext = createContext({});

const usePagination = () => useContext(PaginationContext);

const PaginationProvider = ({ children }) => {
  const [orcamentosAbertosPage, setOrcamentosAbertosPage] = useState(0);
  const [orcamentosFechadosPage, setOrcamentosFechadosPage] = useState(0);

  return (
    <PaginationContext.Provider
      value={{
        orcamentosAbertosPage,
        orcamentosFechadosPage,
        setOrcamentosAbertosPage,
        setOrcamentosFechadosPage
      }}
    >
      {children}
    </PaginationContext.Provider>
  );
};

export { PaginationProvider, usePagination };
