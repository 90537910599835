import React, { useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { req } from 'src/services/api';

const ClientAutocomplete = ({ setSelected, value }) => {
  const [clients, setClients] = useState([]);

  async function loadClients(filter = '') {
    try {
      const data = await req(
        `
                {
                    get_app_users(
                    skip: 0
                    take: 10
                    filter: "${filter}"
                  ){
                    data {
                     nome
                    }
                    total
                  }
                }
                `
      );
      if (data.data) {
        setClients(data.data.get_app_users.data);
      } else if (data.errors) {
        alert(data.errors);
      }
    } catch (err) {
      alert(err);
    }
  }

  useEffect(() => {
    loadClients();
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <Autocomplete
        id="client-autocomplete"
        freeSolo
        defaultValue={value}
        options={clients.map(option => option.nome)}
        onInputChange={event => loadClients(event?.target?.value)}
        onSelect={event => setSelected(event.target.value)}
        renderInput={params => (
          <TextField
            {...params}
            label="Cliente"
            variant="outlined"
            style={{ backgroundColor: '#fff' }}
          />
        )}
      />
    </div>
  );
};

export default ClientAutocomplete;
