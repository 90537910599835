import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
  Modal,
  CircularProgress
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import CategoriaCard from './categoriaCard';
import AddCategoria from './AddCategoria';
import { ImSad2 } from 'react-icons/im';
import { toast } from 'react-toastify';

import { req } from '../../../services/api';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  categoriaCard: {
    height: '100%'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const CategoriaList = ({ permissions }) => {
  const classes = useStyles();

  const [categorias, setCategorias] = useState([]);
  const [categoriasSearch, setCategoriasSearch] = useState([]);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState('');
  const [page, setPage] = useState(1);
  const [isCategoryForSite, setIsCategoryForSite] = useState(false);

  async function loadCategorias() {
    setLoading(true);
    try {
      const resp = await req(
        `
          {
            ${isCategoryForSite ? 'readCategorySite' : 'readCategory'}{
              uuid
              nome
              url
            }
          }
          `
      );
      if (resp.data) {
        setCategorias(
          isCategoryForSite
            ? resp.data.readCategorySite
            : resp.data.readCategory
        );
        setCategoriasSearch(
          isCategoryForSite
            ? resp.data.readCategorySite.slice(0, 9)
            : resp.data.readCategory.slice(0, 9)
        );
        setPage(1);
      } else if (resp.errors) {
        alert(resp.errors);
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }

  useEffect(() => {
    loadCategorias();
  }, [isCategoryForSite]);

  function handleOpen() {
    setOpenModalAdd(true);
  }

  function handleClose() {
    setOpenModalAdd(false);
  }

  useEffect(() => {
    if (input === '') {
      setCategoriasSearch(categorias.slice((page - 1) * 9, (page - 1) * 9 + 9));
    }
  }, [page, input, categorias]);

  return (
    <Page className={classes.root} title="Categorias">
      <Container maxWidth={false}>
        <Modal
          open={openModalAdd}
          onClose={() => {
            setOpenModalAdd(false);
          }}
          className={classes.modal}
        >
          <AddCategoria
            loadCategorias={loadCategorias}
            handleClose={handleClose}
            toast={toast}
            isCategoryForSite={isCategoryForSite}
          />
        </Modal>
        <Typography color="textPrimary" variant="h2">
          Categorias cadastradas no {isCategoryForSite ? 'site' : 'app'}
        </Typography>
        {loading ? (
          <div
            style={{
              width: '100%',
              height: '70vh',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <CircularProgress color="primary" size={50} />
          </div>
        ) : (
          <>
            <Toolbar
              categorias={categorias}
              setCategoriasSearch={setCategoriasSearch}
              input={input}
              setInput={setInput}
              openModal={handleOpen}
              isCategoryForSite={isCategoryForSite}
              setIsCategoryForSite={setIsCategoryForSite}
              permissions={permissions}
            />
            {categoriasSearch.length > 0 ? (
              <div>
                <Box mt={3}>
                  <Grid container spacing={3}>
                    {categoriasSearch.map(categoria => (
                      <Grid item key={categoria.uuid} lg={4} md={6} xs={12}>
                        <CategoriaCard
                          className={classes.categoriaCard}
                          categoria={categoria}
                          loadCategorias={loadCategorias}
                          isCategoryForSite={isCategoryForSite}
                          permissions={permissions}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
                {input === '' && (
                  <Box mt={3} display="flex" justifyContent="center">
                    <Pagination
                      color="primary"
                      boundaryCount={1}
                      variant="outlined"
                      page={page}
                      onChange={(event, value) => {
                        setPage(value);
                      }}
                      count={Math.ceil(categorias.length / 9)}
                      size="small"
                    />
                  </Box>
                )}
              </div>
            ) : (
              <div
                style={{
                  paddingTop: 30,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <ImSad2 size={150} color="lightgray"></ImSad2>
                <h2 style={{ paddingTop: 10, color: 'gray' }}>
                  Nenhuma categoria econtrada
                </h2>
              </div>
            )}
          </>
        )}
      </Container>
    </Page>
  );
};

export default CategoriaList;
