import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { req } from 'src/services/api';

const VendedorAutocomplete = ({ setSelected, value }) => {
  const [vendedores, setVendedores] = useState([]);

  async function loadVendedores() {
    try {
      const data = await req(
        `
                {
                    readSellers{
                        nome
                    }
                }
                `
      );
      if (data.data) {
        setVendedores(data.data.readSellers);
      } else if (data.errors) {
        alert(data.errors);
      }
    } catch (err) {
      alert(err);
    }
  }

  useEffect(() => {
    loadVendedores();
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <FormControl variant="outlined" style={{ width: '100%' }}>
        <InputLabel id="vendedor">Vendedor</InputLabel>
        <Select
          labelId="vendedor"
          value={value}
          label="Vendedor"
          onChange={event => setSelected(event.target.value)}
        >
          {vendedores.map(vendedor => (
            <MenuItem value={vendedor.nome}>{vendedor.nome}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default VendedorAutocomplete;
