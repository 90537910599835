import React, { useState, useEffect } from 'react';import {
    Box,
    Grid,
    Button,
    Card,
    CardContent,
    makeStyles,
    Typography,
    TextField
} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import gql from "graphql-tag";
import { store } from '../../store';
import { Auth } from '../../store/reducers/auth/actions';
import { connect } from 'react-redux';
import { req } from '../../services/api';


const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
        backgroundColor: "white",
        width: '85%',
        [theme.breakpoints.down('xs')]: {
            width: '95%',
            height: '95%',
        },
        overflowY: 'scroll'
    },
    title: {
        paddingBottom: theme.spacing(1)
    },
    cover: {
        width: 360,
        height: 200,
    },
    input: {
        display: 'none'
    },
    inputLabel: {
        paddingBottom: theme.spacing(1),
    },
    imagesContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
        gridGap: '16px',
        flexDirection: 'row',
    }
}));


const AddPromocao = ({ vendedor, handleClose, toast }) => {
    const today = new Date().toISOString().split('T')[0];
    const classes = useStyles();
    const [initialDate, setInitialDate] = useState(today);
    const [finalDate, setFinalDate] = useState(today);
    
   async function getReportURL() {
    try {
      const data = await req(
        `
          query{
            sale_report(
              fields:{
                id_seller:${vendedor.id}
                seller_name:"${vendedor.nome.toUpperCase()}"
                initialDate: "${initialDate}"
                finalDate: "${finalDate}"
              }
            )
          }
        `
      )

      if (data.data) {
        const link = document.createElement('a')
        link.href = data.data.sale_report
        link.target="_blank";
       // link.download =  data.data.sale_report;
       await setTimeout(function() {console.log("*****"); link.click();}, 2000);
        
      } else if (data.errors) {
        toast.error("Ocorreu um erro!");
      }

    } catch (error) {
      console.log(error);
    }
  }

    
    return (
        <Card style={{padding:"1rem"}}>
            <Typography
                className={classes.title}
                variant="h2"
                color="textPrimary"
            >
                Download Relatório
            </Typography>
            <Card>
                <CardContent>
                    <Grid
                        container
                        spacing={2}
                    >
                    
                         <Grid
                            item
                            xs={12}
                            sm={6}
                        >
                            <TextField
                                
                                label="Data inicial"
                                id="date"
                                type="date"
                                value={initialDate}
                                fullWidth
                                required
                                onChange={(event => setInitialDate(event.target.value))}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                        </Grid>

                         <Grid
                            item
                            xs={12}
                            sm={6}
                        >
                            <TextField
                                value={finalDate}
                                label="Data final"
                                id="date"
                                type="date"
                                fullWidth
                                required
                               onChange={(event => setFinalDate(event.target.value))}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                        </Grid>
                
                        
                        <Grid
                            item
                            xs={12}
                        >
                            <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    style={{ color: "#fff", margin: 10 }}
                                    onClick={handleClose}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    style={{ color: '#fff', margin: 10 }}
                                    onClick={(event) => getReportURL()}
                                >
                                   Download  do relatório
                                </Button>
                            </div>

                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Card>
    )
}

export default connect()(AddPromocao);