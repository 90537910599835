import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Button,
  TextField,
  CircularProgress,
  Typography,
  Grid,
  Card,
  CardContent
} from '@material-ui/core';

import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/Lock';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { req } from '../../../services/api';
import { Auth } from '../../../store/reducers/auth/actions';
import { connect } from 'react-redux';
import CryptoJS from 'crypto-js';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100vw'
  },
  content: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  card: {
    width: '35vw',
    [theme.breakpoints.down('sm')]: {
      width: '90vw'
    }
  },
  form: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  textField: {
    marginTop: theme.spacing(3)
  },
  signInButton: {
    margin: theme.spacing(3, 0)
  }
}));

const Login = props => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { dispatch, refreshPermissions } = props;

  const classes = useStyles();

  const [formState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(Auth({ accessToken: '', refreshToken: '', nome: '' }));
  }, []);

  const handleSignIn = async event => {
    event.preventDefault();
    setLoading(true);

    const dados = {
      email: email,
      senha: password
    };

    const dadosCrypto = CryptoJS.AES.encrypt(
      JSON.stringify(dados),
      'secret',
      {}
    ).toString();
    const delay = ms => new Promise(res => setTimeout(res, ms));
    try {
      const data = await req(
        `
          mutation{
            loginWeb(
              data: "${dadosCrypto}"
            ){
              accessToken
              refreshToken
              role
              nome
            }
          }
        `,
        true
      );
      if (data.data) {
        dispatch(
          Auth(data.data.loginWeb || { accessToken: '', refreshToken: '', nome: '' })
        );
        refreshPermissions();
        await delay(1000);
        setLoading(false);
        navigate('/');
      } else if (data.errors) {
        toast.error(data.errors[0].message);
      }
    } catch (err) {
      alert(err);
    }
    setLoading(false);
  };
  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div style={{ marginBottom: 20 }}>
          <img
            alt=""
            src="/static/images/logo/logo-laranja.png"
            style={{ width: 270 }}
          />
        </div>
        <form className={classes.form} onSubmit={handleSignIn}>
          <Card className={classes.card}>
            <CardContent style={{ width: '100%' }}>
              <Grid
                container
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  width: '100%'
                }}
              >
                <Grid item style={{ marginTop: 10, marginBottom: 10 }}>
                  <Typography color="textPrimary" variant="h4" align="center">
                    Logar no sistema
                  </Typography>
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    className={classes.textField}
                    error={hasError('username')}
                    helperText={
                      hasError('username') ? formState.errors.username[0] : null
                    }
                    label="Usuário"
                    name="username"
                    onChange={e => setEmail(e.target.value)}
                    type="text"
                    value={email || ''}
                    variant="outlined"
                    InputLabelProps={{
                      style: { color: 'black' }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    className={classes.textField}
                    error={hasError('password')}
                    helperText={
                      hasError('password') ? formState.errors.password[0] : null
                    }
                    label="Senha"
                    name="password"
                    onChange={e => setPassword(e.target.value)}
                    type="password"
                    value={password || ''}
                    variant="outlined"
                    InputLabelProps={{
                      style: { color: 'black' }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item>
                  {loading ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: 15
                      }}
                    >
                      <CircularProgress />
                    </div>
                  ) : (
                    <Button
                      className={classes.signInButton}
                      color="primary"
                      disabled={email.length <= 0 || password.length < 8}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      <span style={{ color: '#fff' }}>ENTRAR</span>
                    </Button>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </form>
      </div>
    </div>
  );
};

export default connect()(Login);
