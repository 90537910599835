import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { EditOutlined, CloudDownload, Delete } from '@material-ui/icons';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import { req } from '../../services/api';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiSvgIcon-root': {
      color: 'gray'
    },
    '& .MuiSvgIcon-root:hover ': {
      //
      color: '#ff5c08',
      opacity: 1
    }
  },
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({
  className,
  vendedores,
  loading,
  openModal,
  limit,
  setLimit,
  loadVendedores,
  handleOpen,
  carregarVendedor,
  page,
  setPage,
  permissions,
  ...rest
}) => {
  const classes = useStyles();
  const [selectedOrderIds, setSelectedOrderIds] = useState([]);

  const handleLimitChange = event => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  async function deleteCategory(vendedor) {
    try {
      const data = await req(
        `
          mutation{
            deleteSeller(
              uuid: "${vendedor.uuid}"
            )
          }
        `
      );

      if (data.data) {
        toast.success('Vendedor deletado com sucesso!');
      } else if (data.errors) {
        toast.error(data.errors[0].message);
      }
    } catch (error) {
      console.log(error);
      toast.error('Erro ao deletar Vendedor!');
    }
    loadVendedores();
  }

  async function getReportURL(vendedor) {
    try {
      const data = await req(
        `
          query{
            sale_report(
              fields:{
                id_seller:${vendedor.id}
                initialDate: ""
                finalDate: ""
              }
            )
          }
        `
      );

      if (data.data) {
        const link = document.createElement('a');
        link.href = data.data.sale_report;
        link.target = '_blank';
        // link.download =  data.data.sale_report;
        await setTimeout(function() {
          console.log('*****');
          link.click();
        }, 2000);
      } else if (data.errors) {
        toast.error('Ocorreu um erro!');
      }
    } catch (error) {
      console.log(error);
    }
  }

  function excluirVendedor(vendedor) {
    confirmAlert({
      title: `Exluir vendedor ${vendedor.nome.toUpperCase()}?`,
      buttons: [
        {
          label: 'Sim',
          onClick: () => deleteCategory(vendedor)
        },
        {
          label: 'Não',
          onClick: () => {}
        }
      ]
    });
  }

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Código</TableCell>

                <TableCell>Nome do Vendedor</TableCell>

                <TableCell align="center">CPF</TableCell>

                {permissions.downloadRelatorio && (
                  <TableCell align="center">Relatório de Vendas</TableCell>
                )}

                {permissions.editar && (
                  <TableCell align="center">Editar</TableCell>
                )}

                {permissions.excluir && (
                  <TableCell align="center">Deletar</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow hover key="key">
                  <TableCell colSpan={9} align="center">
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center'
                      }}
                    >
                      <CircularProgress color="primary" size={20} />
                      <Typography style={{ marginLeft: 10 }}>
                        Carregando...
                      </Typography>
                    </div>
                  </TableCell>
                </TableRow>
              ) : vendedores.length > 0 ? (
                vendedores
                  .slice(page * limit, limit + page * limit)
                  .map(seller => (
                    <TableRow
                      hover
                      key={seller.uuid}
                      selected={selectedOrderIds.indexOf(seller.uuid) !== -1}
                    >
                      <TableCell>{seller.id}</TableCell>

                      <TableCell>{seller.nome.toUpperCase()}</TableCell>

                      <TableCell align="center">
                        {seller.cpf.toUpperCase()}
                      </TableCell>

                      {permissions.downloadRelatorio && (
                        <TableCell align="center">
                          <span
                            onClick={() => {
                              carregarVendedor(
                                seller.uuid,
                                seller.nome,
                                seller.cpf,
                                seller.id
                              );
                              openModal();
                            }}
                          >
                            <CloudDownload />
                          </span>
                        </TableCell>
                      )}

                      {permissions.editar && (
                        <TableCell align="center">
                          <span
                            onClick={() => {
                              handleOpen();
                              carregarVendedor(
                                seller.uuid,
                                seller.nome,
                                seller.cpf,
                                seller.id
                              );
                            }}
                          >
                            <EditOutlined color="secondary" />
                          </span>
                        </TableCell>
                      )}

                      {permissions.excluir && (
                        <TableCell align="center">
                          <span
                            onClick={() => {
                              excluirVendedor(seller);
                            }}
                          >
                            <Delete color="secondary" />
                          </span>
                        </TableCell>
                      )}
                    </TableRow>
                  ))
              ) : (
                <TableRow hover key="key">
                  <TableCell colSpan={9} align="center">
                    Nenhum vendedor encontrado
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={vendedores.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[15, 25, 50, 100]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  vendedores: PropTypes.array.isRequired
};

export default Results;
