import React, { useState, useRef } from 'react';
import {
  Button,
  Modal,
  Grid,
  Typography,
  makeStyles,
  Tooltip,
  CircularProgress
} from '@material-ui/core';
import { Upload as UploadIcon, Info as InfoIcon } from 'react-feather';
import gql from 'graphql-tag';
import clsx from 'clsx';
import Papa from 'papaparse';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/react-hooks';
import { store } from '../../../store';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    backgroundColor: 'white',
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      width: '95%',
      height: '95%'
    },
    overflowY: 'auto'
  },
  title: {
    paddingBottom: theme.spacing(1)
  },
  importButton: {
    marginRight: 15,
    color: 'white',
    height: 50
  },
  uploadIcon: {},
  infoIcon: {
    height: 12,
    marginLeft: 5,
    marginTop: 5
  },
  input: {
    display: 'none'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'auto'
  },
  fileDetails: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  button: {
    color: 'white',
    marginRight: 10
  },
  updateButtonDiv: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end'
  }
}));

const UpdateMutation = gql`
  mutation($products: [ProductUpdateManyInput!]!) {
    updateProductsMany(products: $products)
  }
`;

export default function ImportProducts() {
  const classes = useStyles();
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [importedFile, setImportedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const [updateProduct] = useMutation(UpdateMutation);

  const handleImportClick = () => {
    setOpenModalAdd(true);
  };

  const handleModalClose = () => {
    setOpenModalAdd(false);
  };

  const handleFileImport = e => {
    setImportedFile(null);
    const file = e.target.files[0];

    if (file && file.type === 'text/csv') {
      setImportedFile({
        file: file,
        name: file.name,
        size: file.size,
        type: file.type,
        lastModified: file.lastModified
      });
    } else {
      toast.error('Por favor, selecione um arquivo CSV.');
    }
  };

  const parseCsv = file => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: result => {
          resolve(result.data);
        },
        error: error => {
          reject(error.message);
        }
      });
    });
  };

  const handleUpdateData = async () => {
    if (!importedFile) {
      toast.error('Por favor, selecione um arquivo CSV.');
    }
    setLoading(true);
    try {
      const result = await parseCsv(importedFile.file);
      // Filtrar apenas os campos desejados
      const filteredData = result.map(item => {
        const filteredItem = {
          codigo: item['Codigo']
        };

        if (item['Quantidade'] !== '') {
          filteredItem.quantidade_estoque = parseFloat(item['Quantidade']);
        }

        if (item['Preco'] !== '') {
          filteredItem.preco = parseFloat(item['Preco'].replace(",","."));
        }

        if (item['Desconto PIX'] !== '') {
          filteredItem.desconto_pix = parseFloat(item['Desconto PIX']);
        }

        return filteredItem;
      });

      console.log(filteredData);
      await updateProduct({
        variables: {
          products: filteredData
        },
        context: {
          headers: {
            authorization: store.getState().auth.accessToken,
            'x-refresh-token': store.getState().auth.refreshToken,
            'Access-Control-Allow-Origin': '*'
          }
        }
      });
      toast.success('Produto atualizado com sucesso!');
    } catch (error) {
      console.error('Erro ao analisar o CSV:', error);
      toast.error('Erro ao atualizar produto!');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Tooltip title="Upload de Arquivo csv">
        <Button
          className={classes.importButton}
          color="primary"
          variant="contained"
          onClick={handleImportClick}
        >
          <UploadIcon className={classes.uploadIcon} />
        </Button>
      </Tooltip>
      <Modal
        disableBackdropClick={true}
        open={openModalAdd}
        className={classes.modal}
        onClose={() => (loading ? null : handleModalClose())}
      >
        <form autoComplete="off" className={clsx(classes.root, 'className')}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                color="textPrimary"
                className={classes.title}
              >
                Selecione o arquivo para importar
              </Typography>

              <input
                accept=".csv, text/plain"
                id="raised-button-file"
                className={classes.input}
                type="file"
                onChange={handleFileImport}
              />
              <label htmlFor="raised-button-file">
                <Button
                  color="primary"
                  variant="contained"
                  component="span"
                  className={classes.button}
                >
                  Enviar Arquivo
                </Button>
              </label>

              {!importedFile ? (
                <div
                  style={{
                    display: 'flex',
                    alignContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ marginTop: 10 }}
                  >
                    <a
                      href="https://drive.google.com/file/d/11d4ewxhzOdzwMx2ORsytMdx1dbmC2sZ6/view?usp=sharing"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Exemplo de arquivo CSV
                    </a>
                  </Typography>
                  <Tooltip title="Você pode baixar o excel na opção ao lado e exportar para csv. Só verificar remover os acentos e caracteres especiais.">
                    <InfoIcon className={classes.infoIcon} />
                  </Tooltip>
                </div>
              ) : null}
            </Grid>
            {importedFile && (
              <Grid item xs={12} className={classes.fileDetails}>
                <Typography variant="body1">
                  Nome do Arquivo: {importedFile.name}
                </Typography>
                <Typography variant="body1">
                  Tamanho do Arquivo: {importedFile.size} bytes
                </Typography>
                <Typography variant="body1">
                  Tipo de Arquivo: {importedFile.type}
                </Typography>
              </Grid>
            )}
          </Grid>

          <div className={classes.updateButtonDiv}>
            <Button
              className={classes.button}
              color="secondary"
              variant="contained"
              onClick={handleModalClose}
            >
              Fechar
            </Button>

            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              onClick={handleUpdateData}
            >
              {loading ? 'Atualizando' : 'Atualizar Dados'}
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.loadingIndicator}
                />
              )}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
}
